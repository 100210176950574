import React, { Component } from 'react';
import './Footer.scss';
import local from 'services/localization/local';

export class Footer extends Component {
  render() {
    return(
      <div className='border-top bg-white copyright text-center text-muted'>
        <div className="container" data-testid='Footer'>
          &copy;&nbsp;{new Date().getFullYear()}&nbsp;-&nbsp;{local.footer.companyName}&nbsp;&nbsp;-&nbsp;&nbsp;<a href={local.footer.privacyUrl} target="_blank" rel="noopener noreferrer">{local.footer.privacy}</a>&nbsp;-&nbsp;<a href={local.footer.termsAndConditionUrl} target="_blank" rel="noopener noreferrer">{local.footer.termsAndConditions}</a>&nbsp;-&nbsp;<a href={local.footer.supportUrl} target="_blank" data-beacon="true" rel="noreferrer">{local.footer.support}</a>
        </div>
      </div>
    );
  }
}

export default Footer;
