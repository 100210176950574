import React from 'react';
import PropTypes from 'prop-types';
import { ModalWrapper, Button, Icon } from 'shared';
import './modal-button.scss';

class ModalButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      isOpen: false
    };
  }

  onClick() {
    const { isOpen } = this.state;
    if (isOpen) {
      this.closeModal();
    } else {
      this.openModal();
    }
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  render() {
    const { bodyContent, buttonIcon, id, renderBody } = this.props;
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <Button id="modal-toggle-button" type="button" onClick={this.onClick} color="transparent" data-testid='toggle'>
          {buttonIcon}
        </Button>
        <ModalWrapper isOpen={isOpen} onRequestClose={this.closeModal}>
          <div className="modal-container button-modal">
            <div className="modal-header">
              <Button id="modal-close-button" onClick={this.closeModal} data-testid='close'><Icon symbol="clear" /></Button>
              {buttonIcon}
              <span />
            </div>
            <div className="modal-body button-modal">
              {bodyContent || (renderBody && renderBody(this.closeModal))}
            </div>
          </div>
        </ModalWrapper>
      </React.Fragment>
    );
  }
}

ModalButton.propTypes = {
  bodyContent: PropTypes.node,
  buttonIcon: PropTypes.node.isRequired,
  id: PropTypes.string,
  renderBody: PropTypes.func
};

export default ModalButton;
