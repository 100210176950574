import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../footer/Footer.scss';
import { Link } from 'react-router-dom';
import local from 'services/localization/local';

export class Header extends Component {
  render() {
    return (
      <div>
        <div className='border-bottom p-2 ps-3 bg-white d-flex justify-content-between position-relative'>
          <Link>
            <img src='/static/images/Calcium-Core-logo.png' style={{width: '70%'}} />
          </Link>
          {!this.props.isSignIn &&
          <a href="/" className="btn btn-secondary btn-signin">
            {local.signUpFlow.linkSignIn}
          </a>}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  isSignIn: PropTypes.bool
};

Header.defaultProps = {
  isSignIn: false
};

export default Header;
