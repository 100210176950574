import * as profilesApi from 'services/api/profiles-api';
import { types } from './auth-connection-types';


export function getAuthConnection(domain) {
  return (dispatch, getState) => {
    dispatch(resetConnection());
    dispatch(loading());
    const onSuccess = response => {
      dispatch(loadAuthConnectionSuccess(response));
    };

    const onError = error => {
      if (error && error.status === 404) {
        dispatch(connectionNotFound());
      }
    };

    return profilesApi.getAuthConnections(getState(), domain)
      .then(onSuccess).catch(onError);
  };
}

export function loadAuthConnectionSuccess(connection) {
  return {
    type: types.LOAD_AUTH_CONNECTIONS_SUCCESS,
    payload: connection
  };
}

export function connectionNotFound() {
  return {
    type: types.NO_CONNECTION_FOUND
  };
}

export function resetConnection() {
  return {
    type: types.RESET_CONNECTIONS
  };
}

export function loading() {
  return {
    type: types.LOADING
  };
}
