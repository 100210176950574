import local from 'services/localization/local';
import * as actionNames from './action-names';

export function signInRequired() {
  return {
    type: actionNames.IDENTITY_SIGNINREQUIRED
  };
}

export function signInRequested() {
  return {
    type: actionNames.IDENTITY_SIGNINREQUESTED
  };
}

export function signInSuccess(token) {
  return {
    type: actionNames.IDENTITY_SIGNINSUCCESS,
    payload: token
  };
}

export function refresUserInfoSuccess(userInfo) {
  return {
    type: actionNames.IDENTITY_REFRESH_USERINFO_SUCCESS,
    payload: userInfo
  };
}

export function signInError(errorMessage) {
  return {
    type: actionNames.IDENTITY_SIGNINERROR,
    payload: errorMessage
  };
}
export function verifiedEmail() {
  return {
    type: actionNames.IDENTITY_UNVERIFIEDEMAIL_CODE
  };
}
export function unverifiedEmail(message) {
  return {
    type: actionNames.IDENTITY_UNVERIFIEDEMAIL,
    payload:message
  };
}

export function unverifiedEmailReset() {
  return {
    type: actionNames.IDENTITY_UNVERIFIEDEMAILRESET
  };
}

export function identityRequested() {
  return {
    type: actionNames.IDENTITY_REQUESTED
  };
}

export function identityLoaded(identity) {
  return {
    type: actionNames.IDENTITY_LOADED,
    payload: identity
  };
}

export function identityLoadError() {
  return {
    type: actionNames.IDENTITY_LOADERROR
  };
}

export function registeringUser() {
  return {
    type: actionNames.IDENTITY_REGISTERUSER
  };
}

export function registerUserSuccess(message) {
  return {
    type: actionNames.IDENTITY_REGISTERUSER_SUCCESS,
    payload: message
  };
}

export function registerUserError(error) {
  return {
    type: actionNames.IDENTITY_REGISTERUSER_ERROR,
    payload: error
  };
}

export function resettingPassword() {
  return {
    type: actionNames.IDENTITY_RESET_PASSWORD
  };
}

export function resetPasswordSuccess() {
  return {
    type: actionNames.IDENTITY_RESET_PASSWORD_SUCCESS
  };
}

export function resetPasswordError() {
  return {
    type: actionNames.IDENTITY_RESET_PASSWORD_ERROR
  };
}

export function resetPasswordReset() {
  return {
    type: actionNames.IDENTITY_RESET_PASSWORD_RESET
  };
}

export function updatePreference(preference, value) {
  return {
    type: actionNames.IDENTITY_UPDATEPREFERENCE,
    payload: { preference, value }
  };
}

export function displayCheckEmail(message) {
  return {
    type: actionNames.IDENTITY_DISPLAY_VERIFICATION_SENT,
    payload: message
  };
}
export function verifyCode() {
  return {
    type: actionNames.IDENTITY_VERIFY_CODE
  };
}
export function signOut() {
  return {
    type: actionNames.IDENTITY_SIGNOUT
  };
}

export function signUpLoading() {
  return {
    type: actionNames.IDENTITY_SIGNUP_LOADING
  };
}

export function emailExists() {
  return {
    type: actionNames.IDENTITY_IS_EMAIL_EXISTS
  };
}

export function emailExistsComplete() {
  return {
    type: actionNames.IDENTITY_IS_EMAIL_EXISTS_COMPLETE
  };
}

export function emailExistsError() {
  return {
    type: actionNames.IDENTITY_IS_EMAIL_EXISTS_ERROR,
    payload: local.signUpFlow.validationMessages.error
  };
}

export function onboardComplete(message) {
  return {
    type: actionNames.IDENTITY_SIGNUP_COMPLETE,
    payload: message
  };
}

export function onboardError() {
  return {
    type: actionNames.IDENTITY_SIGNUP_ERROR,
    payload: local.signUpFlow.validationMessages.error
  };
}

export function solutionsSuccess(message) {
  return {
    type: actionNames.SOLUTIONS_SUCCESS,
    payload: message
  };
}
