import React from 'react';
import { Icon } from 'shared';
import local from 'services/localization/local';

class RegisterRightCardCovid extends React.Component {
  render() {
    return (
      <div className="col-sm-6">
        <div className="shadow ms-md-5 mt-md-0 mt-4">
          <div className="card-body">
            <h4 className="card-title">{local.registerRightCardCovid.title}</h4>
            <div className="card-text mt-3">
              <ul>
                <li> <Icon symbol="tick" /> {local.registerRightCardCovid.tick1} ​</li>
                <li> <Icon symbol="tick" /> {local.registerRightCardCovid.tick2} </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RegisterRightCardCovid;
