import React from 'react';
import local from 'services/localization/local';
import LoadingPage from 'shared/loading/LoadingPage';
import { isMobile, openMobileRoute } from 'mobile/helpers';
import { signOut } from 'services/auth0-service';

class SignOut extends React.Component {
  componentDidMount() {
    if (isMobile) {
      openMobileRoute('signout');
    } else {
      signOut();
    }
  }

  render() {
    return <LoadingPage id='signout-loading' centerElement={local.signingOut} />;
  }
}

export default SignOut;
