import { ErrorCode } from 'services/enums';
import local from 'services/localization/local';

export const ErrorCodeMessageDict = {
  [ErrorCode.General]: local.error,
  [ErrorCode.InviteAlreadyExists]: local.errorCodeMessage.inviteAlreadyExists,
  [ErrorCode.InviteSelf]: local.errorCodeMessage.inviteSelf,
  [ErrorCode.InviteUsed]: local.errorCodeMessage.inviteUsed,
  [ErrorCode.InviteExistingRelationship]: local.errorCodeMessage.inviteExistingRelationship,
  [ErrorCode.InviteNotFound]: local.error,
  [ErrorCode.RegisterPasswordError]: local.errorPasswordError,
  [ErrorCode.RegisterPasswordStrengthError]: local.errorPasswordStrengthError,
  [ErrorCode.RegisterUserExistsError]: local.errorUserExistsError,
  [ErrorCode.RegisterUserInvalidEmailFormat]: local.errorInvalidEmailFormat
};

export const externalUrls = {
  hcWebsite: 'https://calciumhealth.com',
  hcPrivacyPolicy: 'https://calciumhealth.com/privacy-policy/',
  hcAbout: 'https://calciumhealth.com/about/'
};

export const appNames = {
  covid: 'covid',
  dash: 'dash',
  member: 'member',
  provider: 'provider'
};

export const localStoragePaths = {
  preferences: 'preferences',
  redirect: 'redirect',
  redirectUrl: 'redirectUrl',
  app: 'app',
  selectedProfile: 'selectedProfile',
  emailAddress: 'emailAddress',
  branchLinkData: 'branchLinkData'
};

export const socialShareLinks = {
  pathwayGeneral: 'https://healthchampion.app.link/M4CvtmfW65',
  mainMenuShare: 'https://healthchampion.app.link/appshare'
};

export const AnonymousId = 'anonymous';
export const LilChartDataPointCount = 12;

export const AuthConnectionUniqueId = 'auth0';

export const HowDidyouHearAboutCalciumOptions = [
  'Word of mouth',
  'Advertisement',
  'Web search',
  'Other'
]

export const PhoneNumberRegex = '^[0-9*#+]+$';

export const PhoneNumberMaxLength = '^[0-9*#+]+$';

export const emailRegex = /.+@.+\..+/;

export const SelectText = 'Select';

export const Solutions = [{
  solutionName: 'Infectious Disease (COVID-19)',
  solutionId: appNames.covid
}];

export const PasswordMinLength = 8;
