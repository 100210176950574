import * as auth0 from 'services/auth0-service';
import { isMobile } from 'mobile/helpers';
import * as toastActions from 'app/toaster/actions';
import { localStoragePaths, appNames } from 'services/constants';
import * as requestQueueActions from 'app/request/request-queue-actions';
import * as api from 'services/api/profiles-api';
import { saveCurrentBranchData } from 'services/branch-service';
import * as actions from './actions';
import { types } from './identity-types';
import local from 'services/localization/local';
import * as requestActions from 'app/request/request-actions';

export function signIn(email, password, authConnection) {
  return (dispatch) => {
    dispatch(actions.signInRequested());
    localStorage.setItem(localStoragePaths.emailAddress, email);

    const onError = error => {
      dispatch(actions.signInError(error));
    };
    const onEmailUnverified = message => {
      dispatch(actions.unverifiedEmail(message));
    };
    auth0.authorize(email, password, onError, onEmailUnverified, authConnection);
  };
}

export function handleSignInCallback() {
  return (dispatch) => {
    const onSuccess = token => {
      localStorage.removeItem(localStoragePaths.emailAddress);
      dispatch(actions.signInSuccess(token));
    };

    const onError = error => {
      dispatch(actions.signInError(error));
    };

    const onEmailUnverified = message => {
      dispatch(actions.unverifiedEmail(message));
    };

    auth0.authorizeCallback(onSuccess, onError, onEmailUnverified);
  };
}

export function handleSignInCapacitor() {
  return (dispatch) => {
    const onSuccess = token => {
      localStorage.removeItem(localStoragePaths.emailAddress);
      if (token.redirect) {
        localStorage.setItem(localStoragePaths.redirect, token.redirect);
      }
      dispatch(actions.signInSuccess(token));
    };

    const onError = error => {
      dispatch(actions.signInError(error));
    };

    if (!isMobile) {
      onError();
      return;
    }

    auth0.parseHash(onSuccess, onError);
  };
}

export function checkSignIn() {
  return (dispatch) => {
    if (isMobile) {
      return;
    }

    const onSuccess = token => {
      dispatch(actions.signInSuccess(token));
    };

    const onError = () => {
      dispatch(actions.signInRequired());
    };

    const onEmailUnverified = message => {
      dispatch(actions.unverifiedEmail(message));
    };

    saveCurrentBranchData(() => auth0.checkSession(onSuccess, onError, onEmailUnverified));
  };
}

export function refreshUserInfo() {
  return (dispatch, getState) => {
    auth0.refreshUserData(getState().identity.accessToken)
      .then(result => dispatch(actions.refresUserInfoSuccess(result)));
  };
}

export function loadSelfIdentity() {
  return (dispatch, getState) => {
    dispatch(actions.identityRequested());

    const onSuccess = identity => {
      dispatch(actions.identityLoaded(identity));
    };

    const onError = () => {
      dispatch(actions.identityLoadError());
    };

    const branchData = localStorage.getItem(localStoragePaths.branchLinkData);
    return api.getIdentity(getState(), branchData).then(onSuccess, onError).catch(onError);
  };
}

export function registerUser({ email, password, isFromProvider }) {
  const request = {
    email,
    password,
    isFromProvider,
    // defaulting to true
    acceptedPrivacyPolicy: true,
    marketingEmailOptIn: true,
    marketingSourceData: JSON.parse(localStorage.getItem(localStoragePaths.branchLinkData)),
    isForceRegistration: true
  };
  return (dispatch, getState) => {
    dispatch(actions.registeringUser());

    const onSuccess = () => {
      signIn(request.email,request.password)(dispatch);
    };

    const onError = error => {
      dispatch(actions.registerUserError(error));
    };

    return api.registerUser(request, getState()).then(onSuccess).catch(onError);
  };
}

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch(actions.resettingPassword());

    const onSuccess = () => {
      dispatch(actions.resetPasswordSuccess());
    };

    const onError = () => {
      dispatch(actions.resetPasswordError());
    };

    auth0.changePassword(email, onSuccess, onError);
  };
}

export function updatePreference(preference, value) {
  return (dispatch, getState) => {
    dispatch(actions.updatePreference(preference, value));

    const onError = () => {
      dispatch(toastActions.popErrorToast());
      dispatch(actions.updatePreference(preference, !value));
    };

    return api.updatePreference({ preference, value }, getState()).catch(onError);
  };
}

export function resendVerificationEmail() {
  return (dispatch, getState) => {
    const email = getState().identity.email;
    const message = local.formatString(local.checkEmail, email);
    let isFromProvider = localStorage.getItem(localStoragePaths.app) == appNames.provider;
    if(!isFromProvider){
      isFromProvider = getState().identity.isRegisteredForPortal;
    }

    dispatch(actions.displayCheckEmail(message));
    const action = state => api.resendConfirmEmail(email,state,isFromProvider);

    dispatch(requestQueueActions.queueRequestWithToast(action, types.RESEND_VERIFICATION, null, {
      processingMessage: local.sendingConfirmationEmail,
      successMessage: local.sent
    }, { email }));
  };
}

export function verifyEmailWithCode(verificationCode, requestId) {
  return (dispatch) => {
    const action = state => api.verifyEmailWithCode(verificationCode, state)
      .then(() => {
        dispatch(actions.verifiedEmail());
      });

    const requestOptions = {
      showToasts: true,
      processingMessage: local.verifyEmail.verifying,
      successMessage: local.verifyEmail.verified,
      errorMessage: local.verifyEmail.failed
    };

    dispatch(requestActions.request(action, requestId, {}, requestOptions));
  };
}
export function verifyEmailWithCodeClick() {
  return (dispatch) => {
    dispatch(actions.verifyCode());
  };
}

export function signOutHandler() {
  return (dispatch) => {
    dispatch(actions.signOut());
  };
}
export function checkEmailExists(email) {
  return (dispatch, getState) => {
    dispatch(actions.signUpLoading());

    const onSuccess = (response) => {
      if (response) {
        dispatch(actions.emailExists());
      }
      else{
        dispatch(actions.emailExistsComplete());
      }
    };

    const onError = () => {
      dispatch(actions.emailExistsError());
    };

    return api.isEmailExists(getState(), email).then(onSuccess, onError).catch(onError);
  };
}

export function onboardUser(requestData) {
  requestData.marketingSourceData = JSON.parse(localStorage.getItem(localStoragePaths.branchLinkData));
  return (dispatch, getState) => {
    dispatch(actions.signUpLoading());

    const onSuccess = (response) => {
      dispatch(actions.onboardComplete(response));
    };

    const onError = () => {
      dispatch(actions.onboardError());
    };

    return api.onboardUser(getState(), requestData).then(onSuccess, onError).catch(onError);
  };
}

export function getSelfRegisterationSubscriptionProducts() {
  return (dispatch, getState) => {

    const onSuccess = (response) => {
      dispatch(actions.solutionsSuccess(response));
    };

    const onError = () => {
      dispatch(actions.onboardError());
    };

    return api.getSelfRegisterationProducts(getState()).then(onSuccess, onError).catch(onError);
  };
}
