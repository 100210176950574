import React from 'react';
import PropTypes from 'prop-types';
import Home from 'product-home/product-home';
import local from 'services/localization/local';
import { ErrorPage, LoadingPage } from 'shared';
import MainNavigation from './navigation/Navigation';

export class LayoutComponent extends React.Component {
  render() {
    const { loading, loadError, profileMissing, profile } = this.props;
    let nav = null;
    let body = null;
    if (loading) {
      body = <LoadingPage />;
    } else if (loadError) {
      body = <ErrorPage id="layout-error">{local.errorLoadProfile}</ErrorPage>;
    }
    else{
      body = <Home />;
      nav = <MainNavigation />;

    }
    // if (!profile) {
    //   body = <Page404 />;
    //   nav = <MainNavigation />;
    // } else if (!profile.hipaaConsentResult.hasHipaaConsent) {
    //   body = <HipaaIntroductionPage />;
    //   nav = <MainNavigation signOutOnly />;
    // } else

    return (
      <div id="main-layout">
        {nav}
        {body}
      </div>
    );
  }
}

LayoutComponent.displayName = 'Layout';
LayoutComponent.propTypes = {
  profile: PropTypes.shape({}),
  loading: PropTypes.bool,
  loadError: PropTypes.bool,
  profileMissing: PropTypes.bool
};
LayoutComponent.defaultProps = {
  loading: true,
  loadError: false
};

export default LayoutComponent;
