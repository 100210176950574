import React, { Component } from 'react';
import {
  covidPortalUrl,
  dashPortalUrl,
  providerPortalUrl,
  memberAppUrl
} from 'app/config';

export class ProductHome extends Component {
  render() {
    return <div className='product-home'>
      <h3>Solutions</h3>
      <div>
        <a href={covidPortalUrl}>Covid Portal</a><br />
        <a href={dashPortalUrl}>Dash Portal</a><br />
        <a href={providerPortalUrl}>Provider Portal</a><br />
        <a href={memberAppUrl}>Member App</a>
      </div>
    </div>;
  }
}

export default ProductHome;