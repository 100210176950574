import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { localStoragePaths } from 'services/constants';
import { checkSignIn } from 'identity/action-creators';
import SignIn from 'identity/signin/SignIn';
import LayoutContainer from 'app/layout/LayoutContainer';
import IdentityWrapper from 'identity/IdentityWrapper';
import AuthLoader from './AuthLoader';
import { routePaths } from 'services/routing';
import { OrganizationMemberStatus, OrganizationMemberRole, OrganizationStatus, SubscriptionProducts, App } from 'services/enums';
import { getIdentityOrganization } from 'organizations/organizations-actions';
import VerifyEmailByCode from 'identity/verify-email/VerifyEmailByCode';
import {
  covidPortalUrl,
  dashPortalUrl,
  providerPortalUrl,
  memberAppUrl
} from 'app/config';
import { getQueryStringParams } from 'services/url-service';
export default function AuthWrapper() {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.identity.isSignedIn, shallowEqual);
  const signInRequired = useSelector(state => state.identity.signInRequired, shallowEqual);
  const verificationProcessState = useSelector(state => state.identity.verificationProcessState, shallowEqual);
  const unverifiedEmail = useSelector(state => state.identity.unverifiedEmail, shallowEqual);
  const organizationStatues = [OrganizationStatus.Active];
  const memberRoles = [OrganizationMemberRole.Admin];
  const memberStatues = [OrganizationMemberStatus.Active,OrganizationMemberStatus.Pending];
  const [getOrganizations, setGetOrganizations] = useState({});
  const [apiResposonse, setAPIresponse] = useState(false);
  let redirect = null;
  var app = null;
  if (isSignedIn) {
    redirect = localStorage.getItem(localStoragePaths.redirect);
  }
  const query = getQueryStringParams(location);
  if (query.app) {
    localStorage.setItem(localStoragePaths.app, query.app);
  }
  useEffect(() => {
    if (!isSignedIn) {
      dispatch(checkSignIn());
    }
  }, []);
  function appRedirect() {
    if (getOrganizations?.results?.length > 0) {
      var rootOrgs = getOrganizations?.results?.filter(x => x.parentOrganization == null);
      var appUrl = '';
      var subscriptionByProduct = Object.keys(getOrganizations?.results[0]?.organizationFeatures?.subscriptionByProduct)[0].toLowerCase();
      if (rootOrgs.length > 1 && !subscriptionByProduct == SubscriptionProducts.FitwellPortal) {
        appUrl = providerPortalUrl;
      }
      else {
        if (subscriptionByProduct == SubscriptionProducts.CovidPortal) {
          appUrl = covidPortalUrl;
        }
        else if (subscriptionByProduct == SubscriptionProducts.ProviderPortal) {
          appUrl = providerPortalUrl;
        }
        else if (subscriptionByProduct == SubscriptionProducts.FitwellPortal) {
          appUrl = dashPortalUrl;
        }
        else if (subscriptionByProduct == SubscriptionProducts.Potal30Covid) {
          appUrl = providerPortalUrl;
        }
      }
    }
    return appUrl;
  }
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  function getDefaultappURL() {
    var defaultUrl = '';
    app = localStorage.getItem(localStoragePaths.app);
    // Need to check with Core team, why they removed this
    // localStorage.removeItem(localStoragePaths.app);
    if (app && app != '') {
      app = app.toLowerCase();
      if (getOrganizations?.results?.length == 0 && app != App.dash) {
        app = App.provider;
      }
      switch (app) {
        case App.covid:
          defaultUrl = covidPortalUrl;
          break;
        case App.dash:
          defaultUrl = dashPortalUrl;
          break;
        case App.provider:
          defaultUrl = providerPortalUrl;
          break;
        case App.member:
          defaultUrl = memberAppUrl;
          break;
      }
    }
    return defaultUrl;
  }
  if (signInRequired) {
    return <SignIn />;
  }

  else if (redirect && !unverifiedEmail && verificationProcessState) {
    return <Redirect to={routePaths.redirectToExternalURL} />;
  }
  else if (isSignedIn) {
    dispatch(getIdentityOrganization([], memberStatues, memberRoles, organizationStatues)).then(function (response) {
      setGetOrganizations(response);
      setAPIresponse(true);
    });
    timeout(3000);
    if (apiResposonse) {
      var url = appRedirect();
      if (url == null || url == '')
        url = getDefaultappURL();
      if (url && url != ''  && !unverifiedEmail && verificationProcessState) {
        localStorage.setItem(localStoragePaths.redirect, url);
        return <Redirect to={routePaths.redirectToExternalURL} />;
      }
      else if ((unverifiedEmail &&  verificationProcessState)|| (!unverifiedEmail && !verificationProcessState)) {
        return (
          <IdentityWrapper>
            <VerifyEmailByCode />
          </IdentityWrapper>
        );
      }
      else {
        return (
          <IdentityWrapper>
            <LayoutContainer />
          </IdentityWrapper>
        );
      }
    }
    else {
      return (
        <IdentityWrapper>
          <LayoutContainer />
        </IdentityWrapper>
      );
    }
  }
  else {
    return <AuthLoader />;
  }
}
