import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { ExternalLink } from 'shared';
import { privacyPolicyUrl, termsAndConditionsUrl } from 'app/config';
class PrivacyPolicyEulaSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { buttonNameText } = this.props;

    const eulaTextAction = (
      <ExternalLink
        href={termsAndConditionsUrl}
        id="eula-text"
        logName='signin-terms-conditions-external-url'
        className='nav-eula'
        openNewTab>
        <span>{local.eula}</span>
      </ExternalLink>
    );
    const privacyPolicyTextAction = (
      <ExternalLink
        href={privacyPolicyUrl}
        id="privacy-policy"
        logName='sigin-privacy-policy-external-url'
        className='nav-pp'
        openNewTab>
        <span>{local.privacyPolicy}</span>
      </ExternalLink>
    );
    const bodyText = local.formatString(local.formPrivacyPolicyAgreeText, buttonNameText, eulaTextAction, privacyPolicyTextAction);

    return (
      <div className="privacy-policy-link">
        <small>
          {bodyText}
        </small>
      </div>
    );
  }
}

PrivacyPolicyEulaSection.propTypes = {
  buttonNameText: PropTypes.string.isRequired
};

export default PrivacyPolicyEulaSection;
