import compileReducers from 'services/compile-reducer';
import * as actions from './action-names';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import { types } from './identity-types';

export const initialState = {
  signInRequired: false,
  tokenType: null,
  accessToken: null,
  signingIn: false,
  isSignedIn: false,
  signInError: false,
  unverifiedEmail: false,
  errorMessage: null,
  loading: false,
  loadError: false,
  self: undefined,
  registering: false,
  registerSuccess: false,
  registerError: false,
  activating: false,
  activateSuccess: false,
  activateError: false,
  resettingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordError: false,
  verificationSentMessage: '',
  [types.RESEND_VERIFICATION]: requestQueueReducer.initialState,
  isEmailExists: false,
  stepOneCompleted: false,
  signUpError: null,
  signUpSuccess: false,
  isRegisteredForPortal: false,
  solutions: [],
  solutionsLoaded: false,
  verificationProcessState:true
};

const reducers = { ...requestQueueReducer.reducers(types.RESEND_VERIFICATION) };

reducers[actions.IDENTITY_SIGNINREQUIRED] = (state) => ({
  ...state,
  signInRequired: true
});

reducers[actions.IDENTITY_SIGNINREQUESTED] = () => ({
  ...initialState,
  signingIn: true
});

reducers[actions.IDENTITY_SIGNINSUCCESS] = (state, token) => {
  const newState = {
    signInRequired: false,
    isSignedIn: true,
    signingIn: false,
    signInError: false,
    errorMessage: null,
    tokenType: token.tokenType,
    accessToken: token.accessToken
  };

  /* istanbul ignore else */
  if (token.idTokenPayload) {
    newState.email = token.idTokenPayload.email;
    newState.unverifiedEmail = !token.idTokenPayload.email_verified;
  }

  return {
    ...state,
    ...newState
  };
};

reducers[actions.IDENTITY_SIGNINERROR] = (state, message) => ({
  ...state,
  isSignedIn: false,
  signInRequired: true,
  signingIn: false,
  signInError: true,
  errorMessage: message,
  unverifiedEmail: false
});

reducers[actions.IDENTITY_UNVERIFIEDEMAIL] = (state,payload) => ({
  ...state,
  unverifiedEmail: true,
  isSignedIn: false,
  signingIn: false,
  signInError: true,
  signInRequired: true,
  errorMessage: null,
  email:payload
});

reducers[actions.IDENTITY_UNVERIFIEDEMAILRESET] = (state) => ({
  ...state,
  unverifiedEmail: false,
  signInError: false,
  errorMessage: null,
  signInRequired: false
});

reducers[actions.IDENTITY_UNVERIFIEDEMAIL_CODE] = (state) => ({
  ...state,
  unverifiedEmail: false,
  verificationProcessState :false
});

reducers[actions.IDENTITY_VERIFY_CODE] = (state) => ({
  ...state,
  verificationProcessState :true,
  signInRequired:true
});
reducers[actions.IDENTITY_SIGNOUT] = (state) => ({
  ...state,
  signInRequired: true,
  tokenType: null,
  accessToken: null,
  signingIn: false,
  isSignedIn: false,
  signInError: false,
  unverifiedEmail: false,
  self: undefined,
  email:''
});
reducers[actions.IDENTITY_REFRESH_USERINFO_SUCCESS] = (state, userInfo) => {
  return {
    ...state,
    email: userInfo.email,
    unverifiedEmail: !userInfo.email_verified
  };
};

reducers[actions.IDENTITY_REQUESTED] = state => ({
  ...state,
  loading: true,
  loadError: false
});

reducers[actions.IDENTITY_LOADED] = (state, payload) => ({
  ...state,
  self: payload,
  loading: false,
  loadError: false
});

reducers[actions.IDENTITY_LOADERROR] = (state) => ({
  ...state,
  loading: false,
  loadError: true
});

reducers[actions.IDENTITY_REGISTERUSER] = (state) => ({
  ...state,
  saving: true,
  saveError: false,
  saveSuccess: false
});

reducers[actions.IDENTITY_REGISTERUSER] = (state) => ({
  ...state,
  registering: true,
  registerSuccess: false,
  registerError: false
});

reducers[actions.IDENTITY_REGISTERUSER_SUCCESS] = (state, payload) => ({
  ...state,
  registering: false,
  registerSuccess: true,
  registerError: false,
  email: payload
});

reducers[actions.IDENTITY_REGISTERUSER_ERROR] = (state, payload) => ({
  ...state,
  registering: false,
  registerSuccess: false,
  registerError: payload
});

reducers[actions.IDENTITY_RESET_PASSWORD] = (state) => ({
  ...state,
  resettingPassword: true,
  resetPasswordSuccess: false,
  resetPasswordError: false
});

reducers[actions.IDENTITY_RESET_PASSWORD_SUCCESS] = (state) => ({
  ...state,
  resettingPassword: false,
  resetPasswordSuccess: true,
  resetPasswordError: false
});

reducers[actions.IDENTITY_RESET_PASSWORD_ERROR] = (state) => ({
  ...state,
  resettingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordError: true
});

reducers[actions.IDENTITY_RESET_PASSWORD_RESET] = (state) => ({
  ...state,
  resetPasswordSuccess: false,
  resetPasswordError: false
});

reducers[actions.IDENTITY_UPDATEPREFERENCE] = (state, payload) => ({
  ...state,
  self: {
    ...state.self,
    [payload.preference]: payload.value
  }
});

reducers[actions.IDENTITY_DISPLAY_VERIFICATION_SENT] = (state, payload) => ({
  ...state,
  verificationSentMessage: payload
});


reducers[actions.IDENTITY_SIGNUP_LOADING] = (state) => ({
  ...state,
  signUpLoading: true
});

reducers[actions.IDENTITY_IS_EMAIL_EXISTS] = (state) => ({
  ...state,
  isEmailExists: true,
  signUpError: null,
  signUpLoading: false,
  stepOneCompleted: false
});

reducers[actions.IDENTITY_IS_EMAIL_EXISTS_COMPLETE] = (state, payload) => ({
  ...state,
  isEmailExists: false,
  stepOneCompleted: true,
  signUpError: null,
  signUpLoading: false
});

reducers[actions.IDENTITY_IS_EMAIL_EXISTS_ERROR] = (state, payload) => ({
  ...state,
  signUpError: payload,
  signUpLoading: false
});

reducers[actions.IDENTITY_SIGNUP_COMPLETE] = (state, payload) => ({
  ...state,
  email: payload,
  signUpSuccess: true,
  signUpError: null,
  signUpLoading: false,
  isRegisteredForPortal: true
});

reducers[actions.IDENTITY_SIGNUP_ERROR] = (state, payload) => ({
  ...state,
  signUpSuccess: false,
  signUpError: payload,
  signUpLoading: false,
  isRegisteredForPortal: false
});

reducers[actions.SOLUTIONS_SUCCESS] = (state, payload) => ({
  ...state,
  solutions: payload,
  solutionsLoaded: true
});

export default compileReducers(reducers, initialState);
