import React from 'react';
import PropTypes from 'prop-types';
import { LinkWrapper, ErrorSection, ExternalLink, NonAuthenticatedPageWall } from 'shared';
import local from 'services/localization/local';
import { externalUrls, appNames, localStoragePaths } from 'services/constants';
import PrivacyPolicyEulaSection from 'app/privacy-policy-eula/PrivacyPolicyEulaSection';
import RegisterForm from './RegisterForm';
import './Register.scss';
import RegisterStepHeader from './RegisterStepHeader';
import RegisterRightCardCovid from './RegisterRightCardCovid';
import RegisterRightCardDash from './RegisterRightCardDash';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { getQueryStringParams } from 'services/url-service';

class Register extends React.Component {
  render() {
    const { registering, errorMessage, handleSubmit } = this.props;
    const navAction = <LinkWrapper id='already-have-account-sign-in-nav' className='button gray' to='/'>{local.linkSignIn}</LinkWrapper>;

    const query = getQueryStringParams(location);
    var app = query.app;
    if (app != undefined) {
      localStorage.setItem(localStoragePaths.app, app);
    } else {
      app = localStorage.getItem(localStoragePaths.app, app);
    }
    const content = local.nonAuthWallPage;
    let header = <h2>{content.header}</h2>;
    let subhead = <p>{content.subheadMember}</p>;
    let step;
    let registerSignUp = local.registerSignupMember;
    let registerRightCard;
    let isFromProvider = false;
    let registerAlreadyHaveAccount = local.registerAlreadyHaveAccount;
    let buttonNameText = local.formSignInJoin;
    let signInUrl = '/';

    switch (app) {
      case appNames.covid:
        subhead = <p>{content.subheadCovid}</p>;
        step = <RegisterStepHeader></RegisterStepHeader>;
        registerSignUp = local.registerSignupCovid;
        registerRightCard = <RegisterRightCardCovid></RegisterRightCardCovid>;
        break;
      case appNames.dash:
        subhead = <p>{content.subheadDash}</p>;
        step = <RegisterStepHeader></RegisterStepHeader>;
        registerSignUp = local.registerSignupDash;
        registerRightCard = <RegisterRightCardDash></RegisterRightCardDash>;
        break;
      case appNames.provider:
        isFromProvider = true;
        registerSignUp = local.registerSignupMember;
        registerAlreadyHaveAccount = local.registerAlreadyHaveCalciumAccount;
        buttonNameText = local.formCreateAccount;
        signInUrl = 'signin?app=provider';
        break;
    }

    let privacyPolicySection = <PrivacyPolicyEulaSection buttonNameText={buttonNameText} />;

    return (
      <div>
        {isFromProvider &&
          <div>
            <Header></Header>
          </div>}
        <div className={isFromProvider ? 'd-flex signin-section' : ''}>
          <div className={isFromProvider ? 'flex-fill' : ''}>
            <div id={isFromProvider ? 'signIn-form' : 'register-form'}>
              <NonAuthenticatedPageWall id='register' className="signin-content" titleTabText={local.register} navAction={navAction}>
                {!isFromProvider &&
                  <header>
                    <h2>{content.header}</h2>
                    {subhead}
                    {step}
                  </header>}
                <div className='col-12'>
                  {isFromProvider &&
                    <div><h3 className='mb-0'>{content.headerProvider}</h3>
                      <p>{content.subheadProvider}</p>
                    </div>}
                  <div className={isFromProvider ? 'my-4' : 'row d-flex align-items-center my-5'}>
                    <div className={isFromProvider ? '' : 'col-sm-6'}>
                      {!isFromProvider && <h4>{registerSignUp}</h4>}
                      <ErrorSection>{errorMessage}</ErrorSection>
                      <RegisterForm onSubmit={handleSubmit} registering={registering} privacyPolicySection={privacyPolicySection} isFromProvider={isFromProvider} />
                      <div className='secondary-links'>
                        {!isFromProvider && <small>{local.formatString(local.registerLearnMore, (<ExternalLink id="register-learn-more" openNewTab href={externalUrls.hcAbout}>{local.linkLearnMore}</ExternalLink>))}</small>}
                        <br />
                        <small>{registerAlreadyHaveAccount} <LinkWrapper id='already-have-account-sign-in' to={signInUrl}>{local.linkSignIn}</LinkWrapper></small>
                      </div>
                    </div>
                    {registerRightCard}
                  </div>
                </div>
              </NonAuthenticatedPageWall>
            </div>
          </div>
          {isFromProvider && <div className='flex-fill image-section'>
            <img src="/static/images/logo-white.png" />
          </div>}
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

Register.displayName = 'Register';
Register.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  registering: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

export default Register;
