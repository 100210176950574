import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PreviousWeekButton from './PreviousWeekButton';
import NextWeekButton from './NextWeekButton';
import DateOption from './DateOption';
import './weekly-date-picker.scss';

class WeeklyDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.getSelectedDate = this.getSelectedDate.bind(this);
    this.getDateOptionsForWeek = this.getDateOptionsForWeek.bind(this);
  }

  getSelectedDate() {
    const { value } = this.props;
    return value || moment().format(moment.HTML5_FMT.DATE);
  }

  getDateOptionsForWeek() {
    const { onChange, dateDataSelector } = this.props;
    const selectedDate = this.getSelectedDate();
    const now = moment();
    const startDateMoment = moment(selectedDate).startOf('week');
    const endDateMoment = moment(selectedDate).endOf('week');

    const daysOfWeek = [];

    while (startDateMoment <= endDateMoment) {
      const currentDate = startDateMoment.format(moment.HTML5_FMT.DATE);
      daysOfWeek.push(
        <DateOption
          key={currentDate}
          date={currentDate}
          isSelected={selectedDate === currentDate}
          isDisabled={moment(currentDate) > now}
          dateDataSelector={dateDataSelector}
          onChange={onChange} />
      );
      startDateMoment.add(1, 'day');
    }

    return daysOfWeek;
  }

  render() {
    const { onChange } = this.props;
    const date = this.getSelectedDate();
    const month = moment(date).format('MMMM');
    const daysOfWeek = this.getDateOptionsForWeek();

    return (
      <div data-testid="weekly-date-picker" className="weekly-date-picker">
        <div data-testid="month" className="row month">{month}</div>
        <div className="row">
          <PreviousWeekButton date={date} onChange={onChange} />
          {daysOfWeek}
          <NextWeekButton date={date} onChange={onChange} />
        </div>
      </div>
    );
  }
}

WeeklyDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  dateDataSelector: PropTypes.func,
  value: PropTypes.string
};

export default WeeklyDatePicker;
