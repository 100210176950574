import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { routePaths } from 'services/routing';
import SignUp from './SignUp';

function SignUpContainer({ success }) {
  if (success) {
    return <Redirect to={routePaths.registerThankyou} />;
  }

  return <SignUp />;
}

function mapStateToProps(state) {
  return {
    success: state.identity.signUpSuccess
  };
}

export default connect(mapStateToProps)(SignUpContainer);
