import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { buildVersion, privacyPolicyUrl, termsAndConditionsUrl } from 'app/config';
import { closeDrawerMenu } from './actions';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon, Header, ExternalLink } from 'shared';
import HipaaMoreLink from 'app/privacy-policy-eula/HipaaMoreLink';
import NavShareLink from './NavShareLink';
import './navigation.scss';

class MainNavigationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.buildCloseButton = this.buildCloseButton.bind(this);
    this.buildMainNav = this.buildMainNav.bind(this);
  }

  buildCloseButton() {
    return (
      <button type="submit" onClick={this.props.actions.closeDrawerMenu}>
        <Icon symbol="times" />
      </button>
    );
  }

  buildMainNav() {
    const { signOutOnly } = this.props;

    if (!signOutOnly) {
      return (
        <div>
          <section className="nav-section">
            <NavShareLink />
          </section>
          <section className="nav-section">
            <HipaaMoreLink />
            <ExternalLink
              href={privacyPolicyUrl}
              id="privacy-policy"
              logName='privacy-policy-external-url'
              className='nav-pp'
              openNewTab>
              <Icon symbol="privacypolicy" />
              <span>{local.privacyPolicy}</span>
            </ExternalLink>
            <ExternalLink
              href={termsAndConditionsUrl}
              id="eula-text"
              logName='terms-conditions-external-url'
              className='nav-eula'
              openNewTab>
              <Icon symbol="termsandconditions" />
              <span>{local.eula}</span>
            </ExternalLink>
          </section>
        </div>
      );
    }
  }

  render() {
    const { actions, isOpen } = this.props;
    const year = new Date().getFullYear();
    const closeButton = this.buildCloseButton();
    const mainNav = this.buildMainNav();

    return (
      <aside id="tabBarContainer" className={isOpen ? 'open' : 'close'}>
        <Header centerElement={local.menu} farLeftElement={closeButton} />
        <nav id="navSectionLinks" onClick={actions.closeDrawerMenu}>
          {mainNav}

          <section className="nav-section">
            <NavLink id="more-signout" className='nav-signout' to={routePaths.signout}>
              <Icon symbol="signout" />
              <span>{local.linkSignout}</span>
            </NavLink>
          </section>
        </nav>
        <footer id="navSectionFooter">
          <small>&copy; {year} - {local.copyright}</small>
          <br />
          <small>{buildVersion}</small>
        </footer>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    isOpen: state.navigation.isOpen
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    closeDrawerMenu
  };

  return { actions: bindActionCreators(actions, dispatch) };
}

MainNavigationComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  signOutOnly: PropTypes.bool,
  actions: PropTypes.shape({
    closeDrawerMenu: PropTypes.func.isRequired
  }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigationComponent);
