export const getProfileId = (state) => state.selectedProfile.id;

export const getIsApplicationLoading = state => {
  const { identity } = state;
  const isIdentityLoading = identity.loading && !identity.self;

  return !!(isIdentityLoading);
};

export const getIsApplicationLoadError = state => {
  const { identity } = state;

  return !!(identity.loadError);
};
