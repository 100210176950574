import * as profilesApi from 'services/api/profiles-api';
import { types } from './../organizations/organizations-types';

export function getIdentityOrganization(organizationIds, memberStatuses, memberRoles, organizationStatuses) {
  return (dispatch, getState) => {
    const onError = error => {
      if (error && error.status === 404) {
        dispatch(connectionNotFound());
      }
    };

    return profilesApi.getIdentityOrganizations(getState(), organizationIds, memberStatuses, memberRoles, organizationStatuses)
      .then(response => {return response;}).catch(onError);
  };
}

export function connectionNotFound() {
  return {
    type: types.NO_CONNECTION_FOUND
  };
} 
