import React from 'react';
import { localStoragePaths } from 'services/constants';

class RedirectToExternalURL extends React.Component {
  componentDidMount() {    
    var redirecturl = localStorage.getItem(localStoragePaths.redirect);
    localStorage.removeItem(localStoragePaths.redirect);
    window.location = redirecturl;
  }
  render() {
    return (
      <div>
      </div>);
  }
}
export default RedirectToExternalURL;