import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ArrowButton } from 'shared';

export class NextWeekButton extends React.Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.getNextWeekValidDate = this.getNextWeekValidDate.bind(this);
  }

  onSelect() {
    const { onChange } = this.props;
    const nextWeekDate = this.getNextWeekValidDate();
    if (nextWeekDate) {
      onChange(moment(nextWeekDate).format(moment.HTML5_FMT.DATE));
    }
  }

  getNextWeekValidDate() {
    const { date } = this.props;
    const nextWeekDate = moment(date).add(1, 'week');
    const startOfWeek = moment(nextWeekDate).startOf('week');
    const now = moment().startOf('day');

    if (startOfWeek > now) {
      return false;
    }

    while (nextWeekDate > now) {
      nextWeekDate.add(-1, 'day');
    }
    return nextWeekDate.format(moment.HTML5_FMT.DATE);
  }

  render() {
    return (
      <div id="go-next-week-button" data-testid="go-next-week-button" className="row-item week-option" onClick={this.onSelect}>
        <ArrowButton id="go-next-week-arrow" data-testid="go-next-week-internal" rightArrow className="go-next" disabled={!this.getNextWeekValidDate()} />
      </div>
    );
  }
}

NextWeekButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired
};

export default NextWeekButton;

