import React from 'react';
import Icon from 'shared/icon/Icon';
import SubLabelSection from 'shared/form-fields/SubLabelSection';
import './form-fields.scss';

class SelectField extends React.Component {
  render() {
    const { label, subLabel, placeholder, input, meta: { error, touched }, children, ...rest } = this.props;
    const hasError = error && touched;
    const id = `${input.name}-input`;

    return (
      <div className="formField">
        <div className="select-wrapper">
          <select
            id={id}
            className={hasError ? 'inputError' : ''}
            {...input}
            {...rest}>
            {placeholder && <option disabled value="">{placeholder}</option>}
            {children}
          </select>
          <Icon symbol="chevrondown" />
          {label && <label htmlFor={id}>{label}</label>}
          <SubLabelSection showError={hasError} error={error} subLabel={subLabel} />
        </div>
      </div>
    );
  }
}

SelectField.displayName = 'SelectField';
export default SelectField;
