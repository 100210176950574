import React from 'react';
import { hot } from 'react-hot-loader';
import ScrollToTop from 'shared/scrollToTop/ScrollToTopComponent';
import Toaster from 'app/toaster/Toaster';
import NetworkHandler from 'app/network/NetworkHandler';
import OfflineError from 'app/network/OfflineError';
import AppRoutes from './AppRoutes';
//import 'assets/scss/Saas.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';
import './index.scss';

function App() {
  return (
    <div id="app-react-root">
      <OfflineError />
      <ScrollToTop />
      <NetworkHandler />
      <Toaster />
      <AppRoutes />
    </div>
  );
}

export default hot(module)(App);
