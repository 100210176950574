import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon, Page } from 'shared';
import Vote from './Vote';

class PreviewPage extends React.Component {
  render() {
    const { backPath } = this.props;
    const { name, metricName } = this.props.match.params;

    return (
      <Page
        id="placeholder-component"
        centerElement={name}
        className='nested-page-content'
        backPath={{ to: backPath }}>
        <div style={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          textAlign: 'center',
          minHeight: '100%'
        }}>
          <div style={{ marginBottom: '30px' }}>
            <Icon symbol={metricName} fill="teal" size="48px" />
          </div>
          <p>
            {local[`interestMessage${metricName}`] || local.preview[`${metricName}Details`]}
          </p>
          <hr />
          <Vote name={name} metricName={metricName} />
        </div>
      </Page>
    );
  }
}
PreviewPage.propTypes = {
  backPath: PropTypes.string,
  // router
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string.isRequired,
      metricName: PropTypes.string.isRequired
    })
  })
};

PreviewPage.defaultProps = {
  backPath: routePaths.root
};

export default withRouter(PreviewPage);
