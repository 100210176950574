import React from 'react';
import local from 'services/localization/local';
import VerifyEmailLink from 'identity/verify-email/VerifyEmailLink';
import { isMobile } from 'mobile/helpers';
import PropTypes from 'prop-types';
import './not-verified-message.scss';

export default function NotVerifiedMessage({ message }) {
  const refreshMessage = isMobile ? local.verifyEmailBodyMobile : local.verifyEmailBodyBrowser;

  return (
    <div className='verify-email-message'>
      <h5>{local.verifyEmailHeader}</h5>
      <small>{message}</small>
      <br />
      <br />
      <small><VerifyEmailLink /></small>
      <br />
      <br />
      <small><i>{refreshMessage}</i></small>
    </div>
  );
}

NotVerifiedMessage.propTypes = {
  message: PropTypes.string.isRequired
};
