import { mobileAppRoot } from 'app/config';

const Capacitor = window.Capacitor;

// Device Status Exports
export const isMobile = Capacitor && Capacitor.isNative && window.location.host === 'localhost';
export const isIOS = isMobile && Capacitor.isIOS;
export const isAndroid = isMobile && Capacitor.isAndroid;

// Initialization
let Network;
let App;
let Browser;
let Device;
let Share;

export let isIpad = false;
export let isIphone = false;

if (isMobile) {
  if (Capacitor.isPluginAvailable('Network')) {
    Network = Capacitor.Plugins.Network;
  }

  if (Capacitor.isPluginAvailable('App')) {
    App = Capacitor.Plugins.App;
  }

  if (Capacitor.isPluginAvailable('Browser')) {
    Browser = Capacitor.Plugins.Browser;
  }

  if (Capacitor.isPluginAvailable('Share')) {
    Share = Capacitor.Plugins.Share;
  }

  if (Capacitor.isPluginAvailable('Device')) {
    Device = Capacitor.Plugins.Device;

    // Assign device info upon init
    Device.getInfo().then(deviceInfo => {
      isIpad = isIOS && deviceInfo.model === 'iPad';
      isIphone = isIOS && deviceInfo.model === 'iPhone';
    });
  }
}

// Network
let networkHandler;
export const assignNetworkChangeHandler = (onNetworkChange) => {
  if (Network) {
    networkHandler = Network.addListener('networkStatusChange', onNetworkChange);
  }
};

export const handleCurrentNetworkStatus = (onNetworkStatus) => {
  if (Network) {
    Network.getStatus().then(onNetworkStatus);
  }
};

export const removeNetworkChangeHandler = () => {
  if (networkHandler) {
    networkHandler.remove();
  }
};

// Browser
export const openRouteInApp = (url) => {
  if (Browser) {
    Browser.open({ url });
  } else {
    window.open(url);
  }
};

// App
export const openMobileRoute = (url) => {
  if (App) {
    App.openUrl({ url: `${mobileAppRoot}${url}` });
  }
};

let backButtonHandler;
export const addBackButtonListener = (handleBackButton) => {
  if (App) {
    removeBackButtonListener();
    backButtonHandler = App.addListener('backButton', handleBackButton);
  }
};

export const removeBackButtonListener = () => {
  if (backButtonHandler) {
    backButtonHandler.remove();
  }
};

export const exitMobileApp = () => {
  if (App && isAndroid) {
    App.exitApp();
  }
};

// Share
export const canShare = !!(Share);
export const share = (title, text, url, dialogTitle) => {
  if (Share) {
    return Share.share({
      title,
      text,
      url,
      dialogTitle
    });
  } else {
    return Promise.reject();
  }
};
