export const Countries = [
  { Key: 'AF', Value: 'Afghanistan' },
  { Key: 'AL', Value: 'Albania' },
  { Key: 'DZ', Value: 'Algeria' },
  { Key: 'AS', Value: 'American Samoa' },
  { Key: 'AD', Value: 'Andorra' },
  { Key: 'AO', Value: 'Angola' },
  { Key: 'AI', Value: 'Anguilla' },
  { Key: 'AQ', Value: 'Antarctica' },
  { Key: 'AG', Value: 'Antigua and Barbuda' },
  { Key: 'AR', Value: 'Argentina' },
  { Key: 'AM', Value: 'Armenia' },
  { Key: 'AW', Value: 'Aruba' },
  { Key: 'AU', Value: 'Australia' },
  { Key: 'AT', Value: 'Austria' },
  { Key: 'AZ', Value: 'Azerbaijan' },
  { Key: 'BS', Value: 'Bahamas' },
  { Key: 'BH', Value: 'Bahrain' },
  { Key: 'BD', Value: 'Bangladesh' },
  { Key: 'BB', Value: 'Barbados' },
  { Key: 'BY', Value: 'Belarus' },
  { Key: 'BE', Value: 'Belgium' },
  { Key: 'BZ', Value: 'Belize' },
  { Key: 'BJ', Value: 'Benin' },
  { Key: 'BM', Value: 'Bermuda' },
  { Key: 'BT', Value: 'Bhutan' },
  { Key: 'BO', Value: 'Bolivia' },
  { Key: 'BA', Value: 'Bosnia and Herzegovina' },
  { Key: 'BW', Value: 'Botswana' },
  { Key: 'BV', Value: 'Bouvet Island' },
  { Key: 'BR', Value: 'Brazil' },
  { Key: 'IO', Value: 'British Indian Ocean Territory' },
  { Key: 'BN', Value: 'Brunei Darussalam' },
  { Key: 'BG', Value: 'Bulgaria' },
  { Key: 'BF', Value: 'Burkina Faso' },
  { Key: 'BI', Value: 'Burundi' },
  { Key: 'KH', Value: 'Cambodia' },
  { Key: 'CM', Value: 'Cameroon' },
  { Key: 'CA', Value: 'Canada' },
  { Key: 'CV', Value: 'Cape Verde' },
  { Key: 'KY', Value: 'Cayman Islands' },
  { Key: 'CF', Value: 'Central African Republic' },
  { Key: 'TD', Value: 'Chad' },
  { Key: 'CL', Value: 'Chile' },
  { Key: 'CN', Value: 'China' },
  { Key: 'CX', Value: 'Christmas Island' },
  { Key: 'CC', Value: 'Cocos (Keeling) Islands' },
  { Key: 'CO', Value: 'Colombia' },
  { Key: 'KM', Value: 'Comoros' },
  { Key: 'CG', Value: 'Congo' },
  { Key: 'CD', Value: 'Congo' },
  { Key: 'CK', Value: 'Cook Islands' },
  { Key: 'CR', Value: 'Costa Rica' },
  { Key: 'CI', Value: 'Cote D\'Ivoire' },
  { Key: 'HR', Value: 'Croatia' },
  { Key: 'CU', Value: 'Cuba' },
  { Key: 'CY', Value: 'Cyprus' },
  { Key: 'CZ', Value: 'Czech Republic' },
  { Key: 'DK', Value: 'Denmark' },
  { Key: 'DJ', Value: 'Djibouti' },
  { Key: 'DM', Value: 'Dominica' },
  { Key: 'DO', Value: 'Dominican Republic' },
  { Key: 'EC', Value: 'Ecuador' },
  { Key: 'EG', Value: 'Egypt' },
  { Key: 'SV', Value: 'El Salvador' },
  { Key: 'GQ', Value: 'Equatorial Guinea' },
  { Key: 'ER', Value: 'Eritrea' },
  { Key: 'EE', Value: 'Estonia' },
  { Key: 'ET', Value: 'Ethiopia' },
  { Key: 'FK', Value: 'Falkland Islands (Malvinas)' },
  { Key: 'FO', Value: 'Faroe Islands' },
  { Key: 'FJ', Value: 'Fiji' },
  { Key: 'FI', Value: 'Finland' },
  { Key: 'FR', Value: 'France' },
  { Key: 'GF', Value: 'French Guiana' },
  { Key: 'PF', Value: 'French Polynesia' },
  { Key: 'TF', Value: 'French Southern Territories' },
  { Key: 'GA', Value: 'Gabon' },
  { Key: 'GM', Value: 'Gambia' },
  { Key: 'GE', Value: 'Georgia' },
  { Key: 'DE', Value: 'Germany' },
  { Key: 'GH', Value: 'Ghana' },
  { Key: 'GI', Value: 'Gibraltar' },
  { Key: 'GR', Value: 'Greece' },
  { Key: 'GL', Value: 'Greenland' },
  { Key: 'GD', Value: 'Grenada' },
  { Key: 'GP', Value: 'Guadeloupe' },
  { Key: 'GU', Value: 'Guam' },
  { Key: 'GT', Value: 'Guatemala' },
  { Key: 'GN', Value: 'Guinea' },
  { Key: 'GW', Value: 'Guinea-Bissau' },
  { Key: 'GY', Value: 'Guyana' },
  { Key: 'HT', Value: 'Haiti' },
  { Key: 'HM', Value: 'Heard Island and McDonald Islands' },
  { Key: 'VA', Value: 'Holy See (Vatican City State)' },
  { Key: 'HN', Value: 'Honduras' },
  { Key: 'HK', Value: 'Hong Kong' },
  { Key: 'HU', Value: 'Hungary' },
  { Key: 'IS', Value: 'Iceland' },
  { Key: 'IN', Value: 'India' },
  { Key: 'ID', Value: 'Indonesia' },
  { Key: 'IR', Value: 'Iran' },
  { Key: 'IQ', Value: 'Iraq' },
  { Key: 'IE', Value: 'Ireland' },
  { Key: 'IL', Value: 'Israel' },
  { Key: 'IT', Value: 'Italy' },
  { Key: 'JM', Value: 'Jamaica' },
  { Key: 'JP', Value: 'Japan' },
  { Key: 'JO', Value: 'Jordan' },
  { Key: 'KZ', Value: 'Kazakhstan' },
  { Key: 'KE', Value: 'Kenya' },
  { Key: 'KI', Value: 'Kiribati' },
  { Key: 'KP', Value: 'North Korea' },
  { Key: 'KR', Value: 'South Korea' },
  { Key: 'KW', Value: 'Kuwait' },
  { Key: 'KG', Value: 'Kyrgyzstan' },
  { Key: 'LA', Value: 'Lao People\'s Democratic Republic' },
  { Key: 'LV', Value: 'Latvia' },
  { Key: 'LB', Value: 'Lebanon' },
  { Key: 'LS', Value: 'Lesotho' },
  { Key: 'LR', Value: 'Liberia' },
  { Key: 'LY', Value: 'Libya' },
  { Key: 'LI', Value: 'Liechtenstein' },
  { Key: 'LT', Value: 'Lithuania' },
  { Key: 'LU', Value: 'Luxembourg' },
  { Key: 'MO', Value: 'Macao' },
  { Key: 'MG', Value: 'Madagascar' },
  { Key: 'MW', Value: 'Malawi' },
  { Key: 'MY', Value: 'Malaysia' },
  { Key: 'MV', Value: 'Maldives' },
  { Key: 'ML', Value: 'Mali' },
  { Key: 'MT', Value: 'Malta' },
  { Key: 'MH', Value: 'Marshall Islands' },
  { Key: 'MQ', Value: 'Martinique' },
  { Key: 'MR', Value: 'Mauritania' },
  { Key: 'MU', Value: 'Mauritius' },
  { Key: 'YT', Value: 'Mayotte' },
  { Key: 'MX', Value: 'Mexico' },
  { Key: 'FM', Value: 'Federated States of Micronesia' },
  { Key: 'MD', Value: 'Moldova, Republic of' },
  { Key: 'MC', Value: 'Monaco' },
  { Key: 'MN', Value: 'Mongolia' },
  { Key: 'MS', Value: 'Montserrat' },
  { Key: 'MA', Value: 'Morocco' },
  { Key: 'MZ', Value: 'Mozambique' },
  { Key: 'MM', Value: 'Myanmar' },
  { Key: 'NA', Value: 'Namibia' },
  { Key: 'NR', Value: 'Nauru' },
  { Key: 'NP', Value: 'Nepal' },
  { Key: 'NL', Value: 'Netherlands' },
  { Key: 'NC', Value: 'New Caledonia' },
  { Key: 'NZ', Value: 'New Zealand' },
  { Key: 'NI', Value: 'Nicaragua' },
  { Key: 'NE', Value: 'Niger' },
  { Key: 'NG', Value: 'Nigeria' },
  { Key: 'NU', Value: 'Niue' },
  { Key: 'NF', Value: 'Norfolk Island' },
  { Key: 'MK', Value: 'North Macedonia' },
  { Key: 'MP', Value: 'Northern Mariana Islands' },
  { Key: 'NO', Value: 'Norway' },
  { Key: 'OM', Value: 'Oman' },
  { Key: 'PK', Value: 'Pakistan' },
  { Key: 'PW', Value: 'Palau' },
  { Key: 'PS', Value: 'Palestine' },
  { Key: 'PA', Value: 'Panama' },
  { Key: 'PG', Value: 'Papua New Guinea' },
  { Key: 'PY', Value: 'Paraguay' },
  { Key: 'PE', Value: 'Peru' },
  { Key: 'PH', Value: 'Philippines' },
  { Key: 'PN', Value: 'Pitcairn' },
  { Key: 'PL', Value: 'Poland' },
  { Key: 'PT', Value: 'Portugal' },
  { Key: 'PR', Value: 'Puerto Rico' },
  { Key: 'QA', Value: 'Qatar' },
  { Key: 'RE', Value: 'Reunion' },
  { Key: 'RO', Value: 'Romania' },
  { Key: 'RU', Value: 'Russia' },
  { Key: 'RW', Value: 'Rwanda' },
  { Key: 'SH', Value: 'Saint Helena' },
  { Key: 'KN', Value: 'Saint Kitts and Nevis' },
  { Key: 'LC', Value: 'Saint Lucia' },
  { Key: 'PM', Value: 'Saint Pierre and Miquelon' },
  { Key: 'VC', Value: 'Saint Vincent and the Grenadines' },
  { Key: 'WS', Value: 'Samoa' },
  { Key: 'SM', Value: 'San Marino' },
  { Key: 'ST', Value: 'Sao Tome and Principe' },
  { Key: 'SA', Value: 'Saudi Arabia' },
  { Key: 'SN', Value: 'Senegal' },
  { Key: 'SC', Value: 'Seychelles' },
  { Key: 'SL', Value: 'Sierra Leone' },
  { Key: 'SG', Value: 'Singapore' },
  { Key: 'SK', Value: 'Slovakia' },
  { Key: 'SI', Value: 'Slovenia' },
  { Key: 'SB', Value: 'Solomon Islands' },
  { Key: 'SO', Value: 'Somalia' },
  { Key: 'ZA', Value: 'South Africa' },
  { Key: 'GS', Value: 'South Georgia and the South Sandwich Islands' },
  { Key: 'ES', Value: 'Spain' },
  { Key: 'LK', Value: 'Sri Lanka' },
  { Key: 'SD', Value: 'Sudan' },
  { Key: 'SR', Value: 'Suriname' },
  { Key: 'SJ', Value: 'Svalbard and Jan Mayen' },
  { Key: 'SZ', Value: 'Eswatini' },
  { Key: 'SE', Value: 'Sweden' },
  { Key: 'CH', Value: 'Switzerland' },
  { Key: 'SY', Value: 'Syrian Arab Republic' },
  { Key: 'TW', Value: 'Taiwan' },
  { Key: 'TJ', Value: 'Tajikistan' },
  { Key: 'TZ', Value: 'Tanzania' },
  { Key: 'TH', Value: 'Thailand' },
  { Key: 'TL', Value: 'Timor-Leste' },
  { Key: 'TG', Value: 'Togo' },
  { Key: 'TK', Value: 'Tokelau' },
  { Key: 'TO', Value: 'Tonga' },
  { Key: 'TT', Value: 'Trinidad and Tobago' },
  { Key: 'TN', Value: 'Tunisia' },
  { Key: 'TR', Value: 'Turkey' },
  { Key: 'TM', Value: 'Turkmenistan' },
  { Key: 'TC', Value: 'Turks and Caicos Islands' },
  { Key: 'TV', Value: 'Tuvalu' },
  { Key: 'UG', Value: 'Uganda' },
  { Key: 'UA', Value: 'Ukraine' },
  { Key: 'AE', Value: 'United Arab Emirates' },
  { Key: 'GB', Value: 'United Kingdom' },
  { Key: 'US', Value: 'United States of America' },
  { Key: 'UM', Value: 'United States Minor Outlying Islands' },
  { Key: 'UY', Value: 'Uruguay' },
  { Key: 'UZ', Value: 'Uzbekistan' },
  { Key: 'VU', Value: 'Vanuatu' },
  { Key: 'VE', Value: 'Venezuela' },
  { Key: 'VN', Value: 'Vietnam' },
  { Key: 'VG', Value: 'Virgin Islands, British' },
  { Key: 'VI', Value: 'Virgin Islands, U.S.' },
  { Key: 'WF', Value: 'Wallis and Futuna' },
  { Key: 'EH', Value: 'Western Sahara' },
  { Key: 'YE', Value: 'Yemen' },
  { Key: 'ZM', Value: 'Zambia' },
  { Key: 'ZW', Value: 'Zimbabwe' },
  { Key: 'AX', Value: 'Åland Islands' },
  { Key: 'BQ', Value: 'Bonaire, Sint Eustatius and Saba' },
  { Key: 'CW', Value: 'Curaçao' },
  { Key: 'GG', Value: 'Guernsey' },
  { Key: 'IM', Value: 'Isle of Man' },
  { Key: 'JE', Value: 'Jersey' },
  { Key: 'ME', Value: 'Montenegro' },
  { Key: 'BL', Value: 'Saint Barthélemy' },
  { Key: 'MF', Value: 'Saint Martin (French part)' },
  { Key: 'RS', Value: 'Serbia' },
  { Key: 'SX', Value: 'Sint Maarten (Dutch part)' },
  { Key: 'SS', Value: 'South Sudan' },
  { Key: 'XK', Value: 'Kosovo' }
];
