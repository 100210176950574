import React from 'react';
import PropTypes from 'prop-types';
import withContext from 'app/withContext';
import local from 'services/localization/local';
import { Header, BackAction, FloatActionMenu, DataLoadingStatus, BottomNavigation } from 'shared';

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.buildLeftElement = this.buildLeftElement.bind(this);
    this.getDocumentcenterElement = this.getDocumentcenterElement.bind(this);
    this.getMainContent = this.getMainContent.bind(this);
  }

  componentDidMount() {
    document.title = this.getDocumentcenterElement();
  }

  componentWillUnmount() {
    document.title = local.app;
  }

  getDocumentcenterElement() {
    const { centerElement, titleTabText } = this.props;
    if (titleTabText) {
      return `${titleTabText} - ${local.app}`;
    }
    if (centerElement && typeof centerElement == 'string') {
      return `${centerElement} - ${local.app}`;
    }
    return local.app;
  }

  buildLeftElement() {
    const { backAction, backPath, readOnlyProfile } = this.props;
    if ((backPath && backPath.to) || backAction) {
      return null;
    }

    return null;
  }

  buildFarLeftElement() {
    const { backAction, backPath, readOnlyProfile } = this.props;
    if (backAction) {
      return backAction;
    }

    if (backPath && backPath.to) {
      const { to, params } = backPath;
      return <BackAction to={to} params={params} />;
    }

    return null;
  }

  getMainContent() {
    const { children, floatAction, loading, error } = this.props;
    const floatMenu = floatAction && (
      <FloatActionMenu>
        {floatAction}
      </FloatActionMenu>
    );

    return (
      <React.Fragment>
        <DataLoadingStatus loading={loading} error={error} overlay>
          {children}
          {floatMenu}
        </DataLoadingStatus>
      </React.Fragment>
    );
  }

  render() {
    const { id, subHeader, centerElement, className, primaryAction, secondaryAction, hideBottomNav } = this.props;
    const leftElement = this.buildLeftElement();
    const farLeftElement = this.buildFarLeftElement();

    return (
      <div data-testid={id} id={id} className={className}>
        <Header farLeftElement={farLeftElement} leftElement={leftElement} rightElement={secondaryAction} farRightElement={primaryAction} centerElement={centerElement}>
          {subHeader}
        </Header>
        <main id="mainContentContainer">
          {this.getMainContent()}
        </main>
        {!hideBottomNav && <BottomNavigation />}
      </div >
    );
  }
}

Page.propTypes = {
  id: PropTypes.string.isRequired,
  subHeader: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  backAction: PropTypes.node,
  primaryAction: PropTypes.node,
  secondaryAction: PropTypes.node,
  backPath: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ to: PropTypes.string, params: PropTypes.shape() })]),
  centerElement: PropTypes.node,
  titleTabText: PropTypes.string,
  floatAction: PropTypes.node,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  readOnlyProfile: PropTypes.bool,
  // router
  history: PropTypes.shape({})
};

Page.defaultProps = {
  className: 'full-page-content'
};

export default withContext(Page);

