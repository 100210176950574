import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { buildPath } from 'services/routing';

export default function withNavigation(WrappedComponent) {
  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.navigate = this.navigate.bind(this);
    }

    navigate({ to, params, navAction }) {
      const { history } = this.props;

      if (navAction) {
        navAction();
        return;
      }

      if (to) {
        const path = buildPath(to, params);
        history.push(path);
        return;
      }

      throw Error('Invalid Argument Error');
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { history, location, match, dispatch, ...rest } = this.props;

      return <WrappedComponent navigate={this.navigate} {...rest} />;
    }
  }

  Wrapper.propTypes = {
    // router
    history: PropTypes.shape().isRequired
  };

  return withRouter(Wrapper);
}
