import React, { useState } from 'react';
import { connect } from 'react-redux';

import { LinkWrapper, NonAuthenticatedPageWall } from 'shared';
import local from 'services/localization/local';
import { bindActionCreators } from 'redux';
import SignUpStepOne from './SignUpStepOne';
import SignUpSteps from './SignUpSteps';
import { checkEmailExists, onboardUser } from 'identity/action-creators';

import SignUpStepTwo from './SignUpStepTwo';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

function Signup({ errorMessage, isEmailAlreadyExists, stepOneCompleted, actions, isLoading }) {

  const navAction = <LinkWrapper id='already-have-account-sign-in-nav' className='button gray' to='/'>{local.linkSignIn}</LinkWrapper>;
  const content = local.nonAuthWallPage;
  const [userStepOneDetails, setUserStepOneDetails] = useState({});

  const proceedToNextStep = (stepOneDetails) => {
    actions.checkEmailExists(stepOneDetails.email);
    setUserStepOneDetails(stepOneDetails);
  };

  const startTrail = (stepTwoDetails) => {
    let allDetails = {
      ...userStepOneDetails,
      ...stepTwoDetails
    };
    actions.onboardUser(allDetails);
  };

  return (
    <div>
      <Header></Header>
      <div className='registerBlock'>
        <NonAuthenticatedPageWall id='register' titleTabText={local.register} navAction={navAction}>
          <div>
            <h2>{content.header}</h2>
            {local.signUpFlow.subHead}
            <SignUpSteps stepOneCompleted={stepOneCompleted}></SignUpSteps>
          </div>
          <div>
            <div className='signIn-block my-4'>
              <div>
                {errorMessage && errorMessage.length > 0 &&
                <div className='alert alert-danger'>
                  {errorMessage}
                </div>}
                {!stepOneCompleted && <SignUpStepOne proceedToNextStep={proceedToNextStep} isEmailExists={isEmailAlreadyExists} isLoading={isLoading} />}
                {stepOneCompleted && <SignUpStepTwo startTrail={startTrail} isLoading={isLoading} selectedSolution={userStepOneDetails.solution} />}
              </div>
            </div>
          </div>
        </NonAuthenticatedPageWall>
      </div>
      <Footer></Footer>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  const actions = { checkEmailExists, onboardUser };
  return { actions: bindActionCreators(actions, dispatch) };
}

function mapStateToProps(state) {
  return {
    errorMessage: state.identity.signUpError,
    isEmailAlreadyExists: state.identity.isEmailExists,
    stepOneCompleted: state.identity.stepOneCompleted,
    isLoading: state.identity.signUpLoading
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
