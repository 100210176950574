/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styleVars from 'styles/_variables.scss';
import symbolPaths from './symbol-paths';

class IconsComponent extends React.Component {
  resolveColor(color) {
    switch (color) {
      case 'white':
        return styleVars.colorWhite;
      case 'gray':
        return styleVars.colorGray;
      case 'black':
        return styleVars.colorBlack;
      case 'teal':
        return styleVars.colorTeal;
      case 'magenta':
        return styleVars.colorMagenta;
      case 'blue':
        return styleVars.colorBlue;
      case 'yellow':
        return styleVars.colorYellow;
      case 'green':
        return styleVars.colorGreen;
      default:
        return 'currentColor';
    }
  }

  render() {
    const { className, size, fill, symbol, ...rest } = this.props;

    return (
      <svg
        viewBox="0 0 24 24"
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        className={`ui-icon ${className}`}
        style={{ fill: this.resolveColor(fill) }}>
        <path d={symbolPaths[symbol]} />
      </svg>
    );
  }
}

IconsComponent.displayName = 'Icon Component';
IconsComponent.propTypes = {
  symbol: PropTypes.string.isRequired,
  fill: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
};
IconsComponent.defaultProps = {
  fill: null,
  size: '24px'
};

export default IconsComponent;
