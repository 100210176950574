import moment from 'moment';
import { profilesApiRoot as apiRoot, authClientId } from 'app/config';
import { httpGet, httpPost, httpPatch, httpDelete, buildUrl, parseSuccess, parseError } from './api';

export const getIdentity = (state, branchData) => {
  const url = buildUrl(apiRoot, '/identities', { marketingSourceData: branchData });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const registerUser = (request, state) => {
  return httpPost(buildUrl(apiRoot, '/identities/register', {verifyByCode: true}), request, state)
    .catch(parseError);
};

export const resendVerificationEmail = (state) => {
  return httpPost(buildUrl(apiRoot, '/identities/verify-email', {includeVerificationCode: true}), { auth0ClientId: authClientId }, state)
    .catch(parseError);
};

export const resendConfirmEmail = (email,state, isFromProvider) => {
  return httpPost(`${apiRoot}/identities/confirm-email`, { auth0ClientId: authClientId,email:email, isFromProvider }, state)
    .catch(parseError);
};

export const updatePreference = (body, state) => {
  return httpPatch(`${apiRoot}/identities/updatepreference`, body, state);
};

export const getProfile = state =>
  httpGet(`${apiRoot}/me`, state)
    .then(parseSuccess);

export const addProfile = (profile, state) => {
  const utcOffsetMinutes = moment().utcOffset();
  return httpPost(`${apiRoot}/profiles`, { ...profile, utcOffsetMinutes }, state)
    .then(parseSuccess);
};

export const updateSelfProfile = (profile, state) =>
  httpPatch(`${apiRoot}/me`, profile, state);

export const createProfileInvite = (request, state) =>
  httpPost(`${apiRoot}/profiles/invites`, request, state)
    .catch(parseError);

export const deleteProfileInvite = (inviteId, state) =>
  httpDelete(`${apiRoot}/profiles/invites/${inviteId}`, null, state)
    .catch(parseError);

export const respondToProfileInvite = (request, state) =>
  httpPost(`${apiRoot}/profiles/invites/respond`, request, state)
    .catch(parseError);

export const revokeSharingAccess = (request, profileId, state) =>
  httpPost(`${apiRoot}/profiles/${profileId}/revoke`, request, state)
    .catch(parseError);

export const getIncomingProfileInvites = state =>
  httpGet(`${apiRoot}/profiles/invites`, state)
    .then(parseSuccess);

export const getIncomingProfileInvitesCount = state =>
  httpGet(`${apiRoot}/profiles/invites-count`, state)
    .then(parseSuccess);

export const getInviteStatuses = (profileId, state) =>
  httpGet(`${apiRoot}/profiles/${profileId}/access`, state)
    .then(parseSuccess);

export const getProfileList = state =>
  httpGet(`${apiRoot}/profiles/related`, state)
    .then(parseSuccess);

export const getProfileById = (profileId, state) =>
  httpGet(`${apiRoot}/profiles/${profileId}`, state)
    .then(parseSuccess);

export const updateProfile = (profile, state) =>
  httpPatch(`${apiRoot}/profiles/${profile.id}`, profile, state)
    .then(parseSuccess);

export const verifyProfile = (profile, requireSsn, state) => {
  var url = buildUrl(apiRoot, `/profiles/verify/${profile.profileId}`, { requireSsn });

  return httpPatch(url, profile, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const verifyProfileBySsn = (profileId, ssn4, state) =>
  httpPost(`${apiRoot}/profiles/verify/${profileId}/ssn`, { ssn4 }, state)
    .then(parseSuccess)
    .catch(parseError);

export const answerQuestions = (profileId, answeredQuestions, state) =>
  httpPost(`${apiRoot}/profiles/verify/${profileId}`, { answeredQuestions }, state)
    .then(parseSuccess)
    .catch(parseError);

export const hipaaConsent = (profileId, state) =>
  httpPatch(`${apiRoot}/profiles/hipaaconsent/${profileId}`, { hipaaConsentVersion: 2 }, state);

export const getHipaaConsent = (profileId, state) =>
  httpGet(`${apiRoot}/profiles/hipaaconsent/${profileId}`, state)
    .catch(parseError);

export const getMarketplaceList = state =>
  httpGet(`${apiRoot}/marketplace`, state)
    .then(parseSuccess);

export const getOrganizations = (statuses, roles, state) => {
  const url = buildUrl(apiRoot, '/identity/organizations', { memberStatuses: statuses, memberRoles: roles });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const createOrganization = (requestBody, state) => {
  return httpPost(`${apiRoot}/organizations`, requestBody, state)
    .catch(parseError);
};

export const leaveOrganization = (organizationMembershipId, organizationId, state) => {
  const url = buildUrl(apiRoot, `/organizations/${organizationId}/members`);

  return httpDelete(url, { organizationMembershipId: organizationMembershipId }, state)
    .catch(parseError);
};

export const getOrganizationInvites = state =>
  httpGet(`${apiRoot}/identity/organizations/invites`, state)
    .then(parseSuccess);

export const getOrganizationInvitesCount = state =>
  httpGet(`${apiRoot}/identity/organizations/invites-count`, state)
    .then(parseSuccess);

export const submitOrganizationInviteCode = (state, inviteCode) => {
  return httpPost(`${apiRoot}/organizations/invite-code`, { inviteCode }, state)
    .catch(parseError)
    .then(parseSuccess);
};

export const respondToOrganizationInvite = (state, organizationId, requestBody) => {
  const url = `${apiRoot}/organizations/${organizationId}/members`;

  return httpPost(url, requestBody, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getOrganizationMembersByIdentity = (statuses, roles, organizationId, state) => {
  const url = buildUrl(apiRoot, `/identity/organizations/${organizationId}/members`, { status: statuses, role: roles });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getOrganizationAdmins = (organizationId, state) => {
  const url = `${apiRoot}/organizations/${organizationId}/admins`;
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getAuthConnections = (state, domainName) => {
  const url = buildUrl(apiRoot, '/auth-connections/', { domainName });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getIdentityOrganizations = (state, organizationIds, memberStatuses, memberRoles, organizationStatuses) => {
  const organizationSearchModel = {
    organizationIds,
    memberStatuses,
    memberRoles,
    organizationStatuses
  };
  const url = buildUrl(apiRoot, '/identity/organizations', organizationSearchModel );
  return httpGet(url, state)
    .then(parseSuccess);
};

export const isEmailExists = (state, email) => {
  const url = buildUrl(apiRoot, '/identities/email-exists', { email });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const onboardUser = (state, requestBody) => {
  return httpPost(`${apiRoot}/organizations/onboard`, requestBody , state)
    .catch(parseError)
    .then(parseSuccess);
};

export const getSelfRegisterationProducts = state =>
  httpGet(`${apiRoot}/subscriptions/self-registeration-products`, state)
    .then(parseSuccess);

export const verifyEmailWithCode = (verificationCode, state) =>
  httpPost(buildUrl(apiRoot, '/identities/verify-email-by-code', { verificationCode }), { auth0ClientId: authClientId }, state)
    .then(parseSuccess)
    .catch(parseError);
