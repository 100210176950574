import React, { useState } from 'react';
import { LoadingIcon } from 'shared';
import local from 'services/localization/local';
import { LinkWrapper } from 'shared';
import PrivacyPolicyEulaSection from 'app/privacy-policy-eula/PrivacyPolicyEulaSection';
import { Countries } from 'shared/data/countries';
import { HowDidyouHearAboutCalciumOptions, SelectText } from 'services/constants';
import { SubscriptionProducts } from 'services/enums';

export default function SignUpStepTwo({ startTrail, isLoading, selectedSolution }) {
  const [companyName, setCompanyName] = useState('');
  const [companyNameFeedback, setCompanyNameFeedback] = useState('');
  const [numberOfEmployees, setNumberOfEmployees] = useState('');
  const [numberOfEmployeesFeedback, setNumberOfEmployeesFeedback] = useState('');
  const [companyLocation, setCompanyLocation] = useState('US');
  const [howDidYouHearAboutCalcium, setHowDidYouHearAboutCalcium] = useState('');
  const [howDidYouHearAboutCalciumFeedback, setHowDidYouHearAboutCalciumFeedback] = useState('');

  const privacyPolicySection = <PrivacyPolicyEulaSection buttonNameText={local.signUpFlow.steps.step2.btnText} />;
  const validateStepTwoDetails = () => {
    let isValid = true;
    if (!companyName || companyName.trim() == '') {
      setCompanyNameFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }

    if (!numberOfEmployees || numberOfEmployees.trim() == '') {
      setNumberOfEmployeesFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }
    else if (!numberOfEmployees.match(/^\d+$/)) {
      setNumberOfEmployeesFeedback(selectedSolution === SubscriptionProducts.Potal30Covid ? local.signUpFlow.validationMessages.invalidEmployeeNumbers : local.signUpFlow.validationMessages.invalidPatientNumbers);
      isValid = false;
    }

    if (!howDidYouHearAboutCalcium) {
      setHowDidYouHearAboutCalciumFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }
    return isValid;
  };

  const resetFeedback = () => {
    setCompanyNameFeedback('');
    setNumberOfEmployeesFeedback('');
    setHowDidYouHearAboutCalciumFeedback('');
  };

  const handleNumberOfEmployeesChange = (event) =>{
    if(event.target.validity.valid){
      setNumberOfEmployees(event.target.value);
    }
  };


  const submitClick = () => {
    resetFeedback();
    if (validateStepTwoDetails()) {
      let stepTwoDetails = {
        companyName,
        numberOfEmployees,
        companyLocation,
        howDidYouHearAboutCalcium
      };
      startTrail(stepTwoDetails);
    }
  };
  return (
    <div>
      <h4>{local.signUpFlow.steps.step2.step2Title}</h4>
      <form className='p-0'>
        <div className='form-group'>
          <label htmlFor='companyName' className='form-label'>
            {local.signUpFlow.steps.step2.formLabels.companyName}
          </label>
          <input type='text' data-testid='companyName' className={`${companyNameFeedback.length > 0 ? 'is-invalid' : ''} form-control`} required maxLength={150} id='companyName' value={companyName} onChange={(event) => { resetFeedback(), setCompanyName(event.target.value); }} />
          <div data-testid='invalid-companyName' className='invalid-feedback'>
            {companyNameFeedback}
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='numberOfEmployees' className='form-label'>
            {selectedSolution === SubscriptionProducts.Potal30Covid ? local.signUpFlow.steps.step2.formLabels.numberOfEmployees : local.signUpFlow.steps.step2.formLabels.numberOfPatients}
          </label>
          <input type='text' className={`${numberOfEmployeesFeedback.length > 0 ? 'is-invalid' : ''} form-control`} required pattern='[0-9]*' maxLength={7} id='numberOfEmployees' value={numberOfEmployees} onChange={(event) => { resetFeedback(), handleNumberOfEmployeesChange(event); }} />
          <div data-testid='invalid-firstname' className='invalid-feedback'>
            {numberOfEmployeesFeedback}
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='location-select' className='form-label'>
            {local.signUpFlow.steps.step2.formLabels.companyLocation}
          </label>
          <select className='form-select' id='location-select' value={companyLocation} onChange={(event) => { setCompanyLocation(event.target.value); }}>
            {Countries.map((country) => { return <option key={country.Key} value={country.Key}>{country.Value}</option>; })}
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='how-did-hear-select' className='form-label'>{local.signUpFlow.steps.step2.formLabels.howDidyouHearAboutCalcium}</label>
          <select className={`${howDidYouHearAboutCalciumFeedback.length > 0 ? 'is-invalid' : ''} form-select`} id='how-did-hear-select' value={howDidYouHearAboutCalcium} onChange={(event) => { resetFeedback(), setHowDidYouHearAboutCalcium(event.target.value); }}>
            <option value=''>{SelectText}</option>
            {HowDidyouHearAboutCalciumOptions.map((data) => { return <option key={data} value={data}>{data}</option>; })}
          </select>
          <div data-testid='invalid-hear' className='invalid-feedback'>
            {howDidYouHearAboutCalciumFeedback}
          </div>
        </div>
        <div className='form-group mt-5'>
          <input type='button' disabled={isLoading} onClick={() => { submitClick(); }} className='btn btn-primary w-100 mb-3' id='submit-step2' value={local.signUpFlow.steps.step2.btnText} />
          {isLoading && <LoadingIcon />}
        </div>
      </form >

      <div className='secondary-links'>
        {privacyPolicySection}
        <small>{local.signUpFlow.alreadyHaveAccount} <LinkWrapper id='already-have-account-sign-in' to='/'>{local.signUpFlow.linkSignIn}</LinkWrapper></small>
      </div>
    </div>
  );
}
