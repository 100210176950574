import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'shared/icon/Icon';

function ErrorLabel(props) {
  const { show, error } = props;
  if (!show || error === ' ') {
    return null;
  }
  return (
    <span className="errorLabel">
      <Icon symbol="error" size="1em" />
      <small>{error}</small>
    </span>
  );
}

ErrorLabel.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.string
};

export default ErrorLabel;
