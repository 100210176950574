import React from 'react';
import local from 'services/localization/local';

export default function SignUpSteps({ stepOneCompleted }) {
  return (
    <div className='btn-group w-100 my-4' role='group'>
      <button data-testid="step1" type='button' className={!stepOneCompleted ? 'btn btn-light btn-1 current' : 'btn btn-light btn-1'}>
        <div className='text-start'>
          <h6 className='mb-0'>{local.signUpFlow.steps.step1.step1Title}</h6>
          <small>{local.signUpFlow.steps.step1.step1Tag}</small>
        </div>
      </button>
      <button data-testid='step2' type='button' className={stepOneCompleted ? 'btn btn-light btn-2 current' : 'btn btn-light btn-2'}>
        <div className='text-start'>
          <h6 className='mb-0'>{local.signUpFlow.steps.step2.step2Title}</h6>
          <small>{local.signUpFlow.steps.step2.step2Tag}</small>
        </div>
      </button>
    </div>
  );
}
