import React, { useEffect, useState } from 'react';
import { LoadingIcon, LinkWrapper } from 'shared';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import local from 'services/localization/local';
import { PhoneNumberRegex, emailRegex, appNames, PasswordMinLength } from 'services/constants';
import { getAuthConnection, resetConnection } from 'auth-connections/auth-connection-actions';
import { authConnection } from 'auth-connections/auth-connection-selectors';
import { signIn } from '../../identity/action-creators';
import { getSelfRegisterationSubscriptionProducts } from 'identity/action-creators';
import { getQueryStringParams } from 'services/url-service';
import { App, SubscriptionProducts } from 'services/enums';

function SignUpStepOne({ proceedToNextStep, isEmailExists, authConnection, actions, isLoading, solutions, solutionsLoaded }) {
  const [firstName, setFirstName] = useState('');
  const [firstNameFeedback, setFirstNameFeedback] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameFeedback, setLastNameFeedback] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberFeedback, setPhoneNumberFeedback] = useState('');
  const [solution, setSolution] = useState(SubscriptionProducts.Potal30Covid);
  const [solutionFeedback, setSolutionFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [emailFeedback, setEmailFeedback] = useState('');
  const [password, setPassword] = useState('');
  const [passwordFeedback, setPasswordFeedback] = useState('');

  useEffect(() => {
    actions.getSelfRegisterationSubscriptionProducts();
  }, [])

  useEffect(() => {
    if(solutionsLoaded){
      setDefaultSolution();
    }
  }, [solutionsLoaded]);

  useEffect(() => {
    if (isEmailExists) {
      setEmailFeedback(local.signUpFlow.validationMessages.emailExists);
    }
    else {
      setEmailFeedback('');
    }
  }, [isEmailExists]);

  useEffect(() => {
    if (authConnection && authConnection.loaded && authConnection.connectionNotFound) {
      proceedToNextStep({
        firstName,
        lastName,
        phoneNumber,
        solution,
        email,
        password
      });
    }
    else if (authConnection && authConnection.loaded && !authConnection.connectionNotFound) {
      actions.signIn(email, null, authConnection.connection);
    }
  }, [authConnection]);


  const validateStepOneDetails = () => {
    let isValid = true;
    if (!firstName || firstName.trim() == '') {
      setFirstNameFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }
    if (!lastName || lastName.trim() == '') {
      setLastNameFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }

    if (!phoneNumber || phoneNumber.trim() == '') {
      setPhoneNumberFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }
    else if (!phoneNumber.match(PhoneNumberRegex)) {
      setPhoneNumberFeedback(local.signUpFlow.validationMessages.invalidPhoneNumber);
      isValid = false;
    }

    if(!solution || (solutions && Object.keys(solutions).length === 0)){
      setSolutionFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }

    if (!email || email.trim() == '') {
      setEmailFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }
    else if (!email.match(emailRegex)) {
      setEmailFeedback(local.signUpFlow.validationMessages.invalidEmail);
      isValid = false;
    }
    else {
      setEmail(email.trim().toLocaleLowerCase());
    }

    if (!password || password.trim() == '') {
      setPasswordFeedback(local.signUpFlow.validationMessages.required);
      isValid = false;
    }
    else if (password.length < PasswordMinLength) {
      setPasswordFeedback(`${PasswordMinLength} ${local.signUpFlow.validationMessages.invalidPassword}`);
      isValid = false;
    }
    return isValid;
  };

  const submitClick = () => {
    resetFeedback();
    if (validateStepOneDetails()) {
      const domain = email.substring(email.lastIndexOf('@') + 1);
      actions.getAuthConnection(domain);
    }
  };

  const resetFeedback = () => {
    setFirstNameFeedback('');
    setLastNameFeedback('');
    setPhoneNumberFeedback('');
    setEmailFeedback('');
    setPasswordFeedback('');
  };

  const handlePhoneNumberChange = (event) => {
    if (event.target.validity.valid) {
      setPhoneNumber(event.target.value);
    }
  };

  const setDefaultSolution = () => {
    const query = getQueryStringParams(location);
    var app = query.app;
    if (app != undefined) {
      let defaultSolutionSelection = solutions.find(s=> s.aliasName.toLocaleLowerCase() == app.toLocaleLowerCase());
      if(defaultSolutionSelection){
        setSolution(defaultSolutionSelection.id)
      }
    }
  };

  return (
    <div>
      <h4>
        {local.signUpFlow.steps.step1.step1Title}
      </h4>
      <form className='p-0 needs-validation' action='#'>
        <div className='form-group'>
          <label htmlFor='firstname' className='form-label'>
            {local.signUpFlow.steps.step1.formLabels.firstName}
          </label>
          <input type='text' data-testid='firstName' className={`${firstNameFeedback.length > 0 ? 'is-invalid' : ''} form-control`} required maxLength={30} id='firstname' value={firstName} onChange={(event) => { resetFeedback(), setFirstName(event.target.value); }} />
          <div data-testid='invalid-firstname' className='invalid-feedback'>
            {firstNameFeedback}
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='lastName' className='form-label'>
            {local.signUpFlow.steps.step1.formLabels.lastName}
          </label>
          <input type='text' data-testid='lastName' className={`${lastNameFeedback.length > 0 ? 'is-invalid' : ''} form-control`} required maxLength={30} id='lastname' value={lastName} onChange={(event) => { resetFeedback(), setLastName(event.target.value); }} />
          <div data-testid='invalid-lastname' className='invalid-feedback'>
            {lastNameFeedback}
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='phonenumber' className='form-label'>
            {local.signUpFlow.steps.step1.formLabels.phoneNumber}
          </label>
          <input type='text' data-testid="phoneNumber" className={`${phoneNumberFeedback.length > 0 ? 'is-invalid' : ''} form-control`} required pattern='[0-9]*' maxLength={20} id='phonenumber' value={phoneNumber} onChange={(event) => { resetFeedback(), handlePhoneNumberChange(event); }} />
          <div data-testid='invalid-phoneNumber' className='invalid-feedback'>
            {phoneNumberFeedback}
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='solution-select' className='form-label'>
            {local.signUpFlow.steps.step1.formLabels.solution}
          </label>
          <select data-testid='solution-select' className={`${solutionFeedback.length > 0 ? 'is-invalid' : ''} form-select`} id='solution-select' value={solution} onChange={(event) => { resetFeedback(), setSolution(event.target.value); }}>
            {solutions && 
              solutions.map((data, i) => {
                return <option key={i} value={data.id}>{data.displayName}</option>
              })
            }
          </select>
          <div data-testid='invalid-solution' className='invalid-feedback'>
            {solutionFeedback}
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='email-username' className='form-label'>
            {local.signUpFlow.steps.step1.formLabels.email}
          </label>
          <input type='text' data-testid='email' className={`${emailFeedback.length > 0 ? 'is-invalid' : ''} form-control`} required maxLength={250} id='email-username' value={email} onChange={(event) => { resetFeedback(), setEmail(event.target.value); }} />
          <div data-testid='invalid-email' className='invalid-feedback'>
            {emailFeedback}
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='password' className='form-label'>
            {local.formatString(local.signUpFlow.steps.step1.formLabels.password, PasswordMinLength)}
          </label>
          <input type='password' data-testid='password' className={`${passwordFeedback.length > 0 ? 'is-invalid' : ''} form-control`} required maxLength={30} minLength={8} id='password' value={password} onChange={(event) => { resetFeedback(), setPassword(event.target.value); }} />
          <div data-testid='invalid-password' className='invalid-feedback'>
            {passwordFeedback}
          </div>
        </div>
        <div className='form-group mt-5'>
          <input type='button' data-testid="btnStepOneContinue" disabled={isLoading} onClick={() => submitClick()} className='btn btn-primary w-100 mb-3' id='submit-step1' value={local.formContinueButton} />
          {(isLoading || authConnection.loading) && <LoadingIcon />}
        </div>
      </form >
      <div className='secondary-links'>
        <small>{local.signUpFlow.alreadyHaveAccount} <LinkWrapper id='already-have-account-sign-in' to='/'>{local.signUpFlow.linkSignIn}</LinkWrapper></small>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  const actions = { getAuthConnection, resetConnection, signIn, getSelfRegisterationSubscriptionProducts };
  return { actions: bindActionCreators(actions, dispatch) };
}

function mapStateToProps(state) {
  return {
    authConnection: authConnection(state),
    solutions: state.identity.solutions,
    solutionsLoaded: state.identity.solutionsLoaded
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpStepOne);
