// place new icon paths here - use icons with 24px x 24px viewbox
export default {
  activity: 'M18.4 8.9c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0-7c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm4.8 8.3c-.3-.7-1.1-1.1-1.8-1.1-.3 0-.6.1-.8.2-.2.1-.9.4-2 .4s-2.4-.3-3.7-1.5C13 6.4 11 5.8 9.4 5.8c-1.7 0-3.2.7-3.9 1.3C4.4 7.7 4.3 9 5 9.9c.4.5 1 .8 1.6.8.4 0 .9-.1 1.2-.4 0 0 .5-.4 1.4-.4.7 0 1.7.3 2.9 1.3 0 0 .1 0 .1.1 0 0-2.5 3.8-3.5 4.9-.9 1-3.6 2.9-6 2.9-1.1 0-2 .9-2 2s.9 2 2 2c3.9 0 7.3-2.7 8.7-3.9h1.8c3.5 0 4.7 2.6 4.9 2.9.3.7 1.1 1.2 1.8 1.2.3 0 .5-.1.8-.2 1-.4 1.5-1.6 1-2.6-.1-.2-1.8-4-6.3-5.1l1.3-1.8c.6.1 1.2.2 1.8.2 2.1 0 3.7-.7 3.8-.8.9-.6 1.3-1.8.9-2.8zM21.8 12s-1.4.7-3.4.7c-.5 0-1-.1-1.6-.2l-.6-.1-.4.5-1.3 1.8-.9 1.2 1.5.3c4 .9 5.5 4.3 5.6 4.5.1.2.1.5 0 .8s-.3.4-.5.5c-.1.1-.3.1-.4.1-.4 0-.8-.2-.9-.6-.3-.6-1.8-3.5-5.8-3.5H11l-.3.3c-1.2 1.1-4.5 3.7-8 3.7-.6 0-1-.4-1-1s.4-1 1-1c2.9 0 5.8-2.2 6.8-3.2 1-1.2 3.5-4.8 3.6-5l.5-.7-.6-.6s-.1 0-.1-.1c-1.2-1.1-2.4-1.6-3.5-1.6-1.2 0-2 .6-2 .6-.5.2-.7.2-.9.2-.3 0-.5-.1-.7-.4-.4-.4-.3-1 .1-1.4.6-.5 1.9-1.1 3.3-1.1 1.7 0 3.3.7 4.9 2.1 1.3 1.2 2.8 1.8 4.4 1.8 1.3 0 2.2-.4 2.4-.5.1-.1.3-.1.4-.1.4 0 .7.2.9.6.3.6.1 1.1-.4 1.4z',
  add: 'M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z',
  allergies: 'M18.5 5.5C17.1 5.5 16 6.6 16 8s1.1 2.5 2.5 2.5S21 9.4 21 8s-1.1-2.5-2.5-2.5zm0 4c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5S20 7.2 20 8s-.7 1.5-1.5 1.5zm-7.5 3c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm-6-7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm13-3c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm-3-9c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm-6.5 2C7.1 5.5 6 6.6 6 8s1.1 2.5 2.5 2.5S11 9.4 11 8 9.9 5.5 8.5 5.5zm0 4C7.7 9.5 7 8.8 7 8s.7-1.5 1.5-1.5S10 7.2 10 8s-.7 1.5-1.5 1.5z',
  backspace: 'M21 11H6.8l3.6-3.6L9 6l-6 6 6 6 1.4-1.4L6.8 13H21v-2z',
  arrowleft: 'M15.7 16.6L11.1 12l4.6-4.6L14.3 6l-6 6 6 6 1.4-1.4z',
  arrowright: 'M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z',
  arrowdown: 'M7.4 8.3l4.6 4.6 4.6-4.6L18 9.7l-6 6-6-6 1.4-1.4z',
  bell: 'M19.3 16.3c-.2-.4-.6-.7-1-1.1-.3-.2-.6-.5-.7-.7-.3-.6-.1-2.2 0-3.2 0-.3.1-.6.1-.8.2-2.7-1.4-5.1-3.9-5.9v-.2c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v.2c-2.5.8-4.1 3.1-3.9 5.9 0 .2 0 .5.1.8.1 1 .3 2.6 0 3.2-.1.2-.4.5-.7.7-.4.4-.9.7-1 1.1-.1.2-.3 1 .1 1.4 1 1.1 3 1.7 5.3 1.9.1 1 .9 1.8 2 1.8s1.9-.8 2-1.9c2.2-.3 4.2-.9 5.2-1.8.3-.4.1-1.2 0-1.4zm-13.8.8v-.4c.1-.2.5-.5.8-.7.4-.3.8-.6 1-1 .4-.9.3-2.5.1-3.8 0-.3-.1-.6-.1-.8-.1-2.3 1.3-4.4 3.5-4.9.2 0 .4-.2.4-.5v-.6c0-.4.4-.8.8-.8s.8.4.8.8V5c0 .2.2.4.4.5 2.2.5 3.7 2.5 3.5 4.9 0 .2 0 .5-.1.8-.1 1.3-.3 2.9.1 3.8.2.4.6.7 1 1 .3.2.7.5.7.7 0 .1.1.3.1.4-2.4 1.9-10.9 2.2-13 0z',
  bloodglucose: 'M18.4 1.1H5.6c-1.4 0-2.5 1.1-2.5 2.5v12.9c0 1.4 1.1 2.5 2.5 2.5h3.1v4.2h6.6V19h3.1c1.4 0 2.5-1.1 2.5-2.5V3.6c0-1.4-1.1-2.5-2.5-2.5zM14.3 21H9.7v-2h4.6v2zm5.6-4.5c0 .8-.7 1.5-1.5 1.5H5.6c-.8 0-1.5-.7-1.5-1.5V3.6c0-.8.7-1.5 1.5-1.5h12.9c.8 0 1.5.7 1.5 1.5v12.9zm-10.2-.9c0 .4-.3.7-.7.7s-.7-.3-.7-.7.3-.7.7-.7.7.4.7.7zm6 0c0 .4-.3.7-.7.7s-.7-.3-.7-.7.3-.7.7-.7.7.4.7.7zm-10-2.1h12.7V3.7H5.7v9.8zm6.3-7s2.1 2.4 2.1 3.5c0 1.2-.9 2.1-2.1 2.1s-2.1-.9-2.1-2.1c0-1.1 2.1-3.5 2.1-3.5z',
  body: 'M12 8C9.8 8 8 6.2 8 4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0-7c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM7.5 23.6c-.3 0-.7-.1-1-.3-1-.6-1.3-1.8-.7-2.7l2.8-4.8c.1-.2.2-.4.1-.6l-.2-1.3c0-.5-.5-.9-1-.9H5c-1.1 0-2-.9-2-2s.9-2 2-2h14c1.1 0 2 .9 2 2s-.9 2-2 2h-2.5c-.5 0-.9.4-1 .9l-.2 1.3c0 .2 0 .4.1.6l2.8 4.8c.6 1 .2 2.2-.7 2.7-.5.3-1 .3-1.5.2-.5-.1-.9-.5-1.2-.9l-1.9-3.3c-.4-.6-1.4-.6-1.7 0l-1.9 3.3c-.3.5-.8.8-1.3.9-.2.1-.3.1-.5.1zM5 10c-.6 0-1 .4-1 1s.4 1 1 1h2.5c1 0 1.9.8 2 1.8l.2 1.3c.1.4 0 .9-.3 1.2l-2.8 4.8c-.3.5-.1 1.1.4 1.4.2.1.5.2.8.1.3-.1.5-.2.6-.5l1.9-3.3c.4-.6 1-1 1.7-1s1.4.4 1.7 1l1.9 3.3c.3.5.9.6 1.4.4.5-.3.6-.9.4-1.4l-2.8-4.8c-.2-.4-.3-.8-.3-1.2l.2-1.3c.1-1 1-1.8 2-1.8H19c.6 0 1-.4 1-1s-.4-1-1-1H5z',
  bp: 'M13.8 20.9c.2.2.2.5 0 .7-.9.8-1.5 1.3-1.5 1.3-.1.1-.2.1-.3.1-.1 0-.2 0-.3-.1-.4-.3-10-6.9-10-15 0-4.4 2.6-6.6 5-6.8 1.9-.1 4.2 1 5.3 3.7C13.1 2 15.4.9 17.4 1c2.4.2 4.9 2.2 4.9 6.3 0 1.2-.2 2.4-.7 3.8-.1.3-.4.4-.6.3-.3-.1-.4-.4-.3-.6.4-1.2.7-2.4.7-3.4 0-3.5-2-5.2-4-5.3-2-.2-4.3 1.3-4.8 4.8 0 .2-.2.4-.5.4s-.5-.2-.5-.4c-.6-3.6-2.8-5-4.8-4.8-2 .2-4.1 2-4.1 5.8 0 6.8 7.6 12.8 9.3 14 .2-.2.6-.6 1.1-1 .2-.2.5-.2.7 0zm8.2-5.4c0 3-2.5 5.5-5.5 5.5S11 18.5 11 15.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5zm-1 0c0-2.5-2-4.5-4.5-4.5S12 13 12 15.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5zM16.5 13c.3 0 .5-.2.5-.5s-.2-.5-.5-.5-.5.2-.5.5.2.5.5.5zm2 4c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zm-4 0c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zm2 1c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zm2.5-1.5c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zM13.5 15c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zm1-1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5-.5.2-.5.5.2.5.5.5zm3.5 1.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5c.2 0 .4.1.6.1l1.1-1.1c.2-.2.5-.2.7 0s.2.5 0 .7l-1.1 1.1c.1.3.2.5.2.7zm-1 0c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5.5-.2.5-.5z',
  cardiac: 'M14.5 17.6h-.05a.48.48 0 0 1-.43-.36l-1.65-5.76-1.92 3.85c-.08.16-.24.26-.42.28a.471.471 0 0 1-.45-.22l-1.61-2.41L6.9 14.4a.48.48 0 0 1-.4.2h-6c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h5.75l1.35-1.8c.1-.13.25-.2.41-.2.16 0 .31.09.4.22l1.52 2.28 2.11-4.23c.09-.18.29-.3.49-.27.21.02.38.16.43.36l1.65 5.76.92-1.85a.5.5 0 0 1 .45-.28h7.5c.28 0 .5.22.5.5s-.22.5-.5.5h-7.19l-1.36 2.72c-.07.18-.24.29-.43.29zm7.2-5.29c.87-1.85 1.3-3.53 1.3-5.14C23 2.94 20.31.81 17.81.61c-2.14-.17-4.67 1.05-5.81 4C10.88 1.71 8.43.52 6.33.67 3.68.88 1 3.15 1 7.76c0 1.46.32 2.98.95 4.52.1.25.4.38.65.27.26-.1.38-.4.27-.65C2.29 10.48 2 9.09 2 7.76c0-3.97 2.21-5.92 4.41-6.08 2.14-.16 4.54 1.31 5.1 4.98.03.24.24.42.49.42s.46-.18.49-.42c.57-3.72 3.04-5.21 5.24-5.04C19.86 1.77 22 3.56 22 7.17c0 1.46-.39 3-1.2 4.72a.504.504 0 0 0 .45.72.52.52 0 0 0 .45-.3zm-9.39 11.08c.16-.12 3.85-3 6.87-6.98.17-.22.12-.53-.1-.7a.498.498 0 0 0-.7.1c-2.47 3.25-5.48 5.81-6.4 6.57-1-.71-4.32-3.19-6.85-6.57a.498.498 0 0 0-.7-.1c-.22.17-.27.48-.1.7 3.11 4.14 7.22 6.89 7.39 7.01.08.06.18.08.28.08.11-.01.22-.04.31-.11z',
  calendarcheck: 'M18 2H6C3.8 2 2 3.8 2 6v12c0 2.2 1.8 4 4 4h12c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4zM3 6c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3v1H3V6zm18 12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V8h18v10zM7.6 14.1c-.2-.2-.2-.5 0-.7s.5-.2.7 0l2.4 2.4 4.9-4.9c.2-.2.5-.2.7 0s.2.5 0 .7L11 16.9c-.1.1-.2.1-.4.1s-.3 0-.4-.1l-2.6-2.8z',
  calendaralert: 'M18 2H6C3.8 2 2 3.8 2 6v12c0 2.2 1.8 4 4 4h12c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4zM3 6c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3v1H3V6zm18 12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V8h18v10zm-9.5-2.8v-4c0-.3.2-.5.5-.5s.5.2.5.5v4c0 .3-.2.5-.5.5s-.5-.3-.5-.5zm0 2.1c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5-.5-.2-.5-.5z',
  check: 'M12 .5C5.7.5.5 5.7.5 12S5.7 23.5 12 23.5 23.5 18.3 23.5 12 18.3.5 12 .5zm0 22C6.2 22.5 1.5 17.8 1.5 12S6.2 1.5 12 1.5 22.5 6.2 22.5 12 17.8 22.5 12 22.5zm6.7-15.1c.2.2.2.5 0 .7l-8.5 8.5c-.1.1-.2.1-.4.1s-.3 0-.4-.1l-4.2-4.2c-.2-.2-.2-.5 0-.7s.5-.2.7 0l3.9 3.9L18 7.4c.2-.2.5-.2.7 0z',
  chevrondown: 'M12 15.8c-.3 0-.5-.1-.7-.3L5.6 9.9c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4.9 4.9 5-4.9c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-5.7 5.7c-.1.1-.3.2-.6.2z',
  chevronright: 'M15.8 11.9c0 .3-.1.5-.3.7l-5.6 5.7c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l4.9-4.9-4.9-5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5.7 5.7c.1.2.2.4.2.6z',
  clear: 'M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z',
  completed: 'M14 10H2v2h12v-2zm0-4H2v2h12V6zM2 16h8v-2H2v2zm19.5-4.5L23 13l-6.99 7-4.51-4.5L13 14l3.01 3 5.49-5.5z',
  delete: 'M17.5 23h-11c-.3 0-.5-.2-.5-.5l-1-17c0-.1 0-.3.1-.4.1 0 .3-.1.4-.1h13c.1 0 .3.1.4.2.1.1.1.2.1.3l-1 17c0 .3-.2.5-.5.5zM7 22h10l1-16H6l1 16zm2-3.5v-11c0-.3-.2-.5-.5-.5s-.5.2-.5.5v11c0 .3.2.5.5.5s.5-.2.5-.5zm11-15c0-.3-.2-.5-.5-.5h-15c-.3 0-.5.2-.5.5s.2.5.5.5h15c.3 0 .5-.2.5-.5zm-6-2c0-.3-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5s.2.5.5.5h3c.3 0 .5-.2.5-.5zm2 17v-11c0-.3-.2-.5-.5-.5s-.5.2-.5.5v11c0 .3.2.5.5.5s.5-.2.5-.5zm-3.5 2v-13c0-.3-.2-.5-.5-.5s-.5.2-.5.5v13c0 .3.2.5.5.5s.5-.2.5-.5z',
  devices: 'M18.3 8.9c0-.4-.2-.8-.4-1v-.1c0-1-.9-2.2-1.7-2.8h-.1c-.1-.1-.3-.2-.3-.2V3.6c0-1.4-1.1-2.6-2.5-2.6h-3.1C8.8 1 7.7 2.2 7.7 3.6v1.3c-.1 0-.2.1-.3.1h-.1c-.8.6-1.6 1.8-1.6 2.8v8.4c0 1 .9 2.2 1.7 2.8h.1c.1.1.3.2.3.2v1.3c0 1.4 1.1 2.6 2.5 2.6h3.1c1.4 0 2.5-1.2 2.5-2.6v-1.2-.1c.1-.1.2-.2.3-.2h.1c.8-.5 1.7-1.7 1.7-2.8V16c.2-.3.4-.6.4-1v-2.5c0-.4-.1-.7-.4-1 .3-.3.4-.6.4-1V8.9zm-1 1.6c0 .2-.2.4-.4.4v1.2c.2 0 .4.2.4.4V15c0 .2-.2.4-.4.4v.8c0 .6-.6 1.6-1.2 1.9-.5.3-.8.5-.8 1.2v1.2c0 .9-.7 1.6-1.5 1.6h-3.1c-.8 0-1.5-.7-1.5-1.6v-1.2c0-.6-.3-.8-.8-1.2-.6-.4-1.2-1.3-1.2-1.9V7.8c0-.6.6-1.6 1.2-1.9.5-.3.8-.5.8-1.2V3.6c-.1-.9.6-1.6 1.4-1.6h3.1c.8 0 1.5.7 1.5 1.6v1.2c0 .6.3.8.8 1.2.6.4 1.2 1.3 1.2 1.9v.7c.2 0 .4.2.4.4v1.5zm-2.5-3.8h-6c-.8 0-1.4.6-1.4 1.4V16c0 .8.6 1.4 1.4 1.4h6.1c.7 0 1.4-.6 1.4-1.4V8.1c-.1-.8-.7-1.4-1.5-1.4zm.4 9.2c0 .2-.2.4-.4.4h-6c-.2 0-.4-.2-.4-.4V8.1c0-.2.2-.4.4-.4h6.1c.2 0 .4.2.4.4v7.8z',
  deselectedCircle : 'M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z',
  documents: 'M20.5 5.8v16c0 .3-.2.5-.5.5H5.8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h13.7V5.8c0-.3.2-.5.5-.5s.5.2.5.5zm-2.3 15H4c-.3 0-.5-.2-.5-.5v-16c0-.3.2-.5.5-.5h1c0-1.1.9-2 2-2s2 .9 2 2h9.2c.3 0 .5.2.5.5v16c0 .2-.2.5-.5.5zM6 3.8h2c0-.6-.4-1-1-1s-1 .4-1 1zm11.7 1H6v1c0 .6.4 1 1 1s1-.4 1-1c0-.3.2-.5.5-.5s.5.2.5.5c0 1.1-.9 2-2 2s-2-.9-2-2v-1h-.5v15h13.2v-15zm-2.2 10h-8c-.3 0-.5.2-.5.4s.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.4-.5-.4zm0 2h-8c-.3 0-.5.2-.5.4s.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.4-.5-.4zm0-4h-8c-.3 0-.5.2-.5.4s.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.4-.5-.4zm0-2h-8c-.3 0-.5.2-.5.4s.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.4-.5-.4zM7 9.2c0 .3.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-8c-.3.1-.5.3-.5.5z',
  edit: 'M18.1 7.8l-1.4-1.4c-.2-.2-.5-.2-.7 0l-1.4 1.4-7.1 7.1c-.1.1-.1.2-.1.2l-.7 2.1c-.1.2 0 .4.1.5s.2.1.4.1l2.3-.7c.1 0 .2-.1.2-.1l7.1-7.1 1.4-1.4c.1-.1.1-.2.1-.4s-.1-.2-.2-.3zm-8.8 8.1l-.7-.7L15 8.8l.7.7-6.4 6.4zm7-7l-.7-.7.7-.7.7.7-.7.7z',
  editprofile: 'M12.8 14.3l1-.7c1.2-1.1 2-2.8 2-4.8 0-3.3-2.3-6-5-6-2.8 0-5 2.7-5 6 0 2.1 1 4 2.4 5.1v1.9L2.8 18c-.3.1-.4.4-.3.7.1.2.3.3.5.3h.2l6-2.4v-2c.5.2 1.1.3 1.6.3.7-.1 1.4-.3 2-.6zm-2-10.4c2 0 3.6 1.7 4 4-1 .1-1.7-.3-2.3-1.5l-.5-.8-.4.8c-.4.7-1.7 1.4-2.8 1.4-.6 0-1.3-.1-1.8-.3h-.1c.6-2.1 2.1-3.6 3.9-3.6zm-4 4.9v-.4c.6.2 1.3.4 1.9.4 1.2 0 2.5-.5 3.2-1.3.7.9 1.5 1.4 2.5 1.4h.3c0 2.7-1.8 4.9-4 4.9-2.1 0-3.9-2.2-3.9-5zm14.7 2.1l-1.4-1.4c-.2-.2-.5-.2-.7 0L18 10.9 10.9 18c-.1.1-.1.1-.1.2l-.7 2.1c-.1.2 0 .4.1.5.1.1.2.1.4.1h.2l2.1-.7c.1 0 .1-.1.2-.1l7.1-7.1 1.4-1.4c.1-.1.1-.2.1-.4s-.1-.2-.2-.3zM12.7 19l-.7-.7 6.4-6.4.7.7-6.4 6.4zm7-7l-.7-.7.7-.7.7.7-.7.7z',
  error: 'M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z',
  eventnote: 'M19 4h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM5 6v2h14V6H5zm2 6h10v2H7v-2zm7 4H7v2h7v-2z',
  feed: 'M16.6 20.6c-.1 0-.2 0-.3-.1-.2-.1-5-3.4-5-7.5 0-2.3 1.4-3.5 2.7-3.6.9-.1 1.9.4 2.6 1.5.6-1.1 1.7-1.6 2.6-1.5 1.3.1 2.6 1.2 2.6 3.3 0 3.8-4.7 7.6-4.9 7.7-.1.1-.2.2-.3.2zm-2.4-10.2h-.1c-.9.1-1.7.9-1.7 2.6 0 3 3.2 5.7 4.2 6.4 1-.9 4.3-3.9 4.3-6.7 0-1.5-.8-2.2-1.7-2.3-.8-.1-1.8.6-2.1 2.1 0 .2-.2.4-.5.4-.2 0-.5-.2-.5-.4-.3-1.4-1.1-2.1-1.9-2.1zm5.5-5.5c0-.3-.2-.5-.5-.5H3.5c-.3 0-.5.3-.5.5s.2.5.5.5h15.8c.2 0 .4-.2.4-.5zM1.2 4.4c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zM9.3 15c0-.3-.2-.5-.5-.5H3.5c-.3 0-.5.2-.5.5s.2.5.5.5h5.3c.3 0 .5-.2.5-.5zm-8.1-.5c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zM12.6 20c0-.3-.2-.5-.5-.5H3.5c-.3 0-.5.2-.5.5s.2.5.5.5h8.6c.2 0 .5-.2.5-.5zm-11.4-.5c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zm9.1-9.5c0-.3-.2-.5-.5-.5H3.5c-.3 0-.5.2-.5.5s.2.5.5.5h6.3c.3 0 .5-.3.5-.5zm-9.1-.6c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5c0-.2-.2-.5-.5-.5z',
  feedback: 'M14.7 8.2c.1 0 .2.1.1.2l-.4.3c-.1.1-.2.3-.1.4l.1.5c0 .1-.1.2-.2.1l-.4-.2c-.1-.1-.3-.1-.4 0l-.4.2c-.1.1-.2 0-.2-.1l.1-.5c0-.1 0-.3-.1-.4l-.4-.3c-.1-.1-.1-.2.1-.2l.5-.1c.1 0 .3-.1.4-.3l.2-.4c.1-.1.2-.1.2 0l.2.4c.1.1.2.2.4.3l.3.1zm-4-.1c-.1 0-.3-.1-.4-.3l-.2-.4c-.1-.1-.2-.1-.2 0l-.2.4c-.1.2-.2.3-.4.3l-.5.1c-.1 0-.2.1-.1.2l.4.3c.1.1.2.3.2.4l-.1.5c0 .1.1.2.2.1l.4-.2c.1-.1.3-.1.4 0l.4.2c.1.1.2 0 .2-.1l-.1-.5c0-.1 0-.3.1-.4l.4-.3c.1-.1.1-.2-.1-.2l-.4-.1zm7.1 1.5l-.1-.5c0-.1 0-.3.1-.4l.4-.3c.1-.1.1-.2-.1-.2l-.5-.1c-.1 0-.3-.1-.4-.3l-.2-.4c-.1-.1-.2-.1-.2 0l-.2.4c-.1.1-.2.2-.4.3l-.5.1c-.1 0-.2.1-.1.2l.4.3c.1.1.2.3.1.4l-.1.5c0 .1.1.2.2.1l.4-.2c.1-.1.3-.1.4 0l.4.2c.4.1.4.1.4-.1zm2.5 8.5c.1.2.1.4-.1.6-.1.1-.2.1-.4.1-.1 0-.2 0-.2-.1l-4.4-2.4c-5.1 1.1-9.7-2.8-9.7-7.8 0-4.4 3.6-8 8-8s8 3.6 8 8c0 2.5-1.1 4.8-3.1 6.3l1.9 3.3zm-1.7-1l-1.2-2.2c-.1-.2-.1-.5.2-.7 1.9-1.3 3-3.5 3-5.7 0-3.9-3.2-7-7-7-3.9 0-7 3.2-7 7 0 4.4 4.1 7.9 8.7 6.8h.1c.1 0 .2 0 .2.1l3 1.7zm-3.4.8c-.1.1-2.1 2.3-5.2 2.3-.6 0-1.1-.1-1.7-.2h-.4l-3 1.7 1.2-2.2c.1-.2.1-.5-.1-.7-1.3-.9-2.3-2.2-2.7-3.7-.2-.7-.3-1.3-.3-2 0-.5.1-2.2 1.1-3.8.1-.2.1-.5-.2-.7-.2-.1-.5 0-.7.2C2.1 10.6 2 12.4 2 13.2c0 .8.1 1.6.3 2.3.5 1.6 1.4 3 2.7 4l-1.8 3.3c-.1.2-.1.4.1.6.1.1.2.1.4.1.1 0 .2 0 .2-.1L8.3 21c.6.1 1.1.2 1.7.2 3.5 0 5.7-2.4 5.9-2.6.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0z',
  filter: 'M3 6v2h18V6H3zm7 12h4v-2h-4v2zm8-5H6v-2h12v2z',
  group: 'M8 10H4.8c-.3 0-.5.2-.5.5v1.9c0 .8.3 1.2.6 1.3V16c0 .3.2.5.5.5h1.9c.3 0 .5-.2.5-.5v-2.4c.3-.1.6-.5.6-1.3v-1.9c0-.2-.2-.4-.4-.4zm-.5 2.3c0 .4-.1.5-.1.5h-.1c-.3 0-.5.2-.5.5v2.3h-.9v-2.3c0-.3-.2-.5-.5-.5h-.1s-.1-.1-.1-.5v-1.4h2.2v1.4zM19.2 10H16c-.3 0-.5.2-.5.5v1.9c0 .8.3 1.2.6 1.3V16c0 .3.2.5.5.5h1.9c.3 0 .5-.2.5-.5v-2.4c.3-.1.6-.5.6-1.3v-1.9c0-.2-.2-.4-.4-.4zm-.5 2.3c0 .4-.1.5-.1.5h-.1c-.3 0-.5.2-.5.5v2.3h-.9v-2.3c0-.3-.2-.5-.5-.5h-.1s-.1-.1-.1-.5v-1.4h2.2v1.4zM14.2 10H9.8c-.3 0-.5.2-.5.5v3.1c0 .8.5 1.4 1.2 1.6v3.3c0 .3.2.5.5.5h1.9c.3 0 .5-.2.5-.5v-3.3c.7-.2 1.2-.9 1.2-1.6v-3.1c0-.3-.2-.5-.4-.5zm-.5 3.6c0 .4-.3.8-.8.8-.3 0-.5.2-.5.5v3.3h-.9v-3.3c0-.3-.2-.5-.5-.5-.4 0-.8-.3-.8-.8V11h3.4v2.6zM17.6 9.7c.9 0 1.7-.8 1.7-1.7s-.8-1.7-1.7-1.7-1.7.7-1.7 1.7.8 1.7 1.7 1.7zm0-2.5c.4 0 .8.3.8.8 0 .4-.3.8-.8.8-.4 0-.8-.3-.8-.8s.4-.8.8-.8zM6.4 9.7c.9 0 1.7-.8 1.7-1.7s-.8-1.8-1.7-1.8S4.7 7 4.7 8s.7 1.7 1.7 1.7zm0-2.5c.4 0 .8.3.8.8 0 .4-.3.8-.8.8s-.8-.4-.8-.8c0-.5.4-.8.8-.8zM12 9.7c1.3 0 2.3-1 2.3-2.3S13.3 5 12 5 9.7 6 9.7 7.3s1 2.4 2.3 2.4zm0-3.8c.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4.6-1.4 1.4-1.4z',
  health: 'M11.7 13.4c.1.1.2.1.3.1.1 0 .2 0 .3-.1.1-.1 2.5-2 2.5-4 0-1.2-.8-1.8-1.5-1.9-.4 0-1 .1-1.3.5-.4-.4-.8-.6-1.3-.5-.7.1-1.5.7-1.5 2 0 2.2 2.4 3.8 2.5 3.9zm-.9-4.9c.3 0 .6.2.7.8 0 .2.2.4.5.4.2 0 .5-.2.5-.4.1-.6.4-.8.7-.8.3 0 .6.3.6.9 0 1.1-1.2 2.3-1.9 3-.7-.6-1.8-1.7-1.8-2.8.1-.7.4-1.1.7-1.1zm5.8 8.5c0 .3-.2.5-.5.5H7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H16c.3 0 .6.2.6.5zm0-2c0 .3-.2.5-.5.5H7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H16c.3 0 .6.2.6.5zm0 4c0 .3-.2.5-.5.5H7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H16c.3 0 .6.2.6.5zM19 2h-5c0-1.1-.9-2-2-2s-2 .9-2 2H5C3.6 2 2.5 3.1 2.5 4.5v16C2.5 21.9 3.6 23 5 23h14c1.4 0 2.5-1.1 2.5-2.5v-16C21.5 3.1 20.4 2 19 2zM8.9 3s.1 0 0 0H10c.1 0 .3 0 .4-.1.1 0 .2-.1.3-.2.2-.2.3-.4.3-.7 0-.6.4-1 1-1s1 .4 1 1c0 .3.1.5.3.7.1.1.2.1.3.2.1.1.3.1.4.1h1.1c.5 0 .9.5.9 1 0 .6-.4 1-1 1H9c-.6 0-1-.4-1-1 0-.5.4-.9.9-1zm11.6 17.5c0 .8-.7 1.5-1.5 1.5H5c-.8 0-1.5-.7-1.5-1.5v-16C3.5 3.7 4.2 3 5 3h2.3c-.2.3-.3.6-.3 1 0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2 0-.4-.1-.7-.3-1H19c.8 0 1.5.7 1.5 1.5v16zM11.5 2c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5-.5-.2-.5-.5z',
  healthprograms: 'M5.1 22.4H5c-2-.5-3.5-2.3-3.5-4.4V6c0-2.5 2-4.5 4.5-4.5h11c.2 0 .4.1.5.4.1.2 0 .4-.2.6-4.1 2.8-9.6 7.5-9 9.2.2.5 1.1.8 2.8.8 1.4 0 3.4.1 3.7 1.4.5 1.6-2.2 4-8.8 8.1-.2.1-.4.3-.6.3-.1.1-.2.1-.3.1zM6 2.5C4.1 2.5 2.5 4.1 2.5 6v12c0 1.6 1.1 3 2.6 3.4.1-.1.2-.1.4-.2 7.9-4.8 8.5-6.5 8.3-7-.1-.3-.9-.7-2.8-.7-2.2 0-3.4-.5-3.7-1.5-.8-2.6 5.2-7.3 8.1-9.5H6zm12 20h-4c-.2 0-.4-.1-.5-.3-.1-.2 0-.4.1-.5 1.9-1.9 6.7-7.5 5.7-9.9-.3-.8-1.5-1.3-3.3-1.3-2.5 0-3.9-.5-4.2-1.6-.7-2.3 4.5-5.8 6.8-7.3.1-.1.2-.1.4-.1 2 .6 3.5 2.4 3.5 4.5v12c0 2.5-2 4.5-4.5 4.5zm-2.8-1H18c1.9 0 3.5-1.6 3.5-3.5V6c0-1.6-1.1-3-2.6-3.4-3.4 2.1-6.5 4.9-6.2 6 .1.3.5.9 3.3.9 2.3 0 3.8.6 4.3 1.9 1.2 2.9-3.2 8-5.1 10.1z',
  height: 'M15.5 9.6c1.5 0 2.7-1.2 2.7-2.7S17 4.2 15.5 4.2s-2.7 1.2-2.7 2.7 1.2 2.7 2.7 2.7zm0-4.4c1 0 1.7.8 1.7 1.7 0 1-.8 1.7-1.7 1.7s-1.7-.8-1.7-1.7c0-.9.7-1.7 1.7-1.7zm-4 4.7v.5c0 3.1 1.3 4.5 2.2 5.1v4.4h3.5v-4.4c.9-.6 2.2-2 2.2-5.1v-.5h-7.9zm5 4.8l-.3.1v3.9h-1.5v-3.9l-.3-.1c-1.2-.6-1.8-2-2-3.8h6c0 1.9-.7 3.2-1.9 3.8zM5 2.5v1h1.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h3.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h1.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h3.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h1.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h3.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h1.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h3.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h1.5c.3 0 .5.2.5.5s-.2.5-.5.5H5v1h5.5c.3 0 .5.2.5.5s-.2.5-.5.5h-6c-.3 0-.5-.2-.5-.5V2c0-.3.2-.5.5-.5h6c.3 0 .5.2.5.5s-.2.5-.5.5H5z',
  hipaaauth: 'M12 3l7.5 3v7.5c0 4.1-7.5 7.5-7.5 7.5s-7.5-3.4-7.5-7.5V6L12 3m0-1l-.4.1-7.5 3-.6.3v8.1c0 2.1 1.4 4.1 4.2 6.1 1.9 1.4 3.8 2.3 3.9 2.3l.4.2.4-.2c.8-.4 8.1-3.8 8.1-8.4V5.4l-.6-.3-7.5-3L12 2zm3 8V9c0-1.7-1.3-3-3-3S9 7.3 9 9v1H8v7h8v-7h-1zm-1 3.7h-1.5v1.5h-1v-1.5H10v-1h1.5v-1.5h1v1.5H14v1zm0-3.7h-4V9c0-1.1.9-2 2-2s2 .9 2 2v1z',
  history: 'M11.9 7v5h3c.3 0 .5.2.5.5s-.2.5-.5.5h-3.5c-.3 0-.5-.2-.5-.5V7c0-.3.2-.5.5-.5s.5.2.5.5zm0-4c-4.7 0-8.6 3.7-9 8.3l-1.2-1.2c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l2 2 .1.1h.4s.1 0 .1-.1h.1l2-2c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-1 1.2C4.3 7.2 7.7 4 11.9 4c4.4 0 8 3.6 8 8s-3.6 8-8 8c-3.1 0-6-1.8-7.3-4.7 0-.2-.3-.3-.6-.2-.3.1-.4.4-.2.7C5.3 19 8.5 21 12 21c5 0 9-4 9-9-.1-5-4.1-9-9.1-9z',
  immunizations: 'M22.5 8.7l-7.2-7.2c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l1.5 1.5-1.7 1.7-.3-.3-1.2-1.2c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l.8.8-1.5 1.5-1.7 1.8L5 13.5c-1.4 1.4-1.5 3.5-.4 5.1l-3.2 3.2c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1l3.2-3.2c.7.6 1.6.9 2.4.9 1 0 2-.4 2.8-1.1l8.1-8.1.8.8c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7l-1.2-1.2-.3-.3L20.4 8l1.5 1.5c.1.1.2.1.4.1s.3 0 .4-.1c0-.3 0-.6-.2-.8zM9.8 18.3c-1.1 1.1-3 1.1-4.1 0-1.1-1.1-1.1-3 0-4.1l1.1-1.1 1.4 1.4c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7l-1.4-1.4.9-.9.8.8c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7l-.8-.8 1.1-1.1 1.4 1.4c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7L11.2 9l.8-1 .8.8c.1.2.2.2.4.2s.3 0 .4-.1c.2-.2.2-.5 0-.7l-.8-.8 1.1-1.1 4.1 4.1-8.2 7.9z',
  loader: 'M12 23.5C5.7 23.5.5 18.3.5 12S5.7.5 12 .5 23.5 5.7 23.5 12c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-5.8-4.7-10.5-10.5-10.5S1.5 6.2 1.5 12 6.2 22.5 12 22.5c.3 0 .5.2.5.5s-.2.5-.5.5z',
  logo: 'M10.7 8.3c0 .1 0 .2.1.3 0 0 0 .1.1.2 0 .1.1.1.1.2l.1.1s.1 0 .1.1c.1 0 .1 0 .2.1H11.7c.5 0 .9-.3 1.1-.7l2.6-6v-.1c.2-.6 0-1.3-.6-1.5-.6-.2-1.3.1-1.5.6L11.8 5l-1.3-3.4C10.3 1 9.6.7 9 .9c-.5.4-.8 1-.5 1.6l2.2 5.8zM13.2 15.5c0-.1 0-.1 0 0 0-.1-.1-.2-.1-.2 0-.1-.1-.1-.1-.2l-.1-.1s-.1 0-.1-.1c-.1 0-.1 0-.2-.1H12.3h-.1-.1c-.1 0-.1 0-.2.1h-.1s-.1 0-.1.1h-.1s-.1 0-.1.1l-.1.1-.1.1c0 .1-.1.1-.1.2l-2.6 5.9v.1c-.2.6.1 1.3.7 1.5s1.3-.1 1.5-.7l1.5-3.4 1.3 3.4c.2.4.6.7 1.1.7.2 0 .3 0 .4-.1.6-.2.9-.9.7-1.5l-2.6-5.9zM2.8 7.8l5.9 2.6H9.8l.2-.1.1-.1s0-.1.1-.1c.2-.3.3-.7.2-1.1L7.8 2.8c-.2-.5-.8-.8-1.4-.6s-.9.9-.7 1.5l1.4 3.5-3.4-1.5c-.6-.2-1.3 0-1.5.6-.2.6 0 1.3.6 1.5zM2.5 15.5l5.9-2.3c.1 0 .2-.1.3-.1.1-.1.2-.1.2-.1s.1-.1.1-.2v-.1c.3-.6 0-1.3-.6-1.5l-6-2.6c-.4-.4-1-.1-1.3.4-.3.6 0 1.3.6 1.5L5.1 12l-3.4 1.3c-.4.2-.7.7-.7 1.2 0 .6.5 1.1 1.1 1.1.2 0 .3 0 .4-.1zM22.3 13.4l-3.4-1.5 3.4-1.3c.6-.2.9-.9.7-1.5-.2-.6-.9-.9-1.5-.7l-5.8 2.3c-.1 0-.1 0-.2.1 0 0-.1 0-.2.1-.1 0-.1.1-.2.1l-.1.1s0 .1-.1.1c0 .1 0 .1-.1.2v.4c0 .4.3.7.7.9l6 2.6c.1.1.3.1.5.1.5 0 .9-.3 1-.7.2-.3-.1-1-.7-1.3zM21.2 16.2l-5.7-2.5s-.1 0-.1-.1H15.1h-.2c-.2 0-.3 0-.5.1-.6.2-.9.9-.7 1.5l2.4 6.1c.2.4.6.7 1.1.7.2 0 .3 0 .4-.1.6-.2.9-.9.7-1.5l-1.4-3.5 3.3 1.5c.6.2 1.3 0 1.5-.6.3-.7.1-1.4-.5-1.6zM10.4 14.4c-.2-.6-.9-.9-1.5-.7l-6.1 2.4c-.5.3-.8.9-.6 1.5.2.6.9.9 1.5.7l3.5-1.4-1.5 3.3c-.1.1-.1.3-.1.5 0 .6.5 1.1 1.1 1.1.5 0 .9-.3 1.1-.7l2.6-5.9c.1-.2.1-.5 0-.8zM13.5 9.1c0 .6.5 1.1 1.1 1.1.1 0 .3 0 .4-.1l6.1-2.4c.6-.1.9-.7.7-1.3s-.9-.9-1.5-.7l-3.5 1.4 1.5-3.3c.2-.6 0-1.3-.6-1.5s-1.3 0-1.5.6l-2.6 5.9s-.1.2-.1.3z',
  healthRecords: 'M18 3.2h-4.1c-.2-.9-1-1.5-1.9-1.5s-1.7.6-1.9 1.5H6c-1.4 0-2.5 1.1-2.5 2.5v14c0 1.4 1.1 2.5 2.5 2.5h12c1.4 0 2.5-1.1 2.5-2.5v-14c0-1.3-1.1-2.5-2.5-2.5zm-1.5 3.6c.3 0 .5.2.5.4v11c0 .3-.2.5-.5.5h-9c-.3 0-.5-.2-.5-.5v-11c0-.2.2-.4.5-.4h9zM11 4.2v-.4c0-.6.4-1 1-1s1 .4 1 1v.5h2.9c0 .1-.1.2-.2.3v.1l-.3.3c-.1.1-.2.1-.4.2h-.1c-.1 0-.3.1-.4.1h-5c-.1 0-.3 0-.4-.1H9c-.1-.1-.3-.1-.4-.2l-.3-.3-.1-.1c0-.1-.1-.2-.1-.3H11zm8.5 15.6c0 .8-.7 1.5-1.5 1.5H6c-.8 0-1.5-.7-1.5-1.5v-14c0-.8.7-1.5 1.5-1.5h1.1c0 .1.1.3.1.4 0 0 0 .1.1.1 0 .1.1.2.1.3 0 .1.1.1.1.1l.2.2.1.1c.1.1.1.2.2.3h-.5c-.8 0-1.5.6-1.5 1.4v11c0 .8.7 1.5 1.5 1.5h9c.8 0 1.5-.7 1.5-1.5v-11c0-.8-.7-1.5-1.5-1.5H16l.2-.2.1-.1.2-.2.1-.1c.1-.1.1-.2.1-.3 0 0 0-.1.1-.1.1-.1.1-.3.1-.4h1c.8 0 1.5.7 1.5 1.5v14zM8 17.2c0-.3.2-.5.5-.5h7c.3 0 .5.2.5.5s-.2.5-.5.5h-7c-.3.1-.5-.2-.5-.5zm0-2c0-.3.2-.5.5-.5h7c.3 0 .5.2.5.5s-.2.5-.5.5h-7c-.3.1-.5-.2-.5-.5zm0-2c0-.3.2-.5.5-.5h7c.3 0 .5.2.5.5s-.2.5-.5.5h-7c-.3.1-.5-.2-.5-.5zm3.6-1.1c.1.1.2.1.4.1s.3 0 .4-.1l1.5-1.5c.4-.4.5-1 .3-1.5s-.7-.9-1.3-.9c-.3 0-.6.1-.9.3-.2-.2-.5-.3-.9-.3-.6 0-1.1.3-1.3.9-.2.5-.1 1.1.3 1.5l1.5 1.5zm-.8-2.6c0-.1.1-.2.4-.2.2 0 .4.2.4.4 0 .3.2.5.5.5s.5-.2.5-.5c0-.2.2-.4.4-.4s.3.2.4.2c0 .1.1.3-.1.4L12 11l-1.1-1.1c-.2-.2-.1-.4-.1-.4z',
  marketplace: 'M5 12.5H4v8.2h11v-8.2H5zm9 7.2H5v-6.2h9v6.2zM23 9c0-.1-1.3-7.5-3.5-7.5h-15C2.3 1.5 1 8.9 1 9c0 .8.4 1.5 1 1.9v11.6h20V10.9c.6-.4 1-1.1 1-1.9zm-2 12.5H3V11.4c.2.1.4.1.7.1.6 0 1.2-.2 1.7-.5.5.3 1 .5 1.7.5s1.2-.2 1.7-.5c.5.3 1 .5 1.7.5.6 0 1.2-.2 1.7-.5.5.3 1 .5 1.7.5s1.2-.2 1.7-.5c.5.3 1 .5 1.7.5s1.2-.2 1.7-.5c.5.3 1 .5 1.7.5.2 0 .5 0 .7-.1v10.1zm.9-12c-.1.1-.1.3-.2.4l-.3.3c-.1.1-.3.1-.4.2h-.1c-.1 0-.3.1-.5.1-.9 0-1.7-.7-1.7-1.5 0 .8-.7 1.5-1.7 1.5-.9 0-1.7-.7-1.7-1.5 0 .8-.7 1.5-1.7 1.5S12 9.8 12 9c0 .8-.7 1.5-1.7 1.5-.9 0-1.7-.7-1.7-1.5 0 .8-.7 1.5-1.7 1.5-.9 0-1.7-.7-1.7-1.5 0 .8-.7 1.5-1.7 1.5-.2 0-.3 0-.5-.1h-.1c-.1 0-.3-.1-.4-.2.1-.1-.1-.2-.2-.3-.1-.1-.1-.3-.2-.4C2 9.3 2 9.2 2 9c0-.8 1.6-6.5 2.5-6.5h15c.9 0 2.5 5.7 2.5 6.5 0 .2 0 .3-.1.5zm-5.9 3h4v8h-4v-8zM20 7c0 .3-.2.5-.5.5h-15c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h15c.3 0 .5.2.5.5z',
  measure: 'M9.8 10.4c1.8 0 3.7-.6 3.7-1.8 0-2.4-7.3-2.4-7.3 0-.1 1.2 1.8 1.8 3.6 1.8zm0-2.7c1.7 0 2.7.6 2.7.8s-.9.8-2.7.8-2.7-.5-2.7-.7 1-.9 2.7-.9zm14.1 4.6c0-.1-.1-.1-.1-.1h-.1l-.1-.1h-4.1V8.6c0-2.5-4.3-4.4-9.8-4.4S0 6.1 0 8.6v6.8c0 2.5 4.2 4.4 9.7 4.4h13.8c.3 0 .5-.2.5-.5v-6.8c0-.1 0-.1-.1-.2zm-7.8-.3c1.1-.4 1.9-.9 2.5-1.4V12h-2.5zM9.8 5.2c5.2 0 8.8 1.8 8.8 3.4S15 12 9.8 12C4.6 12 1 10.2 1 8.6s3.6-3.4 8.8-3.4zM1 15.4v-4.9c.3.3.8.6 1.3.9v2.9c0 .3.2.5.5.5s.5-.2.5-.5v-2.5c.5.2 1 .3 1.5.5v2c0 .3.2.5.5.5s.5-.2.5-.5v-1.7l1.8.3v2.7c0 .1.1.3.1.4.1.1.2.1.4.1.3 0 .5-.2.5-.5V13h1.5v2c0 .3.2.5.5.5s.5-.2.5-.5v-2h1.8v2.6c0 .3.2.5.5.5s.5-.2.5-.5V13h1.5v1.7c0 .3.2.5.5.5s.5-.2.5-.5V13h1.8v2.6c0 .3.2.5.5.5s.5-.2.5-.5V13h1.6v5.8H9.7c-5.2 0-8.7-1.7-8.7-3.4zm22 3.4h-1.2V13H23v5.8z',
  medications: 'M2.6 12.8c-.6-1-.9-2.1-.9-3.3C1.7 5.9 4.6 3 8.2 3c3 0 5.5 2 6.3 4.8l-1 .2c-.6-2.4-2.8-4.1-5.3-4.1-1.5 0-2.8.6-3.8 1.5l4.4 4.4-1 .4-4-4c-.7.9-1.1 2-1.1 3.2 0 1 .3 1.9.7 2.7-.3.2-.5.4-.8.7zm16.8 3.7l-7.9 3-3.3 1.2c-.5.2-1.1.3-1.6.3-.6 0-1.3-.1-1.8-.4-1.1-.5-1.9-1.4-2.4-2.5-.4-1.1-.4-2.3.1-3.4s1.4-1.9 2.5-2.3l3.3-1.2 7.9-3c1.1-.4 2.3-.4 3.4.1s1.9 1.4 2.4 2.5c.9 2.2-.3 4.8-2.6 5.7zm-8.3 2.1c1.8-.7 2.7-2.7 2-4.5-.7-1.8-2.7-2.7-4.5-2l-3.3 1.2c-.9.3-1.6 1-1.9 1.8s-.4 1.8-.1 2.7 1 1.6 1.8 1.9c.9.4 1.9.5 2.7.1l3.3-1.2zm10-7.5c-.3-.9-1-1.6-1.8-1.9-.5-.2-1-.3-1.4-.3-.4 0-.8.1-1.2.2l-5.3 2c1.3.4 2.3 1.3 2.8 2.7.4 1.1.4 2.3-.1 3.4-.1.2-.2.3-.3.4l5.3-2c1.8-.7 2.7-2.7 2-4.5zm-8.4 5.3c-.1-.3-.4-.4-.6-.3l-5 1.9c-.4.1-.8.1-1.1 0-.3-.1-.5 0-.7.3-.1.3 0 .5.3.7.3.1.7.2 1 .2.3 0 .6-.1.9-.2l4.9-1.9c.3-.2.4-.5.3-.7z',
  menu: 'M21.5 8.5h-19c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h19c.3 0 .5.2.5.5s-.2.5-.5.5zM22 12c0-.3-.2-.5-.5-.5h-19c-.3 0-.5.2-.5.5s.2.5.5.5h19c.3 0 .5-.2.5-.5zm0 4c0-.3-.2-.5-.5-.5h-19c-.3 0-.5.2-.5.5s.2.5.5.5h19c.3 0 .5-.2.5-.5z',
  more: 'M6.4 13.7c.9 0 1.7-.8 1.7-1.7s-.8-1.7-1.7-1.7-1.7.8-1.7 1.7.7 1.7 1.7 1.7zm0-2.5c.4 0 .8.3.8.8s-.3.8-.8.8-.8-.4-.8-.8.4-.8.8-.8zM12 13.7c.9 0 1.7-.8 1.7-1.7s-.8-1.7-1.7-1.7-1.7.8-1.7 1.7.8 1.7 1.7 1.7zm0-2.5c.4 0 .8.3.8.8s-.3.8-.8.8-.8-.3-.8-.8.4-.8.8-.8zM17.6 13.7c.9 0 1.7-.8 1.7-1.7s-.8-1.7-1.7-1.7-1.7.8-1.7 1.7.8 1.7 1.7 1.7zm0-2.5c.4 0 .8.3.8.8s-.3.8-.8.8c-.4 0-.8-.3-.8-.8s.4-.8.8-.8z',
  morevertical: 'M10.3 6.4c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7-1.7.7-1.7 1.7zm2.5 0c0 .4-.3.8-.8.8s-.8-.3-.8-.8.4-.8.8-.8.8.4.8.8zM10.3 12c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7-1.7.8-1.7 1.7zm2.5 0c0 .4-.3.8-.8.8s-.8-.3-.8-.8.3-.8.8-.8.8.4.8.8zm-2.5 5.6c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7-1.7.8-1.7 1.7zm2.5 0c0 .4-.3.8-.8.8s-.8-.3-.8-.8c0-.4.3-.8.8-.8s.8.4.8.8z',
  nutrition: 'M21.6 13.2c0 3.8-3.5 9.7-8.1 9.7-.3 0-.6-.1-.9-.1-.5-.1-.9-.1-1.3 0-.3.1-.6.1-.9.1-4.6 0-8.1-5.9-8.1-9.7 0-3.1 2.2-5.8 5.4-6.8.3-.1.6 0 .7.3s-.1.5-.3.6c-2.8.9-4.7 3.2-4.7 5.9 0 3.4 3.2 8.7 7.1 8.7.2 0 .4-.1.7-.1.5-.1 1.3-.1 1.8 0 .2.1.5.1.7.1 3.9 0 7.1-5.2 7.1-8.7 0-1.6-.7-3.1-1.9-4.3-1.3-1.3-3.2-2-5.1-1.9-.4 0-1.5.1-1.5.1-.7 0-2.9-.1-4.3-1.5-1.5-1.5-1.5-3.9-1.5-4 0-.3.2-.5.5-.5.1 0 2.5 0 4 1.5.5.5.8 1.1 1 1.6.2-.3.4-.7.7-1 .7-.7 1.7-1.1 2.8-1.1.3 0 .5.2.5.5s-.2.5-.5.5c-.9 0-1.6.3-2.1.8-.6.6-.8 1.5-.9 2.1.4 0 .8-.1 1-.1 2.2-.1 4.3.7 5.9 2.2 1.4 1.4 2.2 3.2 2.2 5.1zM11.5 6.1c-.1-.7-.3-1.9-1.1-2.7-.8-.8-2-1.1-2.7-1.1.1.7.3 1.9 1.1 2.7.8.7 2 1 2.7 1.1zm3.4 1.5c-.3-.1-.5.1-.6.4 0 .3.1.5.4.6 1.7.3 3.2 1.3 3.9 2.7.1.2.3.3.4.3.1 0 .2 0 .2-.1.2-.1.3-.4.2-.7-.8-1.7-2.5-2.9-4.5-3.2zm4.2 5.1c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5z',
  observations: 'M21.1 13.9c0-1.3-1-2.3-2.3-2.3s-2.3 1-2.3 2.3c0 1 .7 1.9 1.6 2.2-.6 2.6-2.1 4.5-3.9 4.5-2.2 0-3.9-2.7-4.1-6.2 1.5-.1 3-.8 4.2-1.9 2.4-2.4 2.6-6.2.6-8.8 0-.1.1-.2.1-.3 0-.5-.4-1-1-1s-1 .4-1 1c0 .5.4 1 1 1h.1c1.7 2.2 1.5 5.4-.5 7.4-2.2 2.2-5.8 2.2-8 0-2-2-2.2-5.2-.5-7.4h.1c.5 0 1-.4 1-1 0-.5-.4-1-1-1s-1 .4-1 1c0 .1 0 .2.1.3-2 2.6-1.8 6.4.6 8.8 1.2 1.2 2.7 1.8 4.2 1.9.2 4 2.4 7.2 5.1 7.2 2.3 0 4.2-2.3 4.9-5.3 1.1-.2 2-1.2 2-2.4zm-2.3 1.4c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3-.6 1.3-1.3 1.3zm.9-1.4c0 .5-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .5 1 1z',
  offline: 'M19.3 18.4L7.8 6.9 5.5 4.6c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l2.3 2.3c-.9 1-1.4 2.2-1.6 3.4-.9 0-1.9.2-2.6.8-.9.7-1.4 1.8-1.4 2.9 0 1.1.3 2 1 2.6 1.1 1.1 2.8 1.1 3 1.1h12.6l.8.8c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7l-.4-.1zm-13.8-.8s-1.4 0-2.3-.9c-.5-.5-.7-1.1-.7-1.9 0-.8.4-1.6 1-2.1.5-.4 1.1-.6 1.7-.6.2 0 .4 0 .6.1l.6.1v-.6c.1-1.3.6-2.5 1.4-3.4l9.3 9.3H5.5zm17-3.9c0 1.5-.5 2.7-1.5 3.5-.1.1-.2.1-.3.1-.1 0-.3-.1-.4-.2-.2-.2-.2-.5 0-.7.8-.7 1.2-1.6 1.2-2.8 0-1.1-.4-2-1.2-2.8-.8-.7-1.8-1.1-2.8-1.1h-.3l-.2-.1c-1.2-2.5-4.2-3.8-6.9-2.9-.3.1-.5 0-.6-.3-.1-.3 0-.5.3-.6.7-.2 1.4-.4 2.2-.4 2.4 0 4.7 1.4 5.8 3.5 1.2 0 2.3.5 3.2 1.3 1 1 1.5 2.2 1.5 3.5z',
  peakflow: 'M17.5 14h-16c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h16C20 13 22 11 22 8.5S20 4 17.5 4 13 6 13 8.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-3 2.5-5.5 5.5-5.5S23 5.5 23 8.5 20.5 14 17.5 14zM11 9c0-1.7-1.3-3-3-3S5 7.3 5 9c0 .3.2.5.5.5S6 9.3 6 9c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2H2.5c-.3 0-.5.2-.5.5s.2.5.5.5H8c1.7 0 3-1.3 3-3zm9 9c0-1.7-1.3-3-3-3H3.5c-.3 0-.5.2-.5.5s.2.5.5.5H17c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2c0-.3-.2-.5-.5-.5s-.5.2-.5.5c0 1.7 1.3 3 3 3s3-1.3 3-3z',
  preferences: 'M12 9.2c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8zm0 4.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8zm8.4-.2c-.7-.3-1.1-.9-1.1-1.6 0-.7.5-1.4 1.1-1.6.2-.1.4-.3.3-.6-.2-.8-.5-1.6-1-2.4-.1-.2-.4-.3-.6-.2-.7.3-1.5.2-2-.3s-.7-1.3-.3-2c.1-.2 0-.5-.2-.6-.7-.4-1.5-.8-2.4-1-.2-.1-.5.1-.6.3-.3.7-.9 1.1-1.6 1.1-.7 0-1.4-.5-1.6-1.1-.1-.2-.4-.4-.6-.3-.8.2-1.6.5-2.4 1-.2.1-.3.4-.2.6.3.7.2 1.5-.3 2s-1.3.7-2 .3c-.2-.1-.5 0-.6.2-.4.7-.8 1.5-1 2.4-.1.2.1.5.3.6.7.3 1.1.9 1.1 1.6 0 .7-.5 1.4-1.1 1.6-.2.1-.4.3-.3.6.2.8.5 1.6 1 2.4.1.2.4.3.6.2.7-.3 1.5-.2 2 .3s.7 1.3.3 2c-.1.2 0 .5.2.6.7.4 1.5.8 2.4 1 .2.1.5-.1.6-.3.3-.7.9-1.1 1.6-1.1.7 0 1.4.5 1.6 1.1.1.2.3.3.5.3h.1c.8-.2 1.6-.5 2.4-1 .2-.1.3-.4.2-.6-.3-.7-.2-1.5.3-2s1.3-.7 2-.3c.2.1.5 0 .6-.2.4-.7.8-1.5 1-2.4.1-.2-.1-.5-.3-.6zm-1.3 2.1c-.9-.3-2 0-2.7.7-.7.7-1 1.7-.7 2.7-.4.2-.9.4-1.3.6-.5-.8-1.4-1.4-2.4-1.4s-1.9.5-2.4 1.4c-.5-.1-.9-.3-1.3-.6.3-.9 0-2-.7-2.7-.7-.7-1.7-1-2.7-.7-.2-.4-.4-.9-.6-1.3.9-.5 1.5-1.4 1.5-2.4s-.5-1.9-1.4-2.4c.1-.5.3-.9.6-1.3.9.2 2 0 2.7-.7s1-1.7.7-2.7c.4-.2.9-.4 1.3-.6.4.9 1.3 1.5 2.3 1.5 1 0 1.9-.5 2.4-1.4.5.1.9.3 1.3.6-.3.9 0 2 .7 2.7.7.7 1.7 1 2.7.7.2.4.4.9.6 1.3-.8.5-1.4 1.4-1.4 2.4s.5 1.9 1.4 2.4c-.2.3-.4.8-.6 1.2z',
  privacypolicy: 'M19.1 13.2c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5m0-1c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5zm.5 9c0-.3-.2-.5-.5-.5H5.4v-17h13.2v6.5c0 .3.2.5.5.5s.5-.2.5-.5v-7c0-.3-.2-.5-.5-.5H4.9c-.3 0-.5.3-.5.5v18c0 .3.2.5.5.5h14.2c.3 0 .5-.2.5-.5zm-.7-4.1l1.9-1.8c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0l-1.6 1.5-.3-.3c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l.7.6c.1.1.2.1.3.1s.3 0 .4-.1zM6.7 15.4c.1.2.3.3.5.3h.2l3.8-1.6v-1.3c.4.1.7.2 1.1.2.3 0 .5 0 .8-.1v.8c0 .3.2.5.5.5s.5-.2.5-.5v-1h1.8c.1 0 .3-.1.4-.3s.1-.3 0-.5c-.5-.7-.7-2.7-.8-3.4v-.1c-.3-1.9-1.6-3.3-3.3-3.3s-3 1.4-3.3 3.3v.1c-.1.7-.4 2.7-.8 3.4-.1.1-.1.3 0 .5s.2.3.4.3h1.6v.8l-3.2 1.3c-.2.1-.4.4-.2.6zm2.6-3.6c0-.1.1-.3.1-.4.1.2.2.3.3.4h-.4zm5.1-3.7c-.3.1-.6.2-.9.2-.6 0-1.4-.4-1.6-.8l-.3-.8-.5.8c-.4.6-.7.8-1.1.8.2-1.2 1.1-2.1 2.2-2.1 1 0 1.9.8 2.2 1.9zm.7 3.7h-.4c.1-.1.2-.3.3-.4 0 0 .1.2.1.4zm-2.8.3c-1.2 0-2.2-1.2-2.3-2.7.6 0 1.2-.3 1.6-.8.5.4 1.4.7 2 .7.3 0 .7-.1 1-.2 0 1.7-1.1 3-2.3 3z',
  profiles: 'M4 13a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5zm0-4c-.83 0-1.5.67-1.5 1.5S3.17 12 4 12s1.5-.67 1.5-1.5S4.83 9 4 9zm2 15H2v-4.19c-1.84-.72-2-2.92-2-5.31V14h8v.5c0 2.39-.16 4.59-2 5.31V24zm-3-1h2v-3.94l.38-.1C6.65 18.64 6.96 17.32 7 15H1c.04 2.32.34 3.64 1.62 3.97l.38.09V23zm17-10a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5zm0-4c-.83 0-1.5.67-1.5 1.5S19.17 12 20 12s1.5-.67 1.5-1.5S20.83 9 20 9zm2 15h-4v-4.19c-1.84-.72-2-2.92-2-5.31V14h8v.5c0 2.39-.16 4.59-2 5.31V24zm-3-1h2v-3.94l.38-.1c1.28-.33 1.59-1.65 1.62-3.97h-6c.03 2.32.34 3.64 1.62 3.97l.38.1V23zM12 5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5zm0-4c-.83 0-1.5.67-1.5 1.5S11.17 4 12 4s1.5-.67 1.5-1.5S12.83 1 12 1zm2 15h-4v-4.19c-1.84-.72-2-2.92-2-5.31V6h8v.5c0 2.39-.16 4.59-2 5.31V16zm-3-1h2v-3.94l.38-.1C14.65 10.64 14.96 9.31 15 7H9c.04 2.31.34 3.64 1.62 3.97l.38.1V15z',
  refresh: 'M3.7 10.2l-1-2.8c-.1-.3 0-.6.3-.7.3-.1.5 0 .6.3l.5 1.5C5.5 5.3 8.5 3.2 12 3.2c3.9 0 7.3 2.7 8.2 6.6.1.3-.1.5-.4.6h-.1c-.2 0-.4-.2-.5-.4v-.1c-.9-3.4-3.8-5.8-7.2-5.8-3.1 0-5.8 2-7 4.9l1.9-.7c.3-.1.5 0 .6.3.1.3 0 .5-.3.6l-1.5.8-1.3.5h-.1-.1c-.1 0-.1 0-.1-.1-.3 0-.3-.1-.4-.2zm17.5 6.4l-1-2.8s0-.1-.1-.1l-.1-.1s-.1 0-.1-.1h-.1-.1-.1l-1.4.5-1.5.6c-.3.1-.4.4-.3.6s.4.4.6.3l1.9-.7c-1.1 2.9-3.8 4.9-7 4.9-3.4 0-6.4-2.4-7.2-5.8v-.1c-.1-.3-.3-.4-.6-.4-.3.1-.4.3-.4.6 1 4 4.3 6.8 8.3 6.8 3.4 0 6.4-2.1 7.8-5.2l.5 1.5c.1.2.3.3.5.3h.2c.2-.2.3-.5.2-.8z',
  reproductive: 'M7 16.2c-.1 0-.2 0-.3-.1-1-.6-1.7-1.4-2.2-2.2-.5-.9-.8-2-.8-3.3-.1-2.7 1-5.1 1.1-5.2l.1-.4h.4s1.2.1 2.6.6c.2.2.4.4.3.7-.1.3-.4.4-.6.3-.9-.3-1.7-.4-2.1-.5-.3.7-.9 2.6-.9 4.4 0 1.1.3 2 .7 2.8.4.7 1 1.3 1.9 1.9.2.1.3.5.2.7-.1.2-.3.3-.4.3zm3 3.4c.3 0 .5-.3.4-.6 0-.3-.3-.4-.6-.4-2 .4-3.6.2-4.8-.5-2.5-1.5-3.3-5.2-3.5-6.3.2-.1.5-.2.8-.3.3-.1.4-.4.3-.6-.1-.3-.4-.4-.6-.3-.7.2-1.2.4-1.2.4l-.3.1v.4c0 .2.6 5.5 4 7.5 1 .6 2.1.8 3.4.8.7 0 1.4 0 2.1-.2zm9.5-.6c3.4-2 4-7.2 4-7.5v-.4l-.3-.1s-.5-.2-1.2-.5c-.3-.1-.5.1-.6.3-.1.3.1.5.3.6.3.1.6.2.8.3-.2 1.2-1 4.9-3.5 6.3-1.2.7-2.8.9-4.8.5-.3 0-.5.1-.6.4s.1.5.4.6c.7.1 1.4.2 2 .2 1.4.1 2.5-.1 3.5-.7zm-2.2-2.9c1-.6 1.7-1.4 2.2-2.2.5-.9.8-2 .8-3.3.1-2.7-1-5.1-1.1-5.2l-.1-.4h-.4s-1.2.1-2.6.6c-.3.1-.4.4-.3.6.1.3.4.4.6.3.9-.3 1.6-.4 2.1-.5.3.7.9 2.6.9 4.4 0 1.1-.3 2-.7 2.8-.4.7-1 1.3-1.9 1.9-.2.1-.3.5-.2.7.1.1.3.2.4.2.1.2.2.1.3.1zm-5 2.6c.2-.1 4.5-3.2 4.5-7.2s-4.3-7.1-4.5-7.2l-.3-.2-.3.2c-.2.1-4.5 3.2-4.5 7.2s4.3 7.1 4.5 7.2l.3.2.3-.2zm3.5-7.2c0 2.9-2.8 5.4-3.8 6.2-.9-.8-3.8-3.3-3.8-6.2 0-2.9 2.8-5.4 3.8-6.2.9.8 3.8 3.3 3.8 6.2z',
  selectedCircle : 'M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z',
  sharesocialbrowser: 'M20 4v5.4c0 .3-.2.5-.5.5s-.5-.2-.5-.5V5.7l-9 9c-.1.1-.2.1-.4.1s-.3 0-.4-.1c-.2-.2-.2-.5 0-.7l9-9h-3.7c-.3 0-.5-.2-.5-.5s.3-.5.6-.5H20zm-.5 9.5c-.3 0-.5.2-.5.5v3.5c0 .8-.7 1.5-1.5 1.5h-11c-.8 0-1.5-.7-1.5-1.5v-11C5 5.7 5.7 5 6.5 5H10c.3 0 .5-.2.5-.5S10.3 4 10 4H6.5C5.1 4 4 5.1 4 6.5v11C4 18.9 5.1 20 6.5 20h11c1.4 0 2.5-1.1 2.5-2.5V14c0-.3-.2-.5-.5-.5z',
  sharesocialandroid: 'M18 16.1c-.8 0-1.4.3-2 .8l-7.1-4.2c.1-.2.1-.5.1-.7s0-.5-.1-.7L16 7.2c.5.5 1.2.8 2 .8 1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3c0 .2 0 .5.1.7L8 9.8C7.5 9.3 6.8 9 6 9c-1.7 0-3 1.3-3 3s1.3 3 3 3c.8 0 1.5-.3 2-.8l7.1 4.2c-.1.2-.1.4-.1.6 0 1.6 1.3 2.9 2.9 2.9s2.9-1.3 2.9-2.9-1.2-2.9-2.8-2.9zM18 4c.5 0 1 .4 1 1s-.5 1-1 1-1-.4-1-1 .5-1 1-1zM6 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm12 7c-.5 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.5 1-1 1z',
  sharesocialiosrounded: 'M8.1 5.4c-.1-.2-.1-.6 0-.8l3.5-3.5.4-.3.4.4 3.5 3.5c.2.2.2.5 0 .7-.1.1-.2.1-.4.1s-.3 0-.4-.1l-2.6-2.6v12.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5V2.7L8.9 5.4c-.2.1-.6.1-.8 0zm9.4 1.7H14c-.3 0-.5.2-.5.5s.2.5.5.5h3.5c.8 0 1.5.7 1.5 1.5v11c0 .8-.7 1.5-1.5 1.5h-11c-.8 0-1.5-.7-1.5-1.5v-11c0-.8.7-1.5 1.5-1.5H10c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H6.5C5.1 7.1 4 8.2 4 9.6v11c0 1.4 1.1 2.5 2.5 2.5h11c1.4 0 2.5-1.1 2.5-2.5v-11c0-1.4-1.1-2.5-2.5-2.5z',
  sharesocialios: 'M8.9 5.4l-.8-.8L12 .8l3.9 3.9-.7.7-2.6-2.6v12.8h-1V2.7L8.9 5.4zM14 7.1v1h5v14H5v-14h5v-1H4v16h16v-16h-6z',
  shareprofile: 'M17.4 21.1L12 18.9v-2.2-.1c1.2-1.1 2-2.8 2-4.8 0-3.3-2.3-6-5-6-2.8 0-5 2.7-5 6 0 2.1 1 4 2.4 5.1v1.9L1 20.9c-.3.1-.4.4-.3.7.1.2.3.3.5.3h.2l6-2.4v-2c.5.2 1.1.3 1.6.3.7 0 1.4-.2 2-.5v2.3l6 2.5h.2c.2 0 .4-.1.5-.3.1-.3-.1-.6-.3-.7zM9 6.9c2 0 3.6 1.7 4 4-1 .1-1.7-.3-2.3-1.5l-.5-.8-.5.8c-.4.7-1.7 1.4-2.8 1.4-.6 0-1.3-.2-1.7-.4h-.1c.5-2 2.1-3.5 3.9-3.5zm-4 4.9v-.4c.6.2 1.3.4 1.9.4 1.2 0 2.5-.5 3.2-1.3.7.9 1.5 1.4 2.5 1.4h.3c0 2.7-1.8 4.9-4 4.9-2.1 0-3.9-2.3-3.9-5zm17.4-1.5L19.2 9v-.8h1.6c.2 0 .3-.1.4-.3.1-.2.1-.4 0-.5-.4-.7-.7-2.7-.8-3.4v-.1C20.1 2 18.8.6 17.1.6c-1.7 0-3 1.4-3.3 3.3V4c-.1.7-.3 2.7-.8 3.4-.1.2-.1.3 0 .5s.3.3.4.3h1.8v1c0 .3.2.5.5.5s.5-.2.5-.5v-.8c.3.1.5.1.8.1.4 0 .7-.1 1.1-.2v1.3l3.8 1.6h.2c.2 0 .4-.1.5-.3.2-.2 0-.5-.2-.6zm-2.8-3.1c.1-.1.2-.2.3-.4 0 .1.1.3.1.4h-.4zm-2.5-5.5c1.1 0 2 .9 2.2 2.1-.4 0-.7-.2-1.1-.8l-.5-.8-.3.8c-.2.4-1 .8-1.6.8-.3 0-.6-.1-.9-.2.3-1.1 1.2-1.9 2.2-1.9zm-2.8 5.1c.1.1.2.3.3.4h-.4c0-.1.1-.3.1-.4zm.4-2.2c.3.1.7.2 1 .2.6 0 1.5-.3 2-.7.4.5 1 .8 1.6.8-.1 1.5-1.1 2.7-2.3 2.7-1.2-.1-2.3-1.4-2.3-3zm4.6 7.5c.2.2.2.5 0 .7-.1.2-.3.2-.4.2s-.3 0-.4-.1l-.5-.7c-.4 3.8-4.1 5.6-4.2 5.7-.1 0-.1.1-.2.1-.2 0-.4-.1-.4-.3-.1-.2 0-.5.2-.7 0 0 3.1-1.6 3.6-4.7l-.5.5c-.2.2-.5.2-.7 0s-.2-.5 0-.7l1.4-1.4c.2-.2.5-.2.7 0l1.4 1.4z',
  signout: 'M18.7 21c0 .3-.2.5-.5.5h-14c-.3 0-.5-.2-.5-.5V3c0-.3.2-.5.5-.5h14c.3 0 .5.2.5.5s-.2.5-.5.5H4.7v17h13.5c.3 0 .5.2.5.5zm1.4-9.4l-6-6c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l5.2 5.2H7.7c-.3 0-.5.2-.5.5s.2.5.5.5h10.8l-5.2 5.2c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1l6-6c.2-.2.2-.6 0-.8z',
  steps: 'M22.1 15.2c-.5-.8-1.3-1.3-2.6-1.5-2.8-.5-3.8-5.8-3.8-5.9 0-.1-.1-.3-.2-.3-.1-.1-.3-.1-.4-.1 0 0-1.9.5-3-.3-.5-.4-.7-1-.7-1.9 0-.2-.1-.4-.3-.5-.2-.1-4-1.2-6.4 4.9-.3.3-1.3 1.5-1.1 2.7.1.7.5 1.2 1.3 1.6l2.4 1.2c1.5.8 3.2 1.7 5.9 3 2 .9 4.3 1.1 5.2 1.1h.4c.2 0 2.1.1 3.1-.8.3-.3.5-.7.5-1.2.1-.2.2-1.1-.3-2zM10.2 5.7h.3c.1 1 .4 1.8 1.1 2.3 1 .8 2.5.7 3.3.5.1.3.1.6.3 1l-2.3.7c-.3.1-.4.4-.3.6.1.2.3.3.5.3h.2l2.3-.7c.1.3.2.6.4.9l-2.5.7c-.3.1-.4.4-.3.6.1.2.3.4.5.4h.1l2.5-.7c.7 1.2 1.7 2.3 3.1 2.5.9.2 1.6.5 1.9 1 .1.2.2.4.2.5-4.2.6-9.1-1.8-10.9-3.3-1.5-1.3-3.9-2.7-4.9-3.2 1.6-3.5 3.5-4.1 4.5-4.1zm8.6 12.6s-2.9.1-5.2-.9c-2.7-1.3-4.4-2.2-5.9-3-.8-.4-1.6-.8-2.4-1.3-.5-.2-.7-.5-.8-.8-.1-.5.3-1.2.6-1.6.9.5 3.3 1.8 4.8 3.1 1.7 1.4 6 3.7 10.1 3.7.5 0 .9 0 1.4-.1 0 .2-.1.3-.2.4-.5.4-1.9.5-2.4.5zM5.4 16H2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.4c.3 0 .5.2.5.5s-.2.5-.5.5zm3.4 1.6c0 .3-.2.5-.5.5H3.7c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h4.6c.3 0 .5.3.5.5z',
  spO2: 'M16.6 20.4c.2.2.1.5-.1.7-1.3.9-2.9 1.4-4.5 1.4-4.4 0-8-3.6-8-8C4 10.2 11.3 2 11.6 1.7c.2-.2.6-.2.7 0 .4.3 7.7 8.5 7.7 12.8 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-3.4-5.5-10-7-11.7-1.5 1.7-7 8.4-7 11.7 0 3.9 3.1 7 7 7 1.4 0 2.8-.4 3.9-1.2.2-.2.6-.1.7.1zm-5-15.6c-1.1 1.4-2.2 2.8-3 4.1-.2.2-.1.5.1.6.1.1.2.1.3.1.2 0 .3-.1.4-.2.9-1.3 1.9-2.7 3-4 .2-.2.1-.5-.1-.7-.2-.2-.5-.1-.7.1zm4.7 8c.3.7.5 1.5.5 2.5 0 .7-.1 1.3-.3 1.9-.2.6-.5 1.1-.9 1.5-.4.4-.8.7-1.4 1-.6.2-1.2.3-1.9.3s-1.3-.1-1.9-.3c-.6-.2-1-.5-1.4-1-.4-.4-.7-.9-.8-1.5-.2-.6-.3-1.2-.3-1.9s.1-1.3.3-1.9c.2-.6.5-1.1.9-1.5.4-.4.8-.7 1.4-.9.5-.2 1.2-.3 1.9-.3.9 0 1.7.2 2.4.6.6.2 1.1.8 1.5 1.5zm-1.4 2.4c0-.7-.1-1.2-.3-1.7-.2-.5-.5-.9-.9-1.1-.4-.2-.9-.4-1.4-.4-.4 0-.7.1-1 .2-.3.1-.6.3-.8.6-.2.3-.4.6-.5 1-.1.4-.2.9-.2 1.4 0 .5.1 1 .2 1.4.1.4.3.8.5 1 .2.3.5.5.8.6.3.1.6.2 1 .2.5 0 .9-.1 1.3-.4.4-.2.7-.6.9-1.1.3-.4.4-1 .4-1.7zm6 5.3c-.1-.1-.2-.1-.4-.1h-1.9c.1-.1.1-.2.2-.2.1-.2.4-.4.7-.7.4-.3.6-.5.8-.6.2-.1.3-.3.5-.6.1-.2.2-.5.2-.8 0-.2 0-.4-.1-.5-.1-.2-.2-.3-.3-.5-.1-.1-.3-.2-.4-.3-.3-.1-.6-.2-.9-.2-.3 0-.6 0-.8.1-.2.1-.4.2-.6.4-.1.1-.3.3-.3.5-.1.2-.1.4-.1.5 0 .1 0 .2.1.3.1.1.2.1.3.1.1 0 .2 0 .3-.1.1-.1.1-.2.2-.4s.1-.3.1-.3c.2-.3.4-.4.7-.4.1 0 .3 0 .4.1l.3.3c.1.1.1.3.1.4 0 .1 0 .3-.1.4-.1.1-.2.3-.3.4-.1.1-.3.3-.5.4-.1.1-.3.2-.6.5-.3.2-.5.5-.9.9-.1.1-.1.2-.2.3-.1.1-.1.3-.1.3 0 .1.1.3.2.4.1.1.2.1.4.1h2.6c.2 0 .3 0 .4-.1.1-.1.1-.2.1-.3 0-.1 0-.2-.1-.3z',
  times: 'M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z',
  temperature: 'M17.9 7.7c-.6.6-1.5.6-2.1 0s-.6-1.5 0-2.1 1.5-.6 2.1 0c.6.6.6 1.5 0 2.1zm-6.6 6.6l3.8-3.8c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2.1 0l-3.8 3.8c-.6.6-.6 1.5 0 2.1.6.6 1.5.6 2.1 0zM20 8.9l-9.4 9.4c-1.1 1.1-2.7 1.4-4.1.8l-2.7 2.7c-.6.6-1.5.6-2.1 0-.6-.6-.6-1.5 0-2.1L4.4 17c-.6-1.4-.3-3 .8-4.1l9.4-9.4C16.1 2 18.5 2 20 3.5c1.5 1.5 1.5 3.9 0 5.4zm-.7-.7c1.1-1.1 1.1-2.9 0-4s-2.9-1.1-4 0l-9.4 9.4c-.9.9-1.1 2.2-.5 3.3.1.2.1.4-.1.6L3 19.8c.2 0 .5.1.6.3.1.1.1.2.2.3L6 18.2c.2-.2.4-.2.6-.1 1.1.6 2.4.4 3.3-.5l9.4-9.4z',
  termsandconditions: 'M14 13.6H8c-.3 0-.5.2-.5.4s.2.5.5.5h6c.3 0 .5-.2.5-.5s-.2-.4-.5-.4zm-1 2H8c-.3 0-.5.2-.5.4s.2.5.5.5h5c.3 0 .5-.2.5-.5s-.2-.4-.5-.4zm2-4H8c-.3 0-.5.2-.5.4s.2.5.5.5h7c.3 0 .5-.2.5-.5s-.2-.4-.5-.4zm1-2H8c-.3 0-.5.2-.5.4s.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.4-.5-.4zM7.5 8c0 .3.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H8c-.3.1-.5.3-.5.5zm11.6 5.3c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5m0-1c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5zm0 9.5H4.9c-.3 0-.5-.2-.5-.5v-18c0-.3.2-.5.5-.5h14.2c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5s-.5-.2-.5-.5V3.8H5.4v17h13.7c.3 0 .5.2.5.5s-.2.5-.5.5zm-.5-4.5c-.1 0-.2 0-.3-.1l-.7-.6c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l.3.3 1.6-1.5c.2-.2.5-.2.7 0 .2.2.2.5 0 .7L19 17.2c-.2.1-.3.1-.4.1z',
  thumbsup: 'M18.5 22.6H12c-1.8 0-2.6-.4-3.6-.8-1.3-.5-2.8-1.2-7.4-1.2-.3 0-.5-.2-.5-.5v-9c0-.3.2-.5.5-.5h3c3.3 0 7-3.1 7-7.5 0-.9.8-1.6 1.7-1.6.6 0 1.2.3 1.6.7.5.5.7 1.2.7 2.1 0 1.6-.6 4.1-.9 5.3h7.4c1.1 0 2 .9 2 2 0 .9-.6 1.6-1.3 1.9.2.3.3.7.3 1.1 0 .9-.6 1.6-1.3 1.9.2.3.3.7.3 1.1 0 .9-.6 1.6-1.3 1.9.2.3.3.7.3 1.1 0 1.1-.9 2-2 2zm-17-3c4.4 0 6 .7 7.3 1.3.9.4 1.6.7 3.2.7h6.5c.6 0 1-.4 1-1s-.4-1-1-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.6 0 1-.4 1-1s-.4-1-1-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.6 0 1-.4 1-1s-.4-1-1-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.6 0 1-.4 1-1s-.4-1-1-1h-8c-.2 0-.3-.1-.4-.2-.1-.2-.1-.3-.1-.5 0 0 1-3.7 1-5.7 0-.6-.2-1.1-.4-1.5-.3-.3-.6-.4-.8-.4-.4 0-.7.2-.7.6 0 5-4.2 8.5-8 8.5H1.5v8.2z',
  thumbsdown: 'M20.5 3.4c0 .4-.1.8-.3 1.1.8.3 1.3 1 1.3 1.9 0 .4-.1.8-.3 1.1.8.3 1.3 1 1.3 1.9 0 .4-.1.8-.3 1.1.8.3 1.3 1 1.3 1.9 0 1.1-.9 2-2 2h-7.4c.3 1.2.9 3.7.9 5.3 0 .9-.2 1.6-.7 2.1-.4.5-1 .7-1.6.7-1 0-1.7-.7-1.7-1.6 0-4.4-3.7-7.5-7-7.5H1c-.3 0-.5-.2-.5-.5v-9c0-.3.2-.5.5-.5 4.6 0 6.2-.7 7.4-1.2.9-.4 1.8-.8 3.6-.8h6.5c1.1 0 2 .9 2 2zm-19 9H4c3.8 0 8 3.5 8 8.5 0 .4.4.6.7.6.2 0 .5 0 .8-.4.3-.3.4-.8.4-1.5 0-1.9-1-5.6-1-5.7 0-.1 0-.3.1-.4.1-.1.2-.2.4-.2h8c.6 0 1-.4 1-1s-.4-1-1-1h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5c.6 0 1-.4 1-1s-.4-1-1-1h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5c.6 0 1-.4 1-1s-.4-1-1-1h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5c.6 0 1-.4 1-1s-.4-1-1-1H12c-1.6 0-2.3.3-3.2.7-1.3.7-2.9 1.4-7.3 1.4v8z',
  weight: 'M19.1 3.5C18.2 2 15.6 1 12.5 1 9.4 1 6.8 2 5.9 3.5 4 3.6 2.5 5.1 2.5 7v12c0 1.9 1.6 3.5 3.7 3.5h12.7c2 0 3.7-1.6 3.7-3.5V7c-.1-1.9-1.6-3.4-3.5-3.5zM21.5 19c0 1.4-1.2 2.5-2.7 2.5H6.2c-1.5 0-2.7-1.1-2.7-2.5V7c0-1.4 1.2-2.5 2.8-2.5h.4l.1-.3C7.1 3.1 9.4 2 12.5 2s5.4 1.1 5.8 2.2l.1.3h.4c1.5 0 2.7 1.1 2.7 2.5v12zM17 6v1.4c-1-.2-2.2-.3-3.6-.4l.6-.9c.2-.2.1-.5-.1-.7-.2-.2-.5-.1-.7.1l-1 1.4c-1.6 0-3 .2-4.2.4V6c0-1.1 2-2 4.5-2s4.5.9 4.5 2z',
  yes: 'M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z',
  admin: 'M17 14.4c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1-1.1-.5-1.1-1.1.5-1.1 1.1-1.1zm0 3.1c-.7 0-2.2.4-2.2 1.1.5.7 1.3 1.2 2.2 1.2s1.7-.5 2.2-1.2c0-.7-1.5-1.1-2.2-1.1zm1-6.4V6.3L10.5 3 3 6.3v4.9c0 4.5 3.2 8.8 7.5 9.8.6-.1 1.1-.3 1.6-.5C13.2 22 15 23 17 23c3.3 0 6-2.7 6-6 0-3-2.2-5.4-5-5.9zM11 17c0 .6.1 1.1.2 1.6-.2.1-.5.2-.7.3-3.2-1-5.5-4.2-5.5-7.7V7.6l5.5-2.4L16 7.6v3.5c-2.8.5-5 2.9-5 5.9zm6 4c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z',
  enrolledpathway: 'M 18 9 l -1.41 -1.42 L 10 14.17 l -2.59 -2.58 L 6 13 l 4 4 Z m 1 -6 h -4.18 C 14.4 1.84 13.3 1 12 1 c -1.3 0 -2.4 0.84 -2.82 2 H 5 c -0.14 0 -0.27 0.01 -0.4 0.04 c -0.39 0.08 -0.74 0.28 -1.01 0.55 c -0.18 0.18 -0.33 0.4 -0.43 0.64 c -0.1 0.23 -0.16 0.49 -0.16 0.77 v 14 c 0 0.27 0.06 0.54 0.16 0.78 s 0.25 0.45 0.43 0.64 c 0.27 0.27 0.62 0.47 1.01 0.55 c 0.13 0.02 0.26 0.03 0.4 0.03 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m -7 -0.25 c 0.41 0 0.75 0.34 0.75 0.75 s -0.34 0.75 -0.75 0.75 s -0.75 -0.34 -0.75 -0.75 s 0.34 -0.75 0.75 -0.75 Z M 19 19 H 5 V 5 h 14 v 14 Z',
  visible: 'M 12 6 c 3.79 0 7.17 2.13 8.82 5.5 C 19.17 14.87 15.79 17 12 17 s -7.17 -2.13 -8.82 -5.5 C 4.83 8.13 8.21 6 12 6 m 0 -2 C 7 4 2.73 7.11 1 11.5 C 2.73 15.89 7 19 12 19 s 9.27 -3.11 11 -7.5 C 21.27 7.11 17 4 12 4 Z m 0 5 c 1.38 0 2.5 1.12 2.5 2.5 S 13.38 14 12 14 s -2.5 -1.12 -2.5 -2.5 S 10.62 9 12 9 m 0 -2 c -2.48 0 -4.5 2.02 -4.5 4.5 S 9.52 16 12 16 s 4.5 -2.02 4.5 -4.5 S 14.48 7 12 7 Z',
  notverified: 'M11 15h2v2h-2v-2zm9.6-.2l.3 3.7-3.6.8-1.9 3.2L12 21l-3.4 1.5-1.9-3.2-3.6-.8.3-3.7L1 12l2.4-2.8-.3-3.7 3.6-.8 1.9-3.2L12 3l3.4-1.5 1.9 3.2 3.6.8-.3 3.7L23 12l-2.4 2.8zm-2.1-.7l1.9-2.1-1.9-2.1.3-2.8-2.8-.6-1.4-2.4L12 5.2 9.4 4.1 8 6.5l-2.8.6.3 2.8L3.7 12l1.9 2.1-.3 2.8 2.7.6 1.4 2.4 2.6-1.1 2.6 1.1 1.4-2.4 2.7-.6-.2-2.8zM11 13h2V7h-2v6z',
  verified: 'M23 12l-2.4-2.8.3-3.7-3.6-.8-1.9-3.2L12 3 8.6 1.5 6.7 4.7l-3.6.8.3 3.7L1 12l2.4 2.8-.3 3.7 3.6.8 1.9 3.2L12 21l3.4 1.5 1.9-3.2 3.6-.8-.3-3.7L23 12zm-4 1.5l-.6.6.1.9.2 1.9-1.9.4-.8.2-.4.7-1 1.7-1.8-.8-.8-.2-.8.3-1.8.8-1-1.7-.4-.7-.8-.2-1.9-.4.2-2 .1-.9-.6-.6L3.7 12 5 10.5l.6-.6-.2-.9-.2-1.9 1.9-.4.9-.2.4-.7 1-1.7 1.8.8.8.2.8-.3 1.8-.8 1 1.7.4.7.8.2 1.9.4-.1 2-.1.9.6.6 1.3 1.5-1.4 1.5zm-8.9.3l-2.3-2.3L6.3 13l3.8 3.8 7.3-7.4-1.5-1.5-5.8 5.9z',
  unknown: 'M23 12l-2.4-2.8.3-3.7-3.6-.8-1.9-3.2L12 3 8.6 1.5 6.7 4.7l-3.6.8.3 3.7L1 12l2.4 2.8-.3 3.7 3.6.8 1.9 3.2L12 21l3.4 1.5 1.9-3.2 3.6-.8-.3-3.7L23 12zm-4.2 4.9l-2.8.6-1.4 2.4-2.6-1.1-2.6 1.1L8 17.5l-2.7-.6.3-2.8L3.7 12l1.9-2.1-.4-2.8L8 6.5l1.4-2.4L12 5.2l2.6-1.1L16 6.5l2.7.6-.3 2.8 1.9 2.1-1.9 2.1.4 2.8zM11 16h2v2h-2v-2zm5-6c0 2.5-3 2.8-3 5h-2c0-3.2 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.2 1.8-4 4-4s4 1.8 4 4z',
  createUser_user: 'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
  createUser_plan:'M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8c0-4.41,3.59-8,8-8 s8,3.59,8,8C20,16.41,16.41,20,12,20z M12.89,11.1c-1.78-0.59-2.64-0.96-2.64-1.9c0-1.02,1.11-1.39,1.81-1.39 c1.31,0,1.79,0.99,1.9,1.34l1.58-0.67c-0.15-0.44-0.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6,0.56-2.62,2.85-2.62,2.96 c0,2.27,2.25,2.91,3.35,3.31c1.58,0.56,2.28,1.07,2.28,2.03c0,1.13-1.05,1.61-1.98,1.61c-1.82,0-2.34-1.87-2.4-2.09L8.1,14.75 c0.63,2.19,2.28,2.78,3.02,2.96V19h1.75v-1.24c0.52-0.09,3.02-0.59,3.02-3.22C15.9,13.15,15.29,11.93,12.89,11.1z',
  create_User_dashboard: 'M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z',
  tick: 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z',
  verificationCodeLock:'M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z',
  backArrow:'M16 7H3.83L9.415 1.415L8 0L0 8L8 16L9.415 14.585L3.83 9H16V7Z',
  greenTick:'M9.26602 15.2665L16.316 8.21651L14.916 6.8165L9.26602 12.4665L6.41602 9.61649L5.01601 11.0165L9.26602 15.2665ZM10.666 20.6665C9.28268 20.6665 7.98269 20.4038 6.76603 19.8785C5.54934 19.3538 4.49101 18.6415 3.59101 17.7415C2.69101 16.8415 1.97868 15.7832 1.45401 14.5665C0.928682 13.3498 0.666016 12.0498 0.666016 10.6665C0.666016 9.28317 0.928682 7.98318 1.45401 6.76649C1.97868 5.54983 2.69101 4.4915 3.59101 3.5915C4.49101 2.6915 5.54934 1.97884 6.76603 1.45351C7.98269 0.928838 9.28268 0.666504 10.666 0.666504C12.0493 0.666504 13.3493 0.928838 14.566 1.45351C15.7827 1.97884 16.841 2.6915 17.741 3.5915C18.641 4.4915 19.3534 5.54983 19.878 6.76649C20.4034 7.98318 20.666 9.28317 20.666 10.6665C20.666 12.0498 20.4034 13.3498 19.878 14.5665C19.3534 15.7832 18.641 16.8415 17.741 17.7415C16.841 18.6415 15.7827 19.3538 14.566 19.8785C13.3493 20.4038 12.0493 20.6665 10.666 20.6665ZM10.666 18.6665C12.8993 18.6665 14.791 17.8915 16.341 16.3415C17.891 14.7915 18.666 12.8998 18.666 10.6665C18.666 8.43318 17.891 6.54149 16.341 4.99151C14.791 3.4415 12.8993 2.66651 10.666 2.66651C8.43269 2.66651 6.541 3.4415 4.99102 4.99151C3.44101 6.54149 2.66602 8.43318 2.66602 10.6665C2.66602 12.8998 3.44101 14.7915 4.99102 16.3415C6.541 17.8915 8.43269 18.6665 10.666 18.6665Z'
};
