import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, OpenMenuAction } from 'shared';
import { routePaths } from 'services/routing';
import { NavLink } from 'react-router-dom';
import { getIdentityId } from 'identity/selectors';
import './bottom-navigation.scss';

export default function BottomNavigation() {
  const identityId = useSelector(getIdentityId);
  if (!identityId) {
    return null;
  }

  return (
    <footer id="bottom-nav">
      <NavLink id="nav-link-logo" to={routePaths.root} className="nav-health" activeClassName="active" exact>
        <Icon symbol="logo" />
      </NavLink>
      <NavLink id="nav-link-pathways" className="nav-pathways" to={routePaths.programs} activeClassName="active">
        <Icon symbol="healthprograms" />
      </NavLink>
      <OpenMenuAction />
    </footer>
  );
}
