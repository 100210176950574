import React from 'react';
import ErrorLabel from 'shared/form-fields/ErrorLabel';

function SubLabelSection(props) {
  const { showError, error, subLabel } = props;
  if (!(showError || subLabel)) {
    return null;
  }

  return (
    <div className="subLabelWrapper">
      {subLabel && !showError && <small className="subLabel">{subLabel}</small>}
      <ErrorLabel show={showError} error={error} />
    </div>
  );
}

export default SubLabelSection;
