import React from 'react';
import PropTypes from 'prop-types';
import styleVars from 'styles/_variables.scss';
import './avatar.scss';

class AvatarComponent extends React.Component {
  getInitials(name) {
    if (!name) {
      return [];
    }

    const nameParts = name
      .split(' ')
      .filter(n => n.length > 0)
      .map(n => n.slice(0, 1).toUpperCase());

    const initials = nameParts.length > 1 ? [nameParts[0], nameParts[nameParts.length - 1]] : [nameParts[0]];

    return initials;
  }

  render() {
    const { profile, themes } = this.props;
    const { name, theme } = profile;

    const initials = this.getInitials(name);

    return (
      <figure className="avatar" style={themes[theme]}>
        <div className="initials">{initials}</div>
      </figure>
    );
  }
}

AvatarComponent.displayName = 'Avatar Component';
AvatarComponent.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    theme: PropTypes.number
  }).isRequired,
  themes: PropTypes.shape({
    backgroundColor: PropTypes.string
  })
};
AvatarComponent.defaultProps = {
  themes: {
    0: { backgroundColor: styleVars.colorGrayLight, color: styleVars.colorBlack },
    1: { backgroundColor: styleVars.colorBlue, color: styleVars.colorWhite },
    2: { backgroundColor: styleVars.colorMagenta, color: styleVars.colorWhite },
    3: { backgroundColor: styleVars.colorTeal, color: styleVars.colorWhite },
    4: { backgroundColor: styleVars.colorYellow, color: styleVars.colorBlack }
  }
};

export default AvatarComponent;
