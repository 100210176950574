/* eslint-disable max-len */
export default {
  accept: 'Accept',
  acceptingInvitation: 'Accepting invitation...',
  activity: {
    visTitle: 'From {0}',
    visDurationlabel: 'Duration: {0} min',
    visIntensityLabel: 'Intensity Score: {0}'
  },
  footer: {
    companyName: 'Calcium LLC',
    privacyUrl: 'https://calciumhealth.com/privacy-policy/',
    termsAndConditionUrl: 'https://calciumhealth.com/terms-and-conditions/',
    supportUrl: 'https://support.calciumhealth.com',
    privacy: 'Privacy',
    termsAndConditions: 'Terms and Conditions',
    support: 'Support'
  },
  activityTitleTab: 'Add Activity',
  activityLogHeaderDefault: 'Add Activity',
  activityFormNameLabel: 'Activity',
  activityFormNotesPlaceholder: 'Add any notes about this activity',
  activityStartDateTimeLabel: 'Start Time',
  activityDurationLabel: 'Duration',
  and: '{0} and {1}',
  observation: {
    context: {
      contextHeader: 'This data is associated with \'{0}\' task of the {1} pathway.',
      dateAfter: 'Due after {0}',
      dateBefore: 'Due before {0}',
      dateBetween: 'Due between {0} - {1}'
    },
    bodyArms: {
      title: 'Arms',
      logTitle: 'Log Arms',
      noDataText: 'No arms data has been recorded yet.',
      tileNoDataText: 'Add your first arms data'
    },
    bodyChest: {
      title: 'Chest',
      logTitle: 'Log Chest',
      noDataText: 'No chest data has been recorded yet.',
      tileNoDataText: 'Add your first chest data'
    },
    bodyFat: {
      title: 'Body Fat',
      noDataText: 'Connect a device to start seeing your body fat measurements here.'
    },
    bodyHips: {
      title: 'Hips',
      logTitle: 'Log Hips',
      noDataText: 'No hips data has been recorded yet.',
      tileNoDataText: 'Add your first hips data'
    },
    bodyThighs: {
      title: 'Thighs',
      logTitle: 'Log Thighs',
      noDataText: 'No thighs data has been recorded yet.',
      tileNoDataText: 'Add your first thighs data'
    },
    bodyWaist: {
      title: 'Waist',
      logTitle: 'Log Waist',
      noDataText: 'No waist data has been recorded yet.',
      tileNoDataText: 'Add your first waist data'
    },
    bloodOxygen: {
      title: 'Blood Oxygen',
      noDataText: 'Connect a device to start seeing your blood oxygen percentage readings here.'
    },
    bloodGlucose: {
      title: 'Blood Glucose',
      noDataText: 'Start tracking your blood glucose.'
    },
    bloodPressure: {
      title: 'Blood Pressure',
      noDataText: 'Connect a device to start seeing your blood pressure readings here.',
      systolicChartLabel: 'Systolic',
      diastolicChartLabel: 'Diastolic',
      systolicFormLabel: 'Systolic',
      systolicFormSubLabel: 'Top number',
      diastolicFormLabel: 'Diastolic',
      diastolicFormSubLabel: 'Bottom number',
      diastolicValueHigherError: 'Must be lower than Systolic'
    },
    heartRate: {
      title: 'Heart Rate',
      logTitle: 'Log Heart Rate',
      noDataText: 'No heart rate data has been recorded yet.',
      tileNoDataText: 'Add your first heart rate'
    },
    height: {
      title: 'Height',
      logTitle: 'Log Height',
      noDataText: 'No height data has been recorded yet.',
      tileNoDataText: 'Add your first height'
    },
    insulin: {
      title: 'Insulin',
      noDataText: 'Connect a device to start seeing your insulin readings here.'
    },
    peakFlow: {
      title: 'Peak Flow',
      noDataText: 'Connect a device to start seeing your peak flow readings here.',
      peakFlowFormLabel: 'Peak Flow (L/min)'
    },
    steps: {
      title: 'Steps',
      noDataText: 'Connect a device to start seeing your daily steps here.',
      tileTitle: 'Daily Steps',
      barChartFormat: '{0} steps'
    },
    temperature: {
      title: 'Temperature',
      logTitle: 'Log Temperature',
      noDataText: 'No temperature data has been recorded yet.',
      tileNoDataText: 'Add your first temperature'
    },
    weight: {
      title: 'Weight',
      logTitle: 'Log Weight',
      noDataText: 'No weight data has been recorded yet.'
    }
  },
  checkEmail: 'Please check your email. We have sent an email to {0}.',
  sendingConfirmationEmail: 'Sending another confirmation email.',
  daysShortLabel: 'd',
  hoursShortLabel: 'h',
  minutesShortLabel: 'm',
  secondsShortLabel: 's',
  daysLabel: 'days',
  hoursLabel: 'hours',
  minutesLabel: 'minutes',
  secondsLabel: 'seconds',
  activityIntensityLabel: 'Intensity',
  low: 'Light',
  medium: 'Moderate',
  high: 'Vigorous',
  lowActivityHelper: 'You can sing a song',
  mediumActivityHelper: 'You can hold a conversation',
  highActivityHelper: 'You can only say a few words',
  add: 'Add',
  agree: 'I Agree',
  app: 'Calcium',
  aspartof: 'as part of',
  back: 'Back',
  bmiCategoryNormal: '(Normal)',
  bmiCategoryObese: '(Obese)',
  bmiCategoryOverweight: '(Overweight)',
  bmiCategoryUnderweight: '(Underweight)',
  bmiDetailsBmi: 'Recent BMI',
  bmiTitle: 'BMI',
  bmiHeader: 'Body Mass Index',
  bmiNoDataText: 'Both height and weight data are required to calculate BMI.',
  bodyMeasurementsHeader: 'Body Measurements',
  browserInformationDisclaimer: 'The following information is being gathered for diagnostic use.',
  cancel: 'Cancel',
  close: 'Close',
  continue: 'Sign In',
  copyLink: 'Copy Link',
  copyright: 'Calcium LLC',
  createSelfProfileTitle: 'Create Your Profile',
  createOtherProfileTitle: 'Create Profile',
  currentSelection: 'Current Selection',
  dashboard: {
    allResults: 'View All',
    data: { header: 'Health Data' },
    dataConnections: {
      header: 'Data Sources',
      tile: 'Devices & Data'
    },
    pathways: {
      activeHeader: 'My Pathways',
      completedHeader: 'Completed Pathways',
      header: 'Explore Pathways',
      description: 'Our personalized health improvement programs.',
      nextTask: 'Next Task',
      dueToday: 'Due Today'
    }
  },
  dateDisplayToday: 'Today',
  dateDisplayTomorrow: 'Tomorrow',
  dateDisplayYesterday: 'Yesterday',
  dateDisplayDayFormat: 'dddd',
  decline: 'Decline',
  deleted: 'Deleted',
  deleting: 'Deleting...',
  devices: {
    connectButton: 'Connect a device',
    errorMessage: 'Unable to prepare for device and data connections',
    explanation: 'Connect your devices from Fitbit, Garmin Connect, iHealth, and Withings.',
    dotDescription: 'This colored dot will indicate data came from a third-party.',
    overviewTitle: 'Devices & Data',
    connectError: 'Failed to open device manager. Click to try again.',
    syncTitle: 'Automatically Sync Data',
    needADeviceQuestion: 'Need a device that works with Calcium?',
    marketplaceLink: 'Get a compatible device here.',
    healthKitExplanation: 'Click here to connect or disconnect your Apple Health account.',
    healthKitButton: 'Apple Health'
  },
  done: 'Done',
  edit: 'Edit',
  editProfile: 'Edit Profile',
  error: 'An error occurred',
  errorLoadProfile: 'Sorry, we were unable to load your profile.  Please check your internet connection and try again.',
  errorPasswordError: 'Password is not allowed, it might be too common',
  errorPasswordStrengthError: 'Password must be at least 8 characters',
  errorUserExistsError: 'Email already registered',
  errorInvalidEmailFormat: 'Invalid email format',
  errorCodeMessage: {
    inviteAlreadyExists: 'A sharing invite already exists for the user with this email',
    inviteSelf: 'Cannot invite self',
    inviteUsed: 'This invite is no longer valid',
    inviteExistingRelationship: 'The user with this email already has access to this profile'
  },
  feedbackEmailSubject: 'Calcium Feedback',
  formAvatarLabel: 'Avatar',
  formBirthdateLabel: 'Birthdate',
  formBloodGlucoseLabel: 'Blood Glucose (mg/dL)',
  formBodyChestLabel: 'Chest (inches)',
  formBodyChestPlaceholder: 'Example: 30',
  formBodyHipsLabel: 'Hips (inches)',
  formBodyHipsPlaceholder: 'Example: 30',
  formBodyThighsLabel: 'Thighs (inches)',
  formBodyThighsPlaceholder: 'Example: 30',
  formBodyWaistLabel: 'Waist (inches)',
  formBodyWaistPlaceholder: 'Example: 30',
  formBodyArmsLabel: 'Arms (inches)',
  formBodyArmsPlaceholder: 'Example: 30',
  formCancel: 'Cancel',
  formContinue: 'Continue',
  formDayLabel: 'Day',
  formEmailLabel: 'Email',
  formResetEmailLabel: 'Email',
  formGenderLabel: 'Gender',
  formHeartRateLabel: 'Heart Rate (bpm)',
  formHeartRatePlaceholder: 'Example: 120',
  formHeightFeetLabel: 'Feet',
  formHeightInchesLabel: 'Inches',
  formLast4Ssn: 'Last 4 digits of SSN',
  medicationDoses: 'Doses',
  noMedicationDoses: 'No medications doses matching your search have been recorded.',
  medications: {
    deleteButton: 'Delete Medication',
    deleteWarning: 'Warning: Deleting this medication will also delete all of its recorded doses'
  },
  medicationDoseLogHeaderDefault: 'Medication Dose',
  medicationDoseLogHeader: 'Add {0} Dose',
  medicationDoseAddTitleTab: 'Add Medication Dose',
  medicationDosageCallToAction: 'Add Dose',
  medicationMostRecentDose: '{0} taken {1}',
  medicationNoTypicalDose: 'No typical dose was found',
  medicationFormTypicalDose: 'Typical Dose:',
  medicationLogHeader: 'Add Medication',
  medicationEditHeader: 'Edit Medication',
  medicationAddTitleTab: 'Add Medication',
  medicationEditTitleTab: 'Edit Medication',
  medicationFormNameLabel: 'Medication Name',
  medicationFormNotesPlaceholder: 'Add any notes about this medication',
  medicationFormTypicalDosePlaceholder: 'Add your typical dose',
  medicationFormTypicalDoseLabel: 'Typical Dose (optional)',
  medicationDoseFormlDoseLabel: 'Dose (optional)',
  medicationDoseFormlDosePlaceholder: 'Dose Taken',
  morning: 'Morning',
  afternoon: 'Afternoon',
  evening: 'Evening',
  nighttime: 'Nighttime',  
  formCreateAccount: 'Create account',
  formMonthLabel: 'Month',
  formNameLabel: 'Preferred Name',
  formNotesLabel: 'Notes (optional)',
  formNotesPlaceholder: 'What was going on at the time?',
  formPasswordLabel: 'Password',
  formPasswordRequirementLabel: 'Password (8 character minimum)',
  formPrivacyPolicyAgreeText: 'By clicking "{0}" you are accepting our {1} and {2}.',
  formProfileHeader: 'Profile',
  formProfileSave: 'Save Profile',
  formSave: 'Save',
  formSignInButton: 'Sign In',
  formContinueButton: 'Continue',
  formSignInHeader: 'Sign In',
  formSignInForgotPassword: 'Forgot password?',
  formSignInResetPasswordLink: 'Reset',
  formSignInJoin: 'Create an account',
  formSignInJoinText: 'New to Calcium?',
  formSubmit: 'Submit',
  formTemperatureLabel: 'Temperature (ºF)',
  formTemperaturePlaceholder: 'Temperature',
  formTimestampLabel: 'Timestamp',
  formWeightLabel: 'Weight (lbs)',
  formWeightPlaceholder: 'Weight',
  formYearLabel: 'Year',
  genderFemale: 'Female',
  genderMale: 'Male',
  genderOther: 'Other',
  genderUnknown: 'Unknown',
  generalFeedbackMessage: 'We appreciate your feedback and would love to hear from you about your experience with Calcium. Let us know what you love and what could be better.',
  generatingLink: 'Creating sharing link...',
  goToDashboard: 'Go to Dashboard',
  healthScoreLabel: 'Calcium Score',
  healthDataHeader: 'Health Data',
  healthDataBodyMeasurementsSectionHeader: 'Body Measurements',
  healthDataVitalsSectionHeader: 'Vitals',
  healthDataOtherSectionHeader: 'Other',
  healthDashboardHeader: 'Dashboard',
  healthChampionScore: 'Calcium Score',
  healthBadge: {
    unknown: {
      message: 'Status: Unknown',
      status: 'Please complete the required tasks.',
      calculatingStatus: 'Calculating new status.',
      errorStatus: 'An error occurred retrieving the latest status'
    },
    verified: {
      message: 'Status: Okay',
      status: 'Thank you for completing the assessment.'
    },
    unverified: {
      message: 'Status: Warning',
      status: 'Be sure to complete the required tasks. If you reported symptoms, follow CDC guidelines for next steps.'
    },
    updated: 'Updated {0}',
    onDay: 'On {0}',
    dateAtTime: 'MM/DD/YYYY [at] h:mm a'
  },
  healthDataLink: 'Health Data',
  healthData: {
    connect: {
      identityForm: {
        address1Label: 'Address',
        address2Label: 'Suite / Apt #',
        addressCityLabel: 'City',
        addressStateLabel: 'State',
        birthdateLabel: 'Birthdate',
        firstNameLabel: 'Legal First Name',
        lastNameLabel: 'Legal Last Name',
        genderLabel: 'Gender',
        profileLabel: 'Verify identity for {0}',
        zipCodeLabel: 'Zip Code',
        submitButton: 'Start Verification',
        validationBirthdate: 'Birthdate is invalid',
        validationDay: 'Day must be between 1 and 31',
        validationMonth: 'Month must be between 1 and 12',
        validationZipCode: 'Zip Code must be 5 digits',
        disclaimer: 'By clicking Start Verification you certify that you have legal authorization to access Protected Health Information for this individual.'
      },
      identityVerification: {
        challengeMessage: 'For your security we need a little more information to complete your verification.',
        contactCta: 'Contact Support',
        continueVerificationAction: 'Continue Verification',
        emailSubject: 'Identity Verification Issues',
        emailMessage: 'I am having trouble verifying my identity and need some assistance.',
        introDetails: 'Calcium is dedicated to protecting your privacy. Please help us verify your identity.',
        introHeader: 'Connect Your Health Records',
        processingMessage: 'Processing ...',
        ssnHeader: 'Verify Identity',
        ssnParagraph1: 'In order to make sure we found you, we need you to verify the last four digits of your SSN.',
        ssnParagraph2: 'We use this information for verification only, and will not store it once complete.',
        startVerificationAction: 'Start Verification',
        steps: '1. Identity verification usually takes between five and ten minutes.\n\n3. You may attempt this process **one time**.',
        title: 'Verify Your Identity',
        toastProcessing: 'Processing',
        toastSuccess: 'Identity verified!',
        toastError: 'Could not verify your identity',
        verificationErrorHeader: 'Sorry About That',
        verificationErrorDetails: 'We were not able to verify your identity.\n\nThere are several reasons why this may have occurred, especially for those under 18 years old.\n\nCalcium is dedicated to protecting your privacy. Until you have verified your identity you will not be able to sync your electronic health records.\n\nWe would like to help you with an alternative method to verify your identity. Please contact support.',
        verifyIdentiyHeader: 'Verify Identity',
        warning: 'Please make sure you are in an environment where you will be able to complete the following process without distraction.'
      },
      status: {
        actionConnect: 'Connect',
        actionConnectLong: 'Connect Health Records',
        complete: 'Complete!',
        connected: 'Connected',
        connecting: 'Connecting...',
        header: 'Health Records',
        lastSynced: 'Synced {0}',
        noData: 'We didn\'t find any of your health records. Don\'t give up hope! We are continuously expanding our network!',
        processing: 'Processing',
        returnToDashboard: 'Return to Dashboard',
        searchComplete: 'Search Complete',
        searching: 'We are now searching our networks for your health information.',
        searchingDisclaimer: 'This could take a while to complete so feel free to continue using the app. We will let you know when we are done.',
        sorry: 'Sorry',
        startedSync: 'Started Sync',
        successDetails: 'Your health records are now available!',
        syncing: 'Syncing...',
        syncDataAction: 'Sync Now',
        syncError: 'Something went wrong. Please check back later.',
        viewHealthRecords: 'View Health Records'
      }
    },
    conditions: {
      connectMessage: 'Connect your health records to see your conditions.',
      noConditions: 'No conditions found',
      title: 'Conditions',
      linkText: 'Conditions',
      diagnosed: 'Diagnosed',
      severity: 'Severity',
      reportedBy: 'Reported By',
      remission: 'Remission',
      unspecified: 'Unspecified'
    },
    encounters: {
      connectMessage: 'Connect your health records to see your interactions with physicians and other health care providers.',
      title: 'Encounters',
      diagnoses: 'Diagnoses',
      linkText: 'Encounters',
      location: 'Location',
      noRecords: 'No encounters found',
      provider: 'Provider',
      started: 'Started',
      stopped: 'Stopped'
    },
    immunizations: {
      connectMessage: 'Connect your health records to see your immunization history.',
      dose: 'Dose',
      title: 'Immunizations',
      linkText: 'Immunizations',
      lotNumber: 'Lot #',
      manufacturer: 'Manufacturer',
      noRecords: 'No immunization records found',
      practitioner: 'Practitioner'
    },
    labs: {
      connectMessage: 'Connect your health records to see your lab results.',
      title: 'Lab Results',
      linkText: 'Lab Results',
      noRecords: 'No lab results found',
      expandAction: 'Expand',
      collapseAction: 'Collapse'
    },
    procedures: {
      connectMessage: 'Connect your health records to see your history of medical procedures.',
      title: 'Procedures',
      status: 'Status',
      linkText: 'Procedures',
      location: 'Location',
      noRecords: 'No procedures found',
      provider: 'Provider',
      started: 'Started',
      stopped: 'Stopped'
    }
  },
  healthScoreDetails: 'The Calcium Score is a calculated number based on many factors related to physical health, mental health, lifestyle, and social factors that can be measured and managed. You can track your Calcium Score on a day-to-day basis while receiving personalized feedback and suggestions for improvement during your evolving healthcare journey.',
  helpscout: {
    heathSync: {
      subject: 'Connect Health Records',
      message: 'Please tell us a bit about what you were looking for when connecting your EHR'
    }
  },
  hipaaAuthorization: 'HIPAA Authorization',
  hipaaConsentIntroGreeting: 'Hello,',
  hipaaConsentIntroP1: 'We need authorization to use the personal health information of {0} to provide you the most relevant insights and complete app experience.',
  hipaaReconsentIntroP1: 'Each year, we need to renew your authorization to use the personal health information of {0} to provide you the most relevant insights and complete app experience.',
  hipaaConsentNewVersionIntroP1: 'We\'ve updated our HIPAA authorization form and need your authorization to continue to use the health information of {0} to provide you the most relevant insights and complete app experience.',
  hipaaConsentIntroP2: 'Without your authorization many of our platform\'s most innovative and useful features will be missing from your experience.',
  hipaaConsentP1: 'I have read this form and agree to the uses and disclosures of the information as described.',
  hipaaConsentP2: 'A copy of this authorization is valid as the original. You have a right to a copy of this authorization. By clicking “I Agree”, you are consenting to the above terms in lieu of an original signature on paper.',
  hipaaIntroNewProfileTitle: 'Before we continue..',
  hipaaIntroNewProfileContent: 'We need you to sign our HIPAA agreement for each new profile you create. We know health data can be sensitive and that\'s why we hold ourselves to the highest standard of security.',
  hipaaIntroNewUserTitle: 'Almost there..',
  hipaaIntroNewUserContent: 'But first we need you to sign our HIPAA agreement, we know that health data is personal and can be highly sensitive. That\'s why we hold ourselves to the highest standard of security and believe our users deserve to know how their data is being used.',
  hipaaIntroResignTitle: 'Before we continue..',
  hipaaIntroResignContent: 'We need you to resign our HIPAA agreement, just like you do each year when visiting the doctor. As always we understand health data can be sensitive and that\'s why we hold ourselves to the highest standard of security.',
  hipaaIntroUpdateTitle: 'Before we continue..',
  hipaaIntroUpdateContent: 'We need you to resign our HIPAA agreement, we have made a few changes. As always we understand health data can be sensitive and that\'s why believe our users deserve to know how their data is being used.',
  in: 'in {0}',
  inactiveHaveACode: 'Have an Invite Code?',
  inactiveHeader: 'Thanks for Joining',
  inactiveParagraph: 'Stay tuned as we\'ll be adding participants over time. If you have already received an invite code, enter it below for instant access. Otherwise, {0} and we\'ll email you once your access becomes available.',
  inactivePreHeader: 'Enter an Invite Code',
  inactiveRequestCode: 'request an invite code',
  inactiveRequestCodeEmailSubject: 'Request an Invite Code',
  includes: 'includes',
  interestCta: 'Email Us',
  interestEmailSubject: 'Including {0} as a feature would make Calcium a better product.',
  interestMessageCta: 'Would this feature be beneficial to you?',
  interestMessageSubmittedCta: 'Thanks for the feedback! We’d love to hear more about how you’d use it.',
  interestMessageactivity: 'Track and analyze the impact of your daily activity. Input detail about exercise, mindfulness, sleep and other activity directly into the app, and sync connected devices.',
  interestMessageallergies: 'Track and understand your known allergies. Input notes directly into the app, import your medical records and lab results.',
  interestMessagebloodglucose: 'Track and analyze your blood glucose levels. Input readings directly into the app, import your medical records and lab results, and sync connected devices.',
  interestMessagebp: 'Track and understand your blood pressure results. Input readings directly into the app, import your medical records and sync connected devices.',
  interestMessagehealthprograms: 'Get smart recommendations for health-related pathways to help meet your personalized goals.',
  interestMessageimmunizations: 'Track and manage your past, current and upcoming immunizations based on your medical records or direct data input.',
  interestMessagemedications: 'Track and manage your current medications based on your medical records or manually entered list. View drug information, interactions, and refill from specific pharmacy chains.',
  interestMessagenutrition: 'Get a clear picture of your daily eating habits, track progress towards your goals and access tips to improve and maintain a healthy diet.',
  interestMessagereproductive: 'Manage and maintain your reproductive health. Track reproductive processes and functions at all stages of life. Integrate your medical records, 3rd party apps and devices.',
  interestMessagespO2: 'Track and analyze your blood oxygen levels. Input readings directly into the app, import your medical records and sync connected devices.',
  interestMessageshareprofile: 'Share access privileges for profiles with others.',
  interestMessagegroup: 'Create groups, share information and support each other’s progress.',
  interviews: {
    answerYes: 'Yes',
    answerNo: 'No',
    continue: 'Continue',
    complete: 'Complete',
    unknownContentType: 'Unknown content type'
  },
  invitationAccepted: 'Success! Invitation was accepted successfully',
  invitationError: 'Failed to accept the invitation',
  invitationErrorCta: 'Please check your internet connection and try again.',
  invitationErrorToast: 'Failed to accept the invitation',
  join: 'Register',
  joinCalcium: 'Create account',
  journal: {
    linkJournals: 'My Journal',
    noData: 'No journal entries to show',
    title: 'My Journal',
    createTitle: 'Create Journal Entry',
    formTitle: 'Entry Title (optional)',
    formContent: 'Entry Content (4000 character limit)',
    deletePrompt: 'Are you sure you want to delete this entry? This operation cannot be undone.',
    deleteJournal: 'Delete Journal Entry',
    journalEntry: 'Journal Entry'
  },
  linkActivity: 'Activities',
  linkAddProfile: 'Add Someone',
  linkAllergies: 'Allergies',
  linkLearnMore: 'Calcium',
  linkHome: 'Home',
  linkMedications: 'Medications',
  linkNutrition: 'Nutrition',
  linkObservations: 'Vitals',
  privacyPolicy: 'Privacy Policy',
  eula: 'Terms & Conditions',
  lastName: 'Last Name',
  linkProfileList: 'People',
  linkReproductiveHealth: 'Reproductive Health',
  linkSelfProfile: 'Me',
  linkSignIn: 'Sign In',
  linkSignout: 'Sign Out',
  listActivityHeader: 'Activities',
  listGroupsHeader: 'Groups',
  listMedicationDosesHeader: 'Medication Doses',
  listMedicationsHeader: 'Medications',
  listMoreHeader: 'More Options',
  listPeopleHeader: 'People',
  listProfileHeader: 'Profiles',
  loading: 'Loading...',
  logButton: 'Add',
  signInError: 'Sign In Error',
  signInErrorMessage: 'Invalid email or password',
  marketplace: {
    label: 'Marketplace',
    sponsoredAds: 'Sponsored',
    noDataText: 'There are curently no items in the marketplace.',
    ads: {
      omronBp7900: {
        title: 'Omron - BP7900, Complete Wireless Upper Arm Blood Pressure Monitor + EKG',
        header: 'Omron - BP7900',
        summary: 'Complete Wireless Upper Arm Blood Pressure Monitor + EKG'
      }
    }
  },
  menu: 'More',
  more: 'more',
  modal: {
    yes: 'Yes',
    no: 'No'
  },
  namePlaceholder: 'First and Last',
  nav: {
    title: 'More',
    dashboard: 'Dashboard',
    health: 'Health Data',
    pathways: 'Pathways',
    more: 'More',
    people: 'People',
    preferences: 'Preferences',
    getHelp: 'Get Help',
    marketplace: 'Marketplace',
    hipaaAuth: 'HIPAA Authorization',
    privacyPolicy: 'Privacy Policy',
    termsConditions: 'Terms & Conditions',
    signOut: 'Sign Out'
  },
  noActivities: 'Log recent activity',
  noMedications: 'Add your first medication',
  noMedicationsPlaceholder: 'No medications have been recorded yet.',
  noMedicationDosesPlaceholder: 'No medication doses have been recorded today.',
  nonAuthWallPage: {
    nav: {
      about: 'About',
      partners: 'Partners'
    },
    header: 'Get Started',
    subheadCovid: 'COVID-19 vaccination tracking and screening for your team - made easy.',
    subheadDash: 'Grow your personal training business today.',
    subheadMember: 'New to Calcium?',
    subheadProvider: 'Think healthier.',
    headerProvider:'Create a Calcium Account',
    value1: 'Access your electronic health records in real time.',
    value2: 'Track your symptoms and activities.',
    value3: 'Show your doctor what\'s been going on.',
    value4: 'Consolidate your health accessory data.'
  },
  notEnoughInfoForHealthScore: 'Get better insights by adding more data.',
  notVerifiedForInvites: 'Please confirm your email to see invites. If you cannot find the email you can resend it.',
  notifications: {
    label: 'Notifications',
    noDataText: 'No notifications',
    markReadError: 'Error marking notification read',
    markDeletedError: 'Error deleting notification',
    deleteNotification: 'Delete notification'
  },
  offlineErrorMainText: 'You Are Offline',
  organizations: {
    admins: 'Administrators',
    adminsDisclaimer: 'These are the administrators of this group. If this information appears suspicious, decline the invite.',
    title: 'Groups',
    createGroupLink: 'Create Group',
    createDescription: 'Create a trusted health group and share important health news with members you invite.',
    joinGroupLink: 'Use Group Invite Code',
    groupNameLabel: 'Group Name',
    groupDescriptionLabel: 'Group Description (optional)',
    statuses: {
      none: 'None',
      pending: 'Pending',
      declined: 'Declined',
      active: 'Active',
      removed: 'Removed'
    },
    roles: {
      none: 'None',
      admin: 'Admin',
      member: 'Member'
    },
    details: {
      header: 'Group Details',
      membersListHeader: 'Memberships'
    },
    loadError: 'An error occurred while loading your groups',
    leavePrompt: 'Are you sure you want to leave {0}?',
    leavePromptForAdmin: 'Are you sure you want to leave {0}?\n\nIf you do, you will still be an admin for {0} but your health data will not be shared with {0}.',
    leaveOrgHeader: 'Leave Group',
    organizationInviteHeader: 'Group Invite',
    organizationInviteCodeHeader: 'Invite Code',
    inviteCodeLabel: 'Invite Code',
    inviteCodeIntro: 'Please enter the group\'s invite code.',
    inviteCodeSearching: 'Searching...',
    inviteCodeFound: 'Found!',
    inviteCodeAlreadyUsed: 'Invite code already used.',
    inviteCodeNotFound: 'Invite code not found.',
    removingOrg: 'Leaving {0}...',
    removedOrg: 'Left {0}',
    notVerifiedForInvites: 'Please verify your email to see your invites.',
    viewDetails: 'View Details',
    cannotRespond: 'You cannot accept an invite to join a group with a shared profile. Please choose a different profile to respond to this invite.',
    joinHeader: 'Group {0} Invitation', //group invite
    joinHeaderForProfile: 'Group {0} Invitation For {1}', //group invite for profile
    programInviteHeaderForProfile: 'Pathway invitation from {0} group', //pathway invite from group
    expectationMessage: '{2} is joining as a group {0}.',
    expectationMessageAdmin: 'You have been invited to be an {0} of {1}. Join as {2}.',
    accepting: 'Accepting...',
    accepted: 'Accepted',
    declining: 'Declining...',
    declined: 'Declined',
    membershipAlreadyExists: '{0} is already a member in {1}. Please choose a different profile to respond to this invite.',
    joinChildOrganization: 'Join the group {0}, part of {1}.', //group invite
    joinRootOrganization: 'Join the group {0}.', //group invite
    recommendedByOrg: 'Recommended by {0}',
    learnMore: 'Learn More',
    dataShared: 'The following data will be shared with {0}:',
    permissions: {
      adminHeader: 'Group Administration',
      adminMessageSingle: 'This groups admin is {0}.',
      adminMessageMultiple: 'Admins are {0} and {1}.',
      adminMessageExtended: 'Admins include {0} and {1} more.',
      observationHeader: 'Shared Data',
      observationMessageDefault: 'Question responses will be shared with group admins.',
      observationMessageSingle: 'Question responses and {0} will be shared with group admins.',
      observationMessageMultiple: 'Question responses, {0} and {1} will be shared with group admins.',
      pathwayHeader: 'Enrolled Pathways',
      pathwayMessageSingle: 'You will be enrolled in the {0} pathway.',
      pathwayMessageMultiple: 'You will be enrolled in the {0}, and {1} pathways.',
      choosePathway: 'Choose the pathways you want to enroll',
      selectAllPrograms: 'Select All'
    }
  },
  page: 'Page',
  page404: {
    titleTab: 'Page Not Found',
    heading: 'Oops!',
    subhead: 'We can’t seem to find the page you’re looking for.',
    error: 'Error code: 404',
    goBackButton: 'Home'
  },
  preferencesCelsiusHelper: '(Celsius)',
  preferencesCelsiusLabel: '°C',
  preferencesFahrenheitHelper: '(Fahrenheit)',
  preferencesFahrenheitLabel: '°F',
  preferencesImperialHelper: '(ft, in, lb, oz)',
  preferencesImperialLabel: 'Imperial',
  preferencesMetricHelper: '(m, cm, kg, g)',
  preferencesMetricLabel: 'Metric',
  preferencesOn: 'Yes',
  preferencesOff: 'No',
  preferencesShowFeaturePreviews: 'Preview Upcoming Features',
  preferencesShowFeaturePreviewsDescription: 'Choosing "yes" makes features available in the app that are in preview mode. Help us decide what to build next by voting and sharing your opinion.',
  preferencesTemperatureUnitHeader: 'Temperature Units',
  preferencesTitle: 'Preferences',
  preferencesUnitHeader: 'Units',
  preview: {
    devicesLabel: 'Connect Wearable Devices',
    devicesName: 'Wearable Devices',
    devicesDetails: 'Connect wearable devices for a clear overview of your progress and access health data reports.',
    healthRecordsLabel: 'Connect Health Records',
    healthRecordsName: 'Health Records',
    healthRecordsDetails: 'Connect your electronic health records to easily view and manage your data.'
  },
  previewGroupMembers: 'Could include you, friends, family, or care providers',
  previewGroupName: 'Calcium',
  programs: {
    detailsComplete: 'Pathway Complete',
    detailsCompleteButton: 'Complete Pathway',
    detailsCompletedOnDate: 'Completed {0}',
    detailsDayProgress: 'Day {0} of {1}',
    detailsEndedOnDate: 'Ended {0}',
    detailsEndingDate: 'Ending {0}',
    detailsResources: 'Resources',
    detailsTasksEmpty: 'No tasks',
    detailsTasksAvailableEmpty: 'No tasks to complete yet',
    detailsTasksComplete: '{0} of {1} tasks completed',
    detailsAvailableTaskCompleteSingle: 'Current task is completed',
    detailsAvailableTasksComplete: 'All {0} tasks are completed',
    detailsUnenrollButton: 'Quit Pathway',
    detailsUnenrollHeader: 'Do you want to quit this pathway?',
    detailsUnenrollText: 'Quitting may cause you to lose data connection and progress associated with this pathway.',
    enrollButton: 'Start',
    enrollDuration: 'Duration: {0} days',
    enrollDurationSingular: 'Duration: {0} day',
    enrollDurationShort: '{0} Days',
    enrollDurationShortSingular: '{0} Day',
    enrollHeader: 'Start',
    enrollTitleTab: 'Pathway Enrollment',
    willBeEnrolled: 'You will be enrolled in the {0} pathway',
    sharedDataHeader: 'Shared Data',
    willSeeData: 'Question responses will be shared with group admins.',
    sharedData: 'Pathway asks for {0} data.',
    listHeader: 'Pathways',
    completedListHeader: 'Completed Pathways',
    listItemEnrolled: 'Active',
    listPlaceholderNoAvailablePrograms: 'No pathways are currently available',
    listPlaceholderNoCompletedPrograms: 'No pathways have been completed yet',
    listTabAvailable: 'Available',
    listTabEnrolled: 'Enrolled',
    listTabCompleted: 'Completed',
    programShareTitle: 'Calcium Pathway In Progress',
    programShareText: 'I am following the {0} pathway on Calcium',
    programShareDialogTitle: 'Share With Friends',
    readyToComplete: 'Ready to complete!',
    reEnrollText: 'Continue your journey; re-enroll in this pathway and take the next steps.',
    reEnrollLabel: 'Re-Enroll',
    reEnrollingLabel: 'Enrolling ...',
    reEnrollSkipLabel: 'Skip',
    reEnrollUpgradeLabel: 'Update',
    reEnrollingUpgradeLabel: 'Updating ...',
    referralListTitle: 'Suggested Pathways',
    successPromptHeader: 'Hey, Nice Work!',
    successPromptCompleteHeader: 'Great Job!',
    successPromptCompleteText: 'You are ready to mark this pathway completed, go for it!',
    successPromptCompleteButton: 'Complete',
    successPromptShareOrReferralCTA: 'Why not share your accomplishments or take the next step and enroll in another pathway?',
    successPromptShareCTA: 'Why not share your accomplishment?',
    successPromptShareTitle: 'Calcium Pathway Complete',
    successPromptShareText: 'I completed the {0} pathway with Calcium',
    successPromptShareDialogTitle: 'Share With Friends',
    taskListTitle: 'Tasks',
    taskListTitleCurrent: 'Current Tasks',
    taskListTitleRemaining: 'Optional Remaining Tasks',
    taskListTitleMissed: 'Missed Tasks',
    taskListTitleCompleted: 'Completed Tasks',
    taskListTitleFuture: 'Future Tasks',
    taskListNextAt: 'Next {0}',
    taskListDueBy: 'Due {0}',
    taskListCompleted: 'Completed {0}',
    tileLink: 'Explore Pathways',
    toastMessageComplete: 'Pathway Complete',
    toastMessageCompleting: 'Completing Pathway',
    toastMessageEnrolling: 'Accepting Pathway',
    toastMessageEnrolled: 'Pathway Accepted',
    toastMessageLeaving: 'Leaving Pathway',
    toastMessageLeft: 'Pathway Left',
    upgradeAvailableBanner: 'Update to the new version of this pathway.',
    upgradePromptHeader: 'Pathway Update Available',
    upgradePromptText: 'Your pathway has changed. Please tap the button below to continue.',
    upgradePromptCTA: 'Update Available',
    weightProgramAction: 'Log weight',
    weightProgramCallToAction: 'Log weight for today',
    weightProgramHistory: 'Weight History',
    weightProgramLinkWeightLoss: 'Weight Loss'
  },
  register: 'Register',
  registerRightCardCovid: {
    title: 'Calcium makes it easy to get started!',
    tick1: '2 week free trial for up to 10 team members.',
    tick2: 'No credit card required.​'
  },
  registerRightCardDash: {
    title: 'Keep clients engaged and get better insights!',
    subtitle: ' One Month Free Trial includes:',
    tick1: 'Up to 5 connected clients.',
    tick2: 'Access to exclusive Trainer Dashboard and a library of workouts.​',
    tick3: 'Connect to your client’s wearble devices.'
  },
  registerStep: {
    step1: 'Step 1',
    step1Title: 'Set Up Account',
    step2: 'Step 2',
    step2Title: 'Choose Your Plan',
    step3: 'Step 3',
    step3Title: 'Go to Your Dashboard'
  },
  registerAlreadyHaveAccount: 'Already have an account?',
  registerConfirmEmail: 'Thank you for creating your account. Please check your email and confirm your email address.',
  registerConfirmEmailSubtext:'Need a new confirmation email? Click here: ',
  registerAlreadyHaveCalciumAccount: 'Already have a Calcium account?',
  registerLearnMore: 'Learn more about {0}.',
  registerSignupCovid: 'Create Your Organization Account',
  registerSignupDash: 'Create Your Organization Account',
  registerSignupMember: 'Sign up here.',
  registerSignupProvider: 'Create Your Organization Account',
  confirmYourEmailAlert:'Confirm your email',
  registerThankYou: 'Thank You',
  reload: 'Try Again',
  resendVerification: 'Resend Confirmation Email',
  resetPasswordTitle: 'Reset Password',
  resetPasswordSubtitle: 'Please enter the email address associated with your account.',
  resetPasswordSuccessTitle: 'Thank You',
  resetPasswordSuccessSubtitle: 'Please check your email and click on the link to reset your password.',
  resetPasswordSuccessSignInText: 'Navigate to',
  saved: 'Saved!',
  saving: 'Saving...',
  closeConfirmationContent: 'If you exit now, you will lose all progress with this task. Are you sure you want to quit? Click the complete button at the end of this task to submit it.',
  backToTaskLabel: 'Back to task',
  closeTaskLabel: 'Close',
  sending: 'Sending...',
  sent: 'Sent!',
  selectPlaceholder: '- Select',
  sharing: {
    title: 'Share Profile',
    description: 'Invite another to share administration of your selected profile.',
    inviteEmail: 'Send Email Invitation',
    legalText: 'By clicking the above button you agree to share profile administration with another user who may be able to view and modify this profile\'s health information.',
    noPendingInvites: 'No Pending Profile Invites',
    pendingInvites: 'Pending Invites',
    invitedBy: '{0} would like you to manage {1}\'s profile.',
    incomingTitle: 'Incoming Share Invites',
    sharingTitle: 'Sharing',
    accepted: 'Accepted!',
    declined: 'Declined!',
    pending: 'Pending',
    inviteSentOn: 'Sent on: {0}',
    accessSince: 'Since: {0}',
    whoHasAccess: 'Who Has Access',
    shareToSeeData: 'Manage who in your circle can access and update information about this profile.',
    callToAction: 'Start Sharing Now',
    revoke: 'Stop Sharing',
    delete: 'Delete',
    revokeModalTitle: 'Revoke Access To Profile',
    revokeModalText: 'Are you sure you want to permanently revoke access to {0}?',
    inviteCancelModalTitle: 'Delete Profile Invite',
    inviteCancelModalText: 'Are you sure you want to delete the invite to {0}?',
    sharedWithYou: 'Shared With You',
    accepting: 'Accepting...',
    declining: 'Declining...',
    revokingAccess: 'Revoking access...',
    accessRevoked: 'Access revoked!',
    removeProfileModalTitle: 'Remove Profile',
    removeProfileModalText: 'Are you sure you want to remove the profile {0}?'
  },
  signingIn: 'Signing In...',
  signingOut: 'Signing Out...',
  simpleDateFormat: 'MM/DD/YY',
  simpleDateTimeFormat: 'MM/DD/YY h:mm A',
  simpleTimeFormat: 'h:mm A',
  socialShare: {
    fallbackCopied: 'Copied to Clipboard',
    desktopTitle: 'Copy Link',
    menuShareDialogTitle: 'Invite Friends',
    copyAppLink: 'Copy App Link',
    menuShareText: 'Join me on Calcium'
  },
  sponsored: 'Sponsored',
  states: {
    stateAL: 'Alabama',
    stateAK: 'Alaska',
    stateAS: 'American Samoa',
    stateAZ: 'Arizona',
    stateAR: 'Arkansas',
    stateCA: 'California',
    stateCO: 'Colorado',
    stateCT: 'Connecticut',
    stateDE: 'Delaware',
    stateDC: 'District Of Columbia',
    stateFM: 'Federated States Of Micronesia',
    stateFL: 'Florida',
    stateGA: 'Georgia',
    stateGU: 'Guam',
    stateHI: 'Hawaii',
    stateID: 'Idaho',
    stateIL: 'Illinois',
    stateIN: 'Indiana',
    stateIA: 'Iowa',
    stateKS: 'Kansas',
    stateKY: 'Kentucky',
    stateLA: 'Louisiana',
    stateME: 'Maine',
    stateMH: 'Marshall Islands',
    stateMD: 'Maryland',
    stateMA: 'Massachusetts',
    stateMI: 'Michigan',
    stateMN: 'Minnesota',
    stateMS: 'Mississippi',
    stateMO: 'Missouri',
    stateMT: 'Montana',
    stateNE: 'Nebraska',
    stateNV: 'Nevada',
    stateNH: 'New Hampshire',
    stateNJ: 'New Jersey',
    stateNM: 'New Mexico',
    stateNY: 'New York',
    stateNC: 'North Carolina',
    stateND: 'North Dakota',
    stateMP: 'Northern Mariana Islands',
    stateOH: 'Ohio',
    stateOK: 'Oklahoma',
    stateOR: 'Oregon',
    statePW: 'Palau',
    statePA: 'Pennsylvania',
    statePR: 'Puerto Rico',
    stateRI: 'Rhode Island',
    stateSC: 'South Carolina',
    stateSD: 'South Dakota',
    stateTN: 'Tennessee',
    stateTX: 'Texas',
    stateUT: 'Utah',
    stateVT: 'Vermont',
    stateVI: 'Virgin Islands',
    stateVA: 'Virginia',
    stateWA: 'Washington',
    stateWV: 'West Virginia',
    stateWI: 'Wisconsin',
    stateWY: 'Wyoming'
  },
  tileActivityTitle: 'Activity - 7 days',
  tileActivityUnits: 'minutes',
  tileBodyMeasurementsTitle: 'BMI',
  noBodyMeasurementsData: 'Add your height and weight',
  tileBodyMeasurementsUnits: '(Overweight)',
  tileMedicationsLabel: 'Synthroid',
  tileMedicationsTitle: 'Medications',
  tileMedicationsUnits: 'hours since last taken',
  timeline: {
    title: '7 Day Timeline',
    noData: {
      header: 'Your Timeline',
      message: 'See all your health data from the last 7 days all in one place.',
      callToAction: 'Try adding your temperature to get started.',
      buttonText: 'Add Temperature',
      linkText1: 'Sync Health Records',
      linkText2: 'Connect a Device'
    },
    filtersApplied: 'Filters Applied.',
    applyFilters: 'Apply',
    clearFilters: 'Clear'
  },
  tooltipAddMedication: 'Add Medication',
  tryAgain: 'Click here to try again',
  units: {
    unknown: 'N/A',
    beatsPerMinute: 'bpm',
    degreesCelsius: 'ºC',
    degreesFahrenheit: 'ºF',
    centimeters: 'cm',
    inches: 'in',
    kilograms: 'kg',
    pounds: 'lbs',
    meters: 'm',
    steps: 'steps',
    percent: '%',
    internationalUnit: 'IU',
    litersPerMinute: 'l/min',
    gramsPerDeciliter: 'g/dL',
    mgdL: 'mg/dL',
    millimolesPerLiter: 'mmol/L',
    millimetersOfMercury: 'mmHg',
    cubicMetersPerSecond: 'm³/s',
    pascal: 'Pa'
  },
  userAgent: 'User Agent',
  validationBirthdate: 'Birthdate is invalid',
  validationDate: 'Date is invalid',
  validationDateInPast: 'Date must be in the past',
  validationDateInRange: 'Date is not in the valid range',
  validationInvalidEmail: 'Must be valid email',
  validationInvalidHeight: 'Height is too large',
  validationMaxLength: 'character length max',
  validationMaxCharLength: '{0} character length max',
  validationMaxValue: 'Must be at most',
  validationMinLength: 'character length minimum',
  validationMinValue: 'Must be at least',
  validationNumber: 'Must be a number',
  validationRequired: 'Required',
  validationSsn4: 'Exactly four digits are required',
  validationTimeLessThan1Day: 'Time must be less than 24 hours',
  verifyEmailBody: 'Certain pages cannot be accessed until your email address is confirmed. Please check your email and click on the link to confirm your email address.',
  verifyEmailHeader: 'Please Confirm Your Email Address',
  verifyEmailResentHeader: 'New Email Confirmation Sent',
  verifyEmailBodyMobile: 'If you have confirmed your email and still see this message please restart the app.',
  verifyEmailBodyBrowser: 'If you have confirmed your email and still see this message please refresh the browser.',
  verifyEmailAlertMessage:'Before you sign in, you must confirm your email. An email has been sent to the email you used to create your account.',
  vitalsHeader: 'Vitals',
  welcome: 'Welcome',
  signUpFlow: {
    subHead: 'Sign up for a full one-week access pass to our platform.',
    steps: {
      step1: {
        step1Title: 'Step 1',
        step1Tag: 'Create account',
        formLabels: {
          firstName: 'First Name',
          lastName: 'Last Name',
          phoneNumber: 'Phone Number',
          solution: 'Solution',
          email: 'Email (username)',
          password: 'Password ({0} character minimum)'
        },
        solutionOptions: {
          infectiousDiseaseCOVID19: 'Infectious Disease (COVID-19)'
        },
      },
      step2: {
        step2Title: 'Step 2',
        formLabels:{
          companyName: 'Company Name',
          numberOfEmployees: 'Number of Employees',
          numberOfPatients: 'Number of Patients',
          companyLocation: 'Company Location',
          howDidyouHearAboutCalcium: 'How did you hear about Calcium?',
        },
        step2Tag: 'Start your free trial',
        btnText: 'Start your free trial'
      }
    },
    validationMessages:{
      required: 'This field is required.',
      invalidPhoneNumber: 'Please enter valid phone number.',
      invalidPassword: 'character length minimum.',
      invalidEmail: 'Please enter valid email.',
      emailExists:'This email already exists.',
      error: 'An error occurred. Please try again.',
      invalidEmployeeNumbers: 'Please enter valid employee numbers.',
      invalidPatientNumbers: 'Please enter valid patient numbers.'
    },
    alreadyHaveAccount: 'Already have an account?',
    linkSignIn: 'Sign in'
  },
  verifyEmail:{
    confirm: 'Verify your email',
    sentCode: 'Sent a 4 digit code to {0}',
    contact: 'Contact us',
    enterCode: 'Enter the code',
    trouble: 'Having trouble?',
    verifying: 'Verifying...',
    verified: 'Verified',
    resend: 'Resend Code',
    error: 'Invalid verification code. Please verify the code in your email and enter again',
    failed: 'Verification failed',
    verifySuccess:'Verification success',
    successMsgBanner:'Your email is successfully verified',
    successMsgDescription:'You can now signin with {0}',
    redirectMsg:'You will be redirected in 10 seconds'
  }
};
