import styleVars from 'styles/_variables.scss';

export const addCallbackEvent = (event, callback) => {
  window.Beacon('on', event, callback);
};

export const destroy = () => {
  window.Beacon('destroy');
};

export const initialize = (helpScoutBeaconId) => {
  window.Beacon('init', helpScoutBeaconId);
};

export const logout = () => {
  window.Beacon('logout');
};

export const open = () => {
  window.Beacon('open');
};

export const prefillUserInformation = (name, email) => {
  window.Beacon('prefill', {
    name: name,
    email: email
  });
};
export const setContent = (subject, text) => {
  window.Beacon('prefill', {
    subject: subject,
    text: text
  });
  window.Beacon('navigate', '/ask/');
};
export const navigate = (route) => {
  window.Beacon('navigate', route);
};
export const setDefaultConfiguration = () => {
  window.Beacon('config', {
    docsEnabled: true,
    hideFABOnMobile: true,
    color: styleVars.colorTeal,
    enablePreviousMessages: false,
    display: {
      style: 'manual',
      zIndex: 190
    }
  });
};

export const setIdentity = (name, email) => {
  window.Beacon('identify', {
    name: name,
    email: email
  });
};

export const prefillSubjectAndMessage = (subject,message) => {
  window.Beacon('prefill', {
    subject: subject,
    text: message
  });
};

export const navigateAsk = ()=> {
  window.Beacon('navigate', '/ask/');
};
