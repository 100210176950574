import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'shared/icon/Icon';
import './data-row.scss';

export class DataRow extends React.Component {
  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
    this.getItemDataClassName = this.getItemDataClassName.bind(this);
  }

  getClassName() {
    const { selected } = this.props;
    if (selected) {
      return 'data-row selected';
    }
    return 'data-row';
  }

  getItemDataClassName() {
    const { handleRowClick, dataSectionWrapper } = this.props;
    const wrappedClass = dataSectionWrapper ? 'wrapped' : '';

    if (handleRowClick) {
      return `item-data item-actions ${wrappedClass}`;
    }

    return `item-data ${wrappedClass}`;
  }

  render() {
    const {
      head,
      subhead,
      timestamp,
      handleDelete,
      handleRowClick,
      id,
      notes,
      visual,
      deleteVisual,
      dataSectionWrapper
    } = this.props;
    const className = this.getClassName();
    const itemDataClassName = this.getItemDataClassName();
    const wrapper = dataSectionWrapper ? dataSectionWrapper : (section) => section;

    const dataSection = (
      <React.Fragment>
        <section className="item-flex-section">
          {visual && <div className="item-flex-left">{visual}</div>}
          <div className="item-flex-main">
            <span className="item-data-head">
              {head}
            </span>
            {subhead && <span className="item-data-subhead">{subhead}</span>}
          </div>
          <div className="item-flex-right">
            {timestamp}
          </div>
        </section>
        {notes && <section className="item-full-width-section">{notes}</section>}
      </React.Fragment>
    );

    return (
      <div id={`data-row-${id}`} className={className}>
        <div onClick={handleRowClick} className={itemDataClassName}>
          {wrapper(dataSection)}
        </div>
        {handleDelete &&
          <div className="data-actions" onClick={handleDelete}>
            { deleteVisual ? deleteVisual : (
              <button
                id={`delete-button-${id}`}
                data-testid={`delete-button-${id}`}
                type="button">
                <Icon symbol="delete" />
              </button>
            )}
          </div>}
      </div>
    );
  }
}

DataRow.propTypes = {
  head: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  subhead: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  visual: PropTypes.element,
  deleteVisual: PropTypes.element,
  timestamp: PropTypes.string,
  notes: PropTypes.string,
  selected: PropTypes.bool,
  id: PropTypes.string.isRequired,
  handleRowClick: PropTypes.func,
  handleDelete: PropTypes.func,
  dataSectionWrapper: PropTypes.func
};

DataRow.defaultProps = {
  selected: false
};

export default DataRow;
