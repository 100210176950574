/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
  }

  getClassName() {
    const { color, className } = this.props;

    return ['button', color, className]
      .filter(x => x)
      .join(' ');
  }

  render() {
    const { id, submit, children, ...rest } = this.props;
    const type = submit ? 'submit' : 'button';
    const className = this.getClassName();

    return (
      <button
        {...rest}
        id={id}
        type={type}
        className={className}>
        {children}
      </button>
    );
  }
}

Button.displayName = 'Button Component';
Button.propTypes = {
  id: PropTypes.string.isRequired,
  submit: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node
};

export default Button;
