/* eslint-disable max-len */
import React from 'react';
import './hc-logo-inline.scss';

class HcLogoInline extends React.Component {
  render() {
    return (
      <svg id="logoInline" viewBox="0 0 762.83 144" xmlns="http://www.w3.org/2000/svg">
        <g data-name="Art">
          <path d="M226,44.82V98H214.41V77H191.87V98H180.28V44.82h11.59V65.88h22.54V44.82Z" className="text" />
          <path d="M269.49,78.24a29.62,29.62,0,0,1-.31,4.38H242.49a9,9,0,0,0,3,4.86,8.51,8.51,0,0,0,5.4,1.72,10.22,10.22,0,0,0,5.44-1.45A9.75,9.75,0,0,0,260,84l8.53,4.86a18.37,18.37,0,0,1-7.39,7.55,21,21,0,0,1-10.61,2.85,18.64,18.64,0,0,1-16.82-10.13,23.66,23.66,0,0,1,0-21.21,18.81,18.81,0,0,1,7-7.43,19.13,19.13,0,0,1,10.1-2.7,17.87,17.87,0,0,1,12.6,4.69q5.08,4.7,5.95,12.76C269.44,76.44,269.49,77.46,269.49,78.24Zm-24.26-8.69a9,9,0,0,0-2.74,4.93h15.65a7.5,7.5,0,0,0-2.5-4.89,7.74,7.74,0,0,0-5.24-1.84A7.52,7.52,0,0,0,245.23,69.55Z" className="text" />
          <path d="M300.15,59.85a15.75,15.75,0,0,1,6.22,5.95,16.73,16.73,0,0,1,2.27,8.68V98h-11v-4a18.72,18.72,0,0,1-5.75,3.76,17.56,17.56,0,0,1-6.85,1.4,12.85,12.85,0,0,1-6-1.44,11.16,11.16,0,0,1-4.42-4.15,11.93,11.93,0,0,1-1.64-6.3,11.12,11.12,0,0,1,3.4-8.37c2.27-2.2,5.6-3.73,10-4.62l11-2.12A6.33,6.33,0,0,0,295,68.73a6.75,6.75,0,0,0-4.11-1.29,9.49,9.49,0,0,0-4.93,1.45,14.31,14.31,0,0,0-4.15,3.72l-7.9-6a22.35,22.35,0,0,1,17.61-8.92A18.06,18.06,0,0,1,300.15,59.85Zm-7.2,29a9.79,9.79,0,0,0,3.44-3.16,7.43,7.43,0,0,0,1.29-4.15v-1l-9.23,1.88A6.25,6.25,0,0,0,285,84,3.67,3.67,0,0,0,284,86.54a3.19,3.19,0,0,0,1.17,2.5,4.85,4.85,0,0,0,3.29,1A9.06,9.06,0,0,0,293,88.84Z" className="text" />
          <path d="M326.12,43.26V98H314.38V43.26Z" className="text" />
          <path d="M349.61,69.08v13.7a5.2,5.2,0,0,0,1,3.6,4.31,4.31,0,0,0,3.29,1.1h4.69V98h-7q-6.88,0-10.1-3.21t-3.2-10.09V69.08h-7V58.91h7V49.52L349.61,46V58.91h9V69.08Z" className="text" />
          <path d="M395.89,60a15.32,15.32,0,0,1,5.2,6.37,21.11,21.11,0,0,1,1.84,8.89V98H391.58V77.61a10.25,10.25,0,0,0-1.95-6.49,6.25,6.25,0,0,0-5.17-2.51,7.18,7.18,0,0,0-5.56,2.55,9.33,9.33,0,0,0-2.26,6.45V98H365.37V43.26h11.27V63.14a14.72,14.72,0,0,1,5.08-4,14.42,14.42,0,0,1,6.42-1.45A13.2,13.2,0,0,1,395.89,60Z" className="text" />
          <path d="M444.7,57.42a16.32,16.32,0,0,0-16.55-.19,15.51,15.51,0,0,0-5.79,5.91,17.36,17.36,0,0,0,0,16.59,15.65,15.65,0,0,0,5.79,5.91,15.87,15.87,0,0,0,8.14,2.15,15.62,15.62,0,0,0,8.41-2.35,15.34,15.34,0,0,0,5.83-6.57l10.1,5.94a27.75,27.75,0,0,1-24.34,14.48,27.8,27.8,0,0,1-24.18-13.85,28,28,0,0,1,0-28,27.85,27.85,0,0,1,24.18-13.85,27.33,27.33,0,0,1,14.21,3.88,26.77,26.77,0,0,1,10.13,10.6l-10.1,6A15.36,15.36,0,0,0,444.7,57.42Z" className="text" />
          <path d="M496.54,60a15.34,15.34,0,0,1,5.21,6.37,21.27,21.27,0,0,1,1.84,8.89V98H492.24V77.61a10.2,10.2,0,0,0-2-6.49,6.22,6.22,0,0,0-5.16-2.51,7.16,7.16,0,0,0-5.56,2.55,9.34,9.34,0,0,0-2.27,6.45V98H466V43.26h11.27V63.14a14.85,14.85,0,0,1,5.09-4,14.39,14.39,0,0,1,6.42-1.45A13.15,13.15,0,0,1,496.54,60Z" className="text" />
          <path d="M536,59.85a15.71,15.71,0,0,1,6.23,5.95,16.73,16.73,0,0,1,2.27,8.68V98h-11v-4a18.84,18.84,0,0,1-5.75,3.76,17.56,17.56,0,0,1-6.85,1.4,12.82,12.82,0,0,1-6-1.44,11.16,11.16,0,0,1-4.42-4.15,11.93,11.93,0,0,1-1.64-6.3,11.12,11.12,0,0,1,3.4-8.37c2.27-2.2,5.6-3.73,10-4.62l11-2.12a6.38,6.38,0,0,0-2.39-3.48,6.75,6.75,0,0,0-4.11-1.29,9.49,9.49,0,0,0-4.93,1.45,14.31,14.31,0,0,0-4.15,3.72l-7.9-6a22.35,22.35,0,0,1,17.61-8.92A18,18,0,0,1,536,59.85Zm-7.2,29a9.73,9.73,0,0,0,3.45-3.16,7.43,7.43,0,0,0,1.29-4.15v-1l-9.24,1.88a6.22,6.22,0,0,0-3.4,1.6,3.68,3.68,0,0,0-1.06,2.55,3.17,3.17,0,0,0,1.18,2.5,4.84,4.84,0,0,0,3.28,1A9,9,0,0,0,528.83,88.84Z" className="text" />
          <path d="M603.68,60a15,15,0,0,1,5.05,6.37,22,22,0,0,1,1.76,8.89V98H599.14V76.83a9.62,9.62,0,0,0-1.68-6.06,5.49,5.49,0,0,0-4.58-2.16,6.46,6.46,0,0,0-5,2.2,8.61,8.61,0,0,0-2,6V98H574.57V76.83a9.56,9.56,0,0,0-1.72-6,5.59,5.59,0,0,0-4.62-2.2,6.37,6.37,0,0,0-4.89,2.2,8.61,8.61,0,0,0-2,6V98H550V58.91h11.35v5a15.31,15.31,0,0,1,4.82-4.62,11.73,11.73,0,0,1,6.06-1.64,11.52,11.52,0,0,1,6.38,1.84,14,14,0,0,1,4.66,5,14.14,14.14,0,0,1,5.28-5A15,15,0,0,1,596,57.66,12.82,12.82,0,0,1,603.68,60Z" className="text" />
          <path d="M648.77,60.4a18.43,18.43,0,0,1,6.42,7.35,23,23,0,0,1,2.35,10.41,23.67,23.67,0,0,1-2.39,10.68,19.28,19.28,0,0,1-6.5,7.6,15.77,15.77,0,0,1-9,2.77,14.59,14.59,0,0,1-6.38-1.36,14.31,14.31,0,0,1-4.82-3.72v19.56H617.08V58.91h11.34v4.15a13.06,13.06,0,0,1,4.78-3.92,15,15,0,0,1,6.57-1.4A16,16,0,0,1,648.77,60.4Zm-5.36,25.08A10,10,0,0,0,646,78.4a9.79,9.79,0,0,0-2.54-7,8.12,8.12,0,0,0-6.14-2.7,8.33,8.33,0,0,0-6.22,2.74,9.79,9.79,0,0,0-2.63,7A9.81,9.81,0,0,0,631,85.6a8.32,8.32,0,0,0,6.18,2.66A8.11,8.11,0,0,0,643.41,85.48Z" className="text" />
          <path d="M663.4,52.77a6,6,0,0,1-2-4.66,5.88,5.88,0,0,1,2-4.62,7.17,7.17,0,0,1,4.93-1.8,6.87,6.87,0,0,1,4.81,1.84,6,6,0,0,1,2,4.58,6.07,6.07,0,0,1-2,4.66,6.87,6.87,0,0,1-4.81,1.84A7.11,7.11,0,0,1,663.4,52.77Zm10.72,6.14V98H662.38V58.91Z" className="text" />
          <path d="M689.45,96.44a20.74,20.74,0,0,1,10.48-38.7,20.11,20.11,0,0,1,10.37,2.77,20.81,20.81,0,0,1,7.52,7.56,20.93,20.93,0,0,1,0,20.81,20.65,20.65,0,0,1-7.52,7.56,20.11,20.11,0,0,1-10.37,2.77A20.45,20.45,0,0,1,689.45,96.44Zm16.94-11.08a10.47,10.47,0,0,0,0-13.81,8.51,8.51,0,0,0-6.5-2.78,8.71,8.71,0,0,0-6.61,2.78,10.46,10.46,0,0,0,0,13.85,8.71,8.71,0,0,0,6.61,2.78A8.47,8.47,0,0,0,706.39,85.36Z" className="text" />
          <path d="M755.78,60A15.34,15.34,0,0,1,761,66.38a21.27,21.27,0,0,1,1.84,8.89V98H751.48V77.61a10.2,10.2,0,0,0-2-6.49,6.22,6.22,0,0,0-5.16-2.51,7.16,7.16,0,0,0-5.56,2.55,9.34,9.34,0,0,0-2.27,6.45V98H725.26V58.91h11.27v4.23a14.76,14.76,0,0,1,5.09-4A14.39,14.39,0,0,1,748,57.66,13.15,13.15,0,0,1,755.78,60Z" className="text" />
          <path d="M71.08,54h-.15a7.54,7.54,0,0,1-6.84-4.77L48.85,10.24a7.5,7.5,0,0,1,14-5.47l8.66,22.11L81.3,4.49a7.5,7.5,0,1,1,13.74,6L78,49.47A7.51,7.51,0,0,1,71.08,54" className="teal" />
          <path d="M88.17,144a7.51,7.51,0,0,1-7-4.77l-8.66-22.11L62.7,139.51a7.5,7.5,0,1,1-13.74-6L66.05,94.53a7.5,7.5,0,0,1,13.86.28l15.24,38.95a7.51,7.51,0,0,1-7,10.24" className="teal" />
          <path d="M53.28,62.09a7.55,7.55,0,0,1-3-.63L12,44.7A7.5,7.5,0,0,1,18,31l21.75,9.52L30.84,17.69a7.5,7.5,0,1,1,14-5.45L60.28,51.86a7.51,7.51,0,0,1-7,10.23" className="magenta" />
          <path d="M106.17,136.54a7.5,7.5,0,0,1-7-4.78L83.72,92.14a7.51,7.51,0,0,1,10-9.6L132,99.3a7.5,7.5,0,0,1-6,13.75l-21.75-9.52,8.88,22.78a7.49,7.49,0,0,1-7,10.23" className="magenta" />
          <path d="M7.51,95.67A7.5,7.5,0,0,1,4.77,81.18l22.11-8.65L4.49,62.7a7.5,7.5,0,1,1,6-13.74l39,17.09a7.5,7.5,0,0,1-.28,13.86L10.24,95.16a7.5,7.5,0,0,1-2.73.51" className="blue" />
          <path d="M136.49,95.67a7.46,7.46,0,0,1-3-.63L94.53,78a7.51,7.51,0,0,1,.28-13.86l38.95-15.25a7.51,7.51,0,0,1,5.47,14l-22.11,8.65,22.39,9.83a7.5,7.5,0,0,1-3,14.37" className="blue" />
          <path d="M37.82,136.54A7.51,7.51,0,0,1,31,126l9.52-21.75-22.78,8.88a7.5,7.5,0,1,1-5.45-14L51.86,83.72a7.51,7.51,0,0,1,9.6,10L44.7,132a7.5,7.5,0,0,1-6.88,4.5" className="yellow" />
          <path d="M89.42,60.79a7.5,7.5,0,0,1-6.88-10.51L99.3,12a7.5,7.5,0,1,1,13.75,6l-9.52,21.75,22.78-8.88a7.5,7.5,0,1,1,5.45,14L92.14,60.28a7.58,7.58,0,0,1-2.72.51" className="yellow" />
          <path d="M60.7,55.54a8.17,8.17,0,0,0,.09-.85c0-.26,0-.52-.06-.79s0-.45-.06-.67a8.18,8.18,0,0,0-.41-1.42,7.47,7.47,0,0,0-4-4.11L39.56,40.46l6.76,16.91a7.48,7.48,0,0,0,4.2,4.17,7.84,7.84,0,0,0,1,.33L52,62a7.39,7.39,0,0,0,1.31.14h.05A7.44,7.44,0,0,0,54.55,62l.37-.08a8.14,8.14,0,0,0,.93-.27l.35-.13a7.1,7.1,0,0,0,1.19-.63l.15-.1a8.85,8.85,0,0,0,.89-.73l.28-.28a7.7,7.7,0,0,0,.63-.76l.21-.28a7.27,7.27,0,0,0,1.08-2.56c0-.2,0-.41.07-.61" className="magenta-dark" />
          <path d="M83.31,88.42a8.53,8.53,0,0,0-.1.85c0,.26,0,.53.06.79s0,.45.06.67a6.54,6.54,0,0,0,.4,1.42,7.45,7.45,0,0,0,4,4.14l16.67,7.31L97.69,86.66a7.42,7.42,0,0,0-4.17-4.18,8.54,8.54,0,0,0-1-.34l-.38-.09a7.4,7.4,0,0,0-1.32-.14h0a7.3,7.3,0,0,0-1.23.11l-.38.08a7.45,7.45,0,0,0-.92.27l-.36.13a7.06,7.06,0,0,0-1.19.62l-.15.1a7.78,7.78,0,0,0-.89.72l-.28.28a7.79,7.79,0,0,0-.64.76l-.21.28a7.57,7.57,0,0,0-.63,1.15,8,8,0,0,0-.46,1.41c0,.2,0,.4-.07.6" className="magenta-dark" />
          <path d="M52.33,68.34a5.78,5.78,0,0,0-.53-.67c-.18-.18-.4-.34-.6-.51s-.33-.31-.52-.43a7.44,7.44,0,0,0-7-.8L26.73,72.62,43.46,79.8a7.51,7.51,0,0,0,5.92,0,8.12,8.12,0,0,0,1-.51l.33-.21a7.63,7.63,0,0,0,1-.83l0,0s0,0,0,0a6.73,6.73,0,0,0,.79-1,3.53,3.53,0,0,0,.21-.32,6.85,6.85,0,0,0,.46-.84c.06-.12.11-.23.16-.35a7.57,7.57,0,0,0,.4-1.28,1.13,1.13,0,0,0,0-.18,7.35,7.35,0,0,0,.12-1.14v-.39a6.39,6.39,0,0,0-.1-1,2.11,2.11,0,0,0-.05-.35,7.91,7.91,0,0,0-.37-1.26,6.77,6.77,0,0,0-.68-1.31,5.71,5.71,0,0,0-.38-.48" className="blue-dark" />
          <path d="M91.58,75.6a5.78,5.78,0,0,0,.53.67,6.05,6.05,0,0,0,.6.52c.17.14.33.3.51.43a7.36,7.36,0,0,0,1.29.72,7.47,7.47,0,0,0,5.73.12l17-6.62-16.7-7.25a7.41,7.41,0,0,0-5.91,0,6.44,6.44,0,0,0-1,.5l-.33.21a6.93,6.93,0,0,0-1,.83l0,0s0,0,0,0a7.24,7.24,0,0,0-.79.95,3.53,3.53,0,0,0-.21.32,8.6,8.6,0,0,0-.47.84c0,.12-.11.23-.15.34a7,7,0,0,0-.41,1.28,1.13,1.13,0,0,0,0,.18A7.35,7.35,0,0,0,90,70.82c0,.13,0,.26,0,.39a8.28,8.28,0,0,0,.09,1c0,.11,0,.23,0,.34a8,8,0,0,0,.37,1.27,7.7,7.7,0,0,0,.67,1.31,5.71,5.71,0,0,0,.38.48" className="blue-dark" />
          <path d="M68.3,91.65a6.76,6.76,0,0,0-.66.54,6,6,0,0,0-.51.6,5.49,5.49,0,0,0-.44.52,7.49,7.49,0,0,0-.8,7l6.7,16.94,7.17-16.74a7.4,7.4,0,0,0,0-5.91,7,7,0,0,0-.5-1L79,93.3a7.6,7.6,0,0,0-.84-1s0,0,0,0,0,0,0,0a7.24,7.24,0,0,0-1-.79l-.32-.21a7.66,7.66,0,0,0-.85-.46l-.34-.16a7.57,7.57,0,0,0-1.28-.4l-.18,0a7.48,7.48,0,0,0-1.14-.12c-.14,0-.27,0-.4,0a7.93,7.93,0,0,0-1,.09l-.35,0a7.62,7.62,0,0,0-1.26.37,8.21,8.21,0,0,0-1.32.68c-.17.11-.32.26-.48.38" className="teal-dark" />
          <path d="M75.57,52.41c.23-.17.46-.34.67-.53s.34-.4.51-.6a5.49,5.49,0,0,0,.44-.52,6.93,6.93,0,0,0,.72-1.28A7.47,7.47,0,0,0,78,43.75l-6.62-17L64.15,43.48a7.47,7.47,0,0,0,0,5.92,6.83,6.83,0,0,0,.5,1l.2.34a9.21,9.21,0,0,0,.84,1h0s0,0,0,0a8.2,8.2,0,0,0,1,.8l.33.2a5.68,5.68,0,0,0,.84.47l.34.16a8.65,8.65,0,0,0,1.28.41l.18,0a6.32,6.32,0,0,0,1.14.13h.39a8.28,8.28,0,0,0,1-.09l.35,0a7,7,0,0,0,1.26-.36,8.17,8.17,0,0,0,1.32-.67c.17-.11.32-.26.48-.38" className="teal-dark" />
          <path d="M55.49,83.32a8.17,8.17,0,0,0-.85-.09c-.26,0-.52,0-.78.06s-.46,0-.68.06a7.6,7.6,0,0,0-1.42.41,7.47,7.47,0,0,0-4.11,4l-7.23,16.71,16.9-6.76a7.48,7.48,0,0,0,4.17-4.2,6.6,6.6,0,0,0,.33-1l.09-.39A7.38,7.38,0,0,0,62,90.75v0a7.32,7.32,0,0,0-.12-1.23c0-.13-.05-.25-.08-.37a8.14,8.14,0,0,0-.27-.93,3.62,3.62,0,0,0-.13-.35,7.88,7.88,0,0,0-.62-1.19l-.11-.15a6.91,6.91,0,0,0-.73-.89l-.27-.28a9.2,9.2,0,0,0-.77-.63l-.28-.21a8.56,8.56,0,0,0-1.15-.63,7.68,7.68,0,0,0-1.41-.45c-.2,0-.41,0-.61-.07" className="yellow-dark" />
          <path d="M88.37,60.71a8.53,8.53,0,0,0,.85.1c.26,0,.53,0,.79-.06s.45,0,.68-.06a7.26,7.26,0,0,0,1.41-.4,7.45,7.45,0,0,0,4.14-4l7.31-16.67L86.61,46.33a7.42,7.42,0,0,0-4.18,4.17,8.54,8.54,0,0,0-.34,1l-.09.38a8.45,8.45,0,0,0-.14,1.32v0A7.3,7.3,0,0,0,82,54.53c0,.13.06.25.08.38a7.45,7.45,0,0,0,.27.92,3.62,3.62,0,0,0,.13.35,7.54,7.54,0,0,0,.62,1.2l.1.14a8.11,8.11,0,0,0,.72.9l.28.28a7.79,7.79,0,0,0,.76.64l.28.21a7.57,7.57,0,0,0,1.15.63,8.29,8.29,0,0,0,1.41.46c.2,0,.4,0,.6.07" className="yellow-dark" />
        </g>
      </svg>
    );
  }
}

HcLogoInline.displayName = 'HcLogoInline';
HcLogoInline.propTypes = {};
HcLogoInline.defaultProps = {};

export default HcLogoInline;
