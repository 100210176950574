import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkWrapper, NonAuthenticatedPageWall, ErrorSection } from 'shared';
import local from 'services/localization/local';
import { resetPasswordReset } from 'identity/actions';
import ResetPasswordForm from './ResetPasswordForm';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

export class ResetPassword extends Component {
  componentWillUnmount() {
    this.props.resetPasswordReset();
  }

  render() {
    const { error, success } = this.props;
    const navAction = <LinkWrapper id='already-have-account-sign-in-nav' className='button gray' to='/'>
      {local.linkSignIn}
    </LinkWrapper>;

    if (success) {
      return (
        <NonAuthenticatedPageWall id='reset-password-confirmation-page' className='signin-content' titleTabText={local.resetPasswordSuccessTitle} navAction={navAction}>
          <h3 className='text-center'>
            {local.resetPasswordSuccessTitle}
          </h3>
          <small>
            {local.resetPasswordSuccessSubtitle}
          </small>
          <br />
          <LinkWrapper to='/' id='link-to-sign-in' className='button blue w-100 my-3'>{local.linkSignIn}</LinkWrapper>
        </NonAuthenticatedPageWall>
      );
    }

    return (
      <div>
        <Header isSignIn></Header>
        <div className='d-flex signin-section'>
          <div className='flex-fill'>
            <NonAuthenticatedPageWall id='reset-password-page' titleTabText={local.resetPasswordTitle} navAction={navAction}>
              <h3>
                {local.resetPasswordTitle}
              </h3>
              <ErrorSection error={error} />
              <ResetPasswordForm />
            </NonAuthenticatedPageWall>
          </div>
          <div className='flex-fill image-section'>
            <img src='/static/images/logo-white.png' />
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  error: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  // actions
  resetPasswordReset: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  error: state.identity.resetPasswordError,
  success: state.identity.resetPasswordSuccess
});

const actions = { resetPasswordReset };

export default connect(mapStateToProps, actions)(ResetPassword);
