import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, change, getFormSubmitErrors, formValueSelector } from 'redux-form';
import { TextField, ErrorField } from 'shared/form-fields';
import local from 'services/localization/local';
import * as normalizers from 'services/fields/field-normalizers';

class DateTextFields extends React.Component {
  constructor(props) {
    super(props);
    this.onUpdate = this.onUpdate.bind(this);
  }

  componentDidUpdate(prevProps) {
    this.onUpdate(prevProps);
  }

  onUpdate(prevProps) {
    const { year, month, day, submitErrors, actions } = this.props;

    // If a submission error was thrown with errors for the date, reset the
    // error state of all three simultanesouly when any of the fields have been modified
    if (submitErrors.dateError) {
      if (prevProps.year !== year || prevProps.month !== month || prevProps.day !== day) {
        actions.changeYear(year);
        actions.changeMonth(month);
        actions.changeDay(day);
        actions.resetDateError();
      }
    }
  }

  render() {
    const { label } = this.props;

    return (
      <React.Fragment>
        <div className="form-row combined-fields">
          {label && (
            <label className="form-label" htmlFor="date">{label}</label>
          )}
          <div className="fieldWrapper">
            <Field
              name="month"
              type="tel"
              label={local.formMonthLabel}
              component={TextField}
              normalize={normalizers.numMaxLength2} />
            <Field
              name="day"
              type="tel"
              label={local.formDayLabel}
              component={TextField}
              normalize={normalizers.numMaxLength2} />
            <Field
              name="year"
              type="tel"
              label={local.formYearLabel}
              component={TextField}
              normalize={normalizers.numMaxLength4} />
          </div>
          <Field
            name="dateError"
            component={ErrorField} />
        </div>
      </React.Fragment>
    );
  }
}

DateTextFields.propTypes = {
  actions: PropTypes.shape({
    changeYear: PropTypes.func.isRequired,
    changeMonth: PropTypes.func.isRequired,
    changeDay: PropTypes.func.isRequired,
    resetDateError: PropTypes.func.isRequired
  }).isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  formName: PropTypes.string.isRequired
};

function mapStateToProps(state, props) {
  const selector = formValueSelector(props.formName);
  const { year, month, day } = selector(state, 'year', 'month', 'day');
  return {

    submitErrors: getFormSubmitErrors(props.formName)(state),
    year,
    month,
    day
  };
}

function mapDispatchToProps(dispatch, props) {
  const actions = {
    changeYear: (year) => change(props.formName, 'year', year),
    changeMonth: (month) => change(props.formName, 'month', month),
    changeDay: (day) => change(props.formName, 'day', day),
    resetDateError: () => change(props.formName, 'dateError', undefined)
  };

  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(DateTextFields);
