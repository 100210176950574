export const IDENTITY_SIGNINREQUIRED = 'IDENTITY_SIGNINREQUIRED';
export const IDENTITY_SIGNINREQUESTED = 'IDENTITY_SIGNINREQUESTED';
export const IDENTITY_SIGNINSUCCESS = 'IDENTITY_SIGNINSUCCESS';
export const IDENTITY_SIGNINERROR = 'IDENTITY_SIGNINERROR';
export const IDENTITY_UNVERIFIEDEMAIL = 'IDENTITY_UNVERIFIFEDEMAIL';
export const IDENTITY_UNVERIFIEDEMAILRESET = 'IDENTITY_UNVERIFIFEDEMAILRESET';
export const IDENTITY_REFRESH_USERINFO_SUCCESS = 'IDENTITY_REFRESH_USERINFO_SUCCESS';
export const IDENTITY_UNVERIFIEDEMAIL_CODE = 'IDENTITY_UNVERIFIFEDEMAIL_CODE';

export const IDENTITY_REQUESTED = 'IDENTITY_REQUESTED';
export const IDENTITY_LOADED = 'IDENTITY_LOADED';
export const IDENTITY_LOADERROR = 'IDENTITY_LOADERROR';

export const IDENTITY_REGISTERUSER = 'IDENTITY_REGISTERUSER';
export const IDENTITY_REGISTERUSER_SUCCESS = 'IDENTITY_REGISTERUSER_SUCCESS';
export const IDENTITY_REGISTERUSER_ERROR = 'IDENTITY_REGISTERUSER_ERROR';

export const IDENTITY_RESET_PASSWORD = 'IDENTITY_RESET_PASSWORD';
export const IDENTITY_RESET_PASSWORD_SUCCESS = 'IDENTITY_RESET_PASSWORD_SUCCESS';
export const IDENTITY_RESET_PASSWORD_ERROR = 'IDENTITY_RESET_PASSWORD_ERROR';
export const IDENTITY_RESET_PASSWORD_RESET = 'IDENTITY_RESET_PASSWORD_RESET';

export const IDENTITY_UPDATEPREFERENCE = 'IDENTITY_UPDATEPREFERENCE';

export const IDENTITY_DISPLAY_VERIFICATION_SENT = 'IDENTITY_DISPLAY_VERIFICATION_SENT';

export const IDENTITY_VERIFY_CODE = 'IDENTITY_VERIFY_CODE';
export const IDENTITY_SIGNOUT = 'IDENTITY_SIGNOUT';
export const IDENTITY_SIGNUP_LOADING = 'IDENTITY_SIGNUP_LOADING';

export const IDENTITY_IS_EMAIL_EXISTS = 'IDENTITY_IS_EMAIL_EXISTS';
export const IDENTITY_IS_EMAIL_EXISTS_COMPLETE = 'IDENTITY_IS_EMAIL_EXISTS_COMPLETE';
export const IDENTITY_IS_EMAIL_EXISTS_ERROR = 'IDENTITY_IS_EMAIL_EXISTS_ERROR';

export const IDENTITY_SIGNUP_COMPLETE = 'IDENTITY_SIGNUP_COMPLETE';
export const IDENTITY_SIGNUP_ERROR = 'IDENTITY_SIGNUP_ERROR';

export const SOLUTIONS_SUCCESS = 'SOLUTIONS_SUCCESS'