import { getErrorCodeText } from 'services/helpers';

export const getIdentity = state => state.identity || {};

export const getIdentityId = state => {
  return state.identity && state.identity.self && state.identity.self.id;
};

export const getIsEmailVerified = state => !(state.identity.unverifiedEmail);

export const getTokenType = state => getIdentity(state).tokenType;
export const getAccessToken = state => getIdentity(state).accessToken;

export const getRegisterErrorMessage = state => {
  const identity = getIdentity(state);
  return getErrorCodeText(identity.registerError);
};
