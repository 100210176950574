import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getIsApplicationLoading, getIsApplicationLoadError } from 'app/selectors';
import Layout from './Layout';
import { getIsEmailVerified } from 'identity/selectors';

export class LayoutContainer extends Component {
 
  render() {
    const { location } = this.props;

    return (
      <React.Fragment>                
        <Layout {...this.props} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    loading: getIsApplicationLoading(state),
    loadError: getIsApplicationLoadError(state),
    unverifedEmail :getIsEmailVerified(state)
  };
  return props;
}

LayoutContainer.propTypes = {
  unverifiedEmail: PropTypes.bool,
  location: PropTypes.shape()
};

export default withRouter(connect(mapStateToProps, null)(LayoutContainer));
