import React from 'react';
import PropTypes from 'prop-types';
import SubLabelSection from 'shared/form-fields/SubLabelSection';
import './form-fields.scss';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.getSelectedOption = this.getSelectedOption.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getHasError = this.getHasError.bind(this);
    this.getInputValue = this.getInputValue.bind(this);
    this.getMaxRangeValue = this.getMaxRangeValue.bind(this);
    this.getMinRangeValue = this.getMinRangeValue.bind(this);
    this.getOptionByRangeValue = this.getOptionByRangeValue.bind(this);
    this.getSelectedLabel = this.getSelectedLabel.bind(this);
  }

  getSelectedOption() {
    const { options, input: { value }, meta: { initial } } = this.props;

    if (!initial) {
      throw Error('Slider field must be set with initial state');
    }

    const selectedOption = options.find(x => x.value === (value || initial));
    return selectedOption;
  }

  handleChange(event) {
    const { input: { onChange } } = this.props;
    const option = this.getOptionByRangeValue(event.target.value);
    onChange(option.value);
  }

  handleBlur(event) {
    const { input: { onBlur } } = this.props;
    const option = this.getOptionByRangeValue(event.target.value);
    onBlur(option.value);
  }

  getHasError() {
    const { meta: { error, touched } } = this.props;
    return error && touched;
  }

  getInputValue() {
    const option = this.getSelectedOption();
    return option.rangeValue;
  }

  getMaxRangeValue() {
    const { options } = this.props;
    return Math.max(...options.map(x => x.rangeValue));
  }

  getMinRangeValue() {
    const { options } = this.props;
    return Math.min(...options.map(x => x.rangeValue));
  }

  getOptionByRangeValue(rangeValue) {
    const { options } = this.props;
    return options.find(x => x.rangeValue === rangeValue);
  }

  getSelectedLabel() {
    const option = this.getSelectedOption();
    return option.label;
  }

  getSelectedHelperText() {
    const option = this.getSelectedOption();
    return option.helper;
  }

  render() {
    const { label, subLabel, input, meta: { error }, ...rest } = this.props;
    const id = `${input.name}-input`;
    const hasError = this.getHasError();
    const value = this.getInputValue();
    const max = this.getMaxRangeValue();
    const min = this.getMinRangeValue();
    const selectedLabel = this.getSelectedLabel();
    const selectedHelperText = this.getSelectedHelperText();

    return (
      <div className="formField slider">
        <input
          className={hasError ? 'inputError' : ''}
          id={id}
          {...rest}
          {...input}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          type="range"
          min={min}
          max={max}
          value={value}
          placeholder={label} />
        <label htmlFor={id}>
          {label && <span className="input-label">{label}</span>}
          <span className="selected-label">{selectedLabel}</span>
        </label>
        <span className="helper-text">{selectedHelperText}</span>

        <SubLabelSection showError={hasError} error={error} subLabel={subLabel} />
      </div>
    );
  }
}

Slider.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    rangeValue: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    helper: PropTypes.string
  })).isRequired,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  meta: PropTypes.shape().isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
  })
};

export default Slider;
