import compileReducers from 'services/compile-reducer';
import { types } from './auth-connection-types';

export const initialState = {
  connection: null,
  loaded: false,
  connectionNotFound: false,
  loading: false
};

const reducers = {};

reducers[types.RESET_CONNECTIONS] = (state) => {
  return {
    ...state,
    connection: null,
    loaded: false,
    connectionNotFound: false,
    loading: false
  };
};

reducers[types.LOADING] = (state) => {
  return {
    ...state,
    connection: null,
    loaded: false,
    connectionNotFound: false,
    loading: true
  };
};

reducers[types.LOAD_AUTH_CONNECTIONS_SUCCESS] = (state, payload) => {
  return {
    ...state,
    connection: payload,
    loaded: true,
    loading: false
  };
};

reducers[types.NO_CONNECTION_FOUND] = (state) => {
  return {
    ...state,
    connection: null,
    loaded: true,
    connectionNotFound: true,
    loading: false
  };
};

export default compileReducers(reducers, initialState);
