exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "@-webkit-keyframes offlineErrorEnter{from{height:0px;padding-top:0px;color:transparent}to{height:calc(60px + env(safe-area-inset-top, 0));padding-top:env(safe-area-inset-top, 0);color:#fff}}@keyframes offlineErrorEnter{from{height:0px;padding-top:0px;color:transparent}to{height:calc(60px + env(safe-area-inset-top, 0));padding-top:env(safe-area-inset-top, 0);color:#fff}}#offline-error{background-color:#cd0f0f;display:flex;justify-content:center;align-items:center;font-weight:bold;box-shadow:inset 0px -10px 10px -10px rgba(51,51,51,.5);-webkit-animation-name:offlineErrorEnter;animation-name:offlineErrorEnter;-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out;-webkit-animation-delay:1s;animation-delay:1s;-webkit-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-iteration-count:1;animation-iteration-count:1;-webkit-animation-direction:forwards;animation-direction:forwards}#offline-error~#main-layout .full-page-content{padding-top:0px;transition:padding .2s 1s ease-in-out}#offline-error svg{vertical-align:center;margin-right:.5em}#offline-error small{font-size:10px}", ""]);

// Exports
exports.locals = {
	"colorTeal": "#54c2b6",
	"colorTealRedChannel": "84",
	"colorTealGreenChannel": "194",
	"colorTealBlueChannel": "182",
	"colorTealDark": "#26a995",
	"colorTeal50": "rgba(84,194,182,.5)",
	"colorTealScreened": "#54c2b6",
	"colorWhiteOff": "#f2f2f2",
	"colorWhite": "#fff",
	"colorWhiteTrans": "rgba(255,255,255,0)",
	"colorWhite50": "rgba(255,255,255,.5)",
	"colorBlack": "#333",
	"colorGrayDark": "#4f4f4f",
	"colorGray": "#828282",
	"colorGrayLight": "#e0e0e0",
	"colorBlue": "#049fda",
	"colorBlueScreened": "#049fda",
	"colorMagenta": "#ea3b7d",
	"colorMagentaScreened": "#ea3b7d",
	"colorYellow": "#fed504",
	"colorYellowDark": "#d8b129",
	"colorYellowScreened": "#fed504",
	"colorUi1": "rgba(4,159,218,.3)",
	"colorUi2": "rgba(84,194,182,.3)",
	"colorUi3": "rgba(234,59,125,.3)",
	"colorUi4": "rgba(254,213,4,.3)",
	"colorUi5": "rgba(31,79,228,.3)",
	"colorUi6": "rgba(177,208,21,.3)",
	"colorUi7": "rgba(228,34,255,.3)",
	"colorUi8": "rgba(255,157,39,.3)",
	"colorUi9": "rgba(66,32,208,.3)",
	"colorUi10": "rgba(26,255,198,.3)",
	"colorUi11": "rgba(205,38,26,.3)",
	"colorUi12": "rgba(247,184,50,.3)",
	"colorUi13": "rgba(146,79,207,.3)",
	"colorUi14": "rgba(98,205,36,.3)",
	"colorUi15": "rgba(255,24,9,.3)",
	"colorUi16": "rgba(207,111,79,.3)",
	"colorGreen": "#0acf97"
};