import compileReducers from 'services/compile-reducer';
import { types } from './organizations-types';

export const initialState = {
  connectionNotFound: false
};

const reducers = {};

reducers[types.NO_CONNECTION_FOUND] = (state) => {
  return {
    ...state,
    connectionNotFound: true
  };
};

export default compileReducers(reducers, initialState);
