import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import './formatted-text.scss';

function FormattedText({ text }) {
  return (
    <div className='formatted-text'>
      <ReactMarkdown plugins={[gfm]} source={text} />
    </div>
  );
}
FormattedText.propTypes = {
  text: PropTypes.string
};

export default FormattedText;
