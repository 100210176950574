const env = window.env || {};

export const authAudience = env.AUTH_AUDIENCE;
export const authClientId = env.AUTH_CLIENTID;
export const authDomain = env.AUTH_DOMAIN;
export const authRealm = env.AUTH_REALM;
export const authResponseType = env.AUTH_RESPONSE_TYPE;
export const authScope = env.AUTH_SCOPE;

export const appInsightsKey = env.APP_INSIGHTS_INSTRUMENTATION_KEY;

export const profilesApiRoot = env.API_ROOT_PROFILES;
export const healthDataApiRoot = env.API_ROOT_HEALTH;
export const mobileAppRoot = env.MOBILE_APP_ROOT;

export const feedbackEmail = env.EMAIL_FEEDBACK || 'feedback@calciumhealth.com';
export const privacyEmail = env.EMAIL_PRIVACY || 'privacy@calciumhealth.com';
export const supportEmail = env.EMAIL_SUPPORT || 'support@calciumhealth.com';

export const syncAgainLimitMinutes = parseConfigInt(env.SYNC_AGAIN_LIMIT_MINUTES, 60);
export const identityRefreshMinutes = parseConfigInt(env.IDENTITY_REFRESH_MINUTES, 60);

export const buildVersion = window.WEB_APP_VERSION || '';

export const branchKey = env.BRANCH_KEY;

export const helpScoutBeaconId = env.HELPSCOUT_BEACON_ID;

export const marketPlaceUrl = env.MARKET_PLACE_URL;

export const privacyPolicyUrl = env.PRIVACY_POLICY_URL;
export const termsAndConditionsUrl = env.TERMS_AND_CONDITIONS_URL;

export const covidPortalUrl = env.COVID_PORTAL_URL;
export const dashPortalUrl = env.DASH_PORTAL_URL;
export const providerPortalUrl = env.PROVIDER_PORTAL_URL;
export const memberAppUrl = env.MEMBER_APP_URL;


function parseConfigInt(config, defaultValue) {
  var result = parseInt(config);
  return isNaN(result) ? defaultValue : result;
}
