import React from 'react';
import { privacyEmail } from 'app/config';
import { MailToLink, FormattedText } from 'shared';

export default function HipaaAuthorization() {
  return (
    <div className="hipaa-auth-content" >
      <FormattedText text="#### HIPAA Authorization" />
      <section className="hippa-summary">
        <FormattedText text='##### Hey, legal forms are complicated, we get it - here is a summary of the important bits:' />
        <FormattedText text="* Your health data will be encrypted and stored on Calcium's servers." />
        <FormattedText text='* Your data will never be sold without specific consent.' />
        <FormattedText text='* You may request to remove your account and data at any time.' />
      </section>
      <p>I hereby authorize the use or disclosure of protected health information pertaining to me to Calcium LLC. This authorization shall become effective immediately and shall remain in effect for one year from the date of signature unless a different date is specified. The purpose/use of this information is to provide Calcium LLC the ability to best serve me as an authorized user.</p>
      <br />
      <p>I authorize Calcium LLC to use my protected health information for marketing purposes including presenting relevant products to me. I understand that if my information would be used for a marketing purpose as defined by the Health Insurance Portability and Accountability Act (HIPAA), Calcium LLC may receive direct or indirect remuneration from a third party. Calcium, LLC will not directly sell personal health information to third parties without additional consent.</p>
      <br />
      <p>I understand that information used or disclosed may be subject to re-disclosure by the person or class of persons or facility receiving it and would then no longer be protected by federal privacy regulations.</p>
      <br />
      <p>I may revoke this authorization by notifying Calcium of my desire to revoke it by notifying Calcium LLC in writing or via email to <MailToLink id="mailto-privacy" mailto={`mailto:${privacyEmail}`}>{privacyEmail}</MailToLink>. However, I understand that any action already taken in reliance on this authorization cannot be reversed, and my revocation will not affect those actions. The written revocation is effective upon receipt, except to the extent the disclosing party or others have acted in reliance upon this authorization. I understand that the recipient may not lawfully further use or disclose the health information unless another authorization is obtained from me or unless such use or disclosure is required or permitted by law.</p>
      <br />
      <div className='hipaa-verified'>
        <a href="http://compliancy-group.com/hipaa-compliance-verification" target="_blank" rel="noopener noreferrer">
          <img src="https://compliancy-group.com/wp-content/uploads/2016/08/HIPAA-Compliance-Verification.png" height="100" alt="HIPAA compliance verification for Calcium" />
        </a>
      </div>
    </div >
  );
}
