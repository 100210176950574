import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ArrowButton } from 'shared';

class PreviousWeekButton extends React.Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect() {
    const { date, onChange } = this.props;
    onChange(moment(date).add(-1, 'week').format(moment.HTML5_FMT.DATE));
  }

  render() {
    return (
      <div id="go-previous-week-button" data-testid="go-previous-week-button" className="row-item week-option" onClick={this.onSelect}>
        <ArrowButton id="go-previous-week-arrow" className="go-previous" />
      </div>
    );
  }
}

PreviousWeekButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired
};

export default PreviousWeekButton;
