import qs from 'qs';
import local from 'services/localization/local';
import { buildVersion, feedbackEmail } from 'app/config';

export const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

export const buildFullUrl = suffix => `${baseUrl}/${suffix}`;

export const getSigninUrl = () => buildFullUrl('signin-callback');

export const buildMailToLink = (toEmail, subject, body) =>
  `mailto:${toEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

export const buildFeedbackEmailLink = subject => {
  const ua = window.navigator.userAgent;
  const url = window.location.href;

  const initialSpacing = '\r\n'.repeat(20);

  const body = `${initialSpacing}${local.browserInformationDisclaimer}\r\n${local.page}: ${url} | ${local.userAgent}: ${ua} | Build Version: ${buildVersion}`;

  return buildMailToLink(feedbackEmail, subject, body);
};

export const getQueryStringParams = (location) => {
  const searchString = location.search.replace(/^\??\/?/, '');
  const query = qs.parse(searchString);

  return query;
};
