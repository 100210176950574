import local from 'services/localization/local';
import { ErrorCodeMessageDict } from 'services/constants';
import styleVars from 'styles/_variables.scss';

export const getErrorCodeText = (error) => {
  if (!error) {
    return undefined;
  }

  if (error && error.body && error.body.code) {
    return ErrorCodeMessageDict[error.body.code] || local.error;
  }

  return local.error;
};

export const getUniqueId = () =>
  '_' + Math.random().toString(36).substr(2, 9);

export const getColorByGuid = (guid) => {
  const colors = [
    styleVars.colorUi1,
    styleVars.colorUi2,
    styleVars.colorUi3,
    styleVars.colorUi4,
    styleVars.colorUi5,
    styleVars.colorUi6,
    styleVars.colorUi7,
    styleVars.colorUi8,
    styleVars.colorUi9,
    styleVars.colorUi10,
    styleVars.colorUi11,
    styleVars.colorUi12,
    styleVars.colorUi13,
    styleVars.colorUi14,
    styleVars.colorUi15,
    styleVars.colorUi16
  ];

  // Null check
  if (!guid || guid.length < 4) return colors[0];

  const intValue = parseInt(guid.substring(0, 4), 16);
  var index = intValue % colors.length;
  return colors[index];
};

export const isFunction = (variable) => {
  return variable && typeof variable === 'function';
};
