import { getTokenType, getAccessToken } from 'identity/selectors';

export const NOT_FOUND_HTTP_ERROR_CODE = 404;
export const HIPAA_CONSENT_ERROR_CODE = 451;
export const buildRequest = (method, body, state) => {
  const tokenType = getTokenType(state);
  const accessToken = getAccessToken(state);
  const headers = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${tokenType} ${accessToken}`
    }
  };

  if (body) {
    headers.body = JSON.stringify(body);
  }

  return headers;
};

export const checkStatus = response => {
  if (response.status >= 400) {
    throw { status: response.status, response };
  }
  return response;
};

export const buildUrl = (apiRoot, route, parameters) => {
  const url = `${apiRoot}${route}`;

  if (!parameters) {
    return url;
  }

  return `${url}?${parameterize({ ...parameters })}`;
};

export const httpGet = (url, state) =>
  fetch(url, buildRequest('GET', null, state))
    .then(checkStatus);

export const httpPost = (url, body, state) =>
  fetch(url, buildRequest('POST', body, state))
    .then(checkStatus);

export const httpPatch = (url, body, state) =>
  fetch(url, buildRequest('PATCH', body, state))
    .then(checkStatus);

export const httpPut = (url, body, state) =>
  fetch(url, buildRequest('PUT', body, state))
    .then(checkStatus);

export const httpDelete = (url, body, state) =>
  fetch(url, buildRequest('DELETE', body, state))
    .then(checkStatus);

export const parameterize = (requestObj, priorKeys = '') => {
  const keys = Object.keys(requestObj);

  const params = keys
    .filter(key => requestObj[key] !== undefined)
    .map(key => {
      let newKey = priorKeys ? `${priorKeys}.${key}` : key;
      const value = requestObj[key];

      // handle array props
      if (Array.isArray(requestObj)) {
        newKey = `${priorKeys}[${key}]`;
      }

      // handle child object props
      if (value && typeof value === 'object') {
        return parameterize(value, newKey);
      }

      return `${encodeURIComponent(newKey)}=${encodeURIComponent(value)}`;
    });

  return params
    .filter(x => x !== '')
    .join('&');
};

export const parseSuccess = response => response.json();

export const parseError = error => {
  return error.response.json()
    .catch(() => { throw error; })
    .then(body => { throw { status: error.status, body }; });
};

export const encodeKeyAndValue = (key, value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
