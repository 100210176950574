import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { routePaths } from 'services/routing';
import { getRegisterErrorMessage } from 'identity/selectors';
import { registerUser } from 'identity/action-creators';
import Register from './Register';
import { bindActionCreators } from 'redux';
import { localStoragePaths,appNames } from 'services/constants';

export class RegisterContainer extends React.Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values){
    values.isFromProvider = localStorage.getItem(localStoragePaths.app) == appNames.provider ;
    this.props.actions.registerUser(values);
  }

  render() {
    const { isSignedIn, success, ...rest } = this.props;
    if (success) {
      return <Redirect to={routePaths.registerThankyou} />;
    }

    if (!isSignedIn) {
      return <Register handleSubmit={this.onSubmit} {...rest} />;
    }

    return <Redirect to="/" />;
  }
}

RegisterContainer.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    registerUser :PropTypes.func.isRequired
  }),
  registering: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isSignedIn: state.identity.isSignedIn,
  errorMessage: getRegisterErrorMessage(state),
  registering: state.identity.registering,
  success: state.identity.registerSuccess
});

function mapDispatchToProps(dispatch) {
  const actions = {
    registerUser
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
