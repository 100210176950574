import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { buildPath } from 'services/routing';
import { logClick } from 'services/appinsights-service';

export class LinkWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.logEvent = this.logEvent.bind(this);
  }

  logEvent() {
    const { id, logName } = this.props;
    logClick(logName || id);
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { to, params, logName, children, ...rest } = this.props;
    const path = buildPath(to, params);

    return (
      <Link to={path} {...rest} onClick={this.logEvent}>
        {children}
      </Link>
    );
  }
}

LinkWrapper.propTypes = {
  to: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  logName: PropTypes.string,
  children: PropTypes.node,
  params: PropTypes.shape()
};

export default LinkWrapper;
