import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import AuthLoader from 'app/AuthLoader';
import { ErrorPage } from 'shared';
import local from 'services/localization/local';
import { loadSelfIdentity } from './action-creators';
import { getIdentityId } from './selectors';

export default function IdentityWrapper({ children }) {
  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId, shallowEqual);
  const loadError = useSelector(state => state.identity.loadError, shallowEqual);

  useEffect(() => {
    if (!identityId) {
      dispatch(loadSelfIdentity());
    }
  }, [identityId]);

  if (loadError) {
    return <ErrorPage>{local.errorLoadProfile}</ErrorPage>;
  }

  if (!identityId) {
    return <AuthLoader />;
  }

  return children;
}
