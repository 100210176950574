import React,{useState,useEffect,useRef} from 'react';
import local from 'services/localization/local';
import { useDispatch,useSelector } from 'react-redux';
import {Modal } from 'react-bootstrap';
import {Icon, Button } from 'shared';
import { getRequest } from 'app/request/request-selectors';
import VerificationInput from 'react-verification-input';
import * as beaconService from 'services/helpscout-beacon-service';
import { helpScoutBeaconId,sendAgainVerificationCodeLimitSeconds } from 'app/config';
import { resendVerificationEmail, verifyEmailWithCode,verifyEmailWithCodeClick,signOutHandler} from 'identity/action-creators';
import { getUniqueId } from 'services/helpers';
import './verificationCode.scss';

function VerifyEmailByCode() {
  const email = useSelector(state => state.identity.email);
  const verificationProcessState = useSelector(state => state.identity.verificationProcessState);
  const [verificationRequestId, setVerificationRequestId]= useState();
  const [isBeaconInitialized, setBeaconInitialized] = useState(false);
  const [isBeaconOpen, setBeaconOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const dispatch = useDispatch();
  const {hasError, error} = useSelector(state => getRequest(state, verificationRequestId));
  const [showError, setShowError] = useState(hasError);
  const [verificationCode, setVerificationCode]= useState('');
  const textInput = useRef(null);

  useEffect(() => {
    if(email){
      setVerificationRequestId(getUniqueId());
    }
  }, [email]);

  useEffect(() => {

    setShowError(hasError);
    if(hasError)
      setVerificationCode('');
  }, [hasError]);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  },[seconds]);

  function onBeaconClose() {
    setBeaconOpen(false);
  }
  function resendCode(){
    dispatch(resendVerificationEmail());
    setVerificationCode('');
    setShowError(false);
    setSeconds(sendAgainVerificationCodeLimitSeconds);
  }
  const onChangeVerificationCode = (code) => {
    setVerificationCode(code);
    setShowError(false);
    if(code.length == 4){
      dispatch(verifyEmailWithCode(code, verificationRequestId));
    }
  };

  function handleSignIn () {
    dispatch(verifyEmailWithCodeClick());
  }
  const handleSignout = () =>{
    dispatch(signOutHandler());
  };
  if(!verificationProcessState){
    setTimeout(() => {
      dispatch(verifyEmailWithCodeClick());
    },10000);
  }

  const contactUsClick = () =>{
    if (isBeaconOpen)
      return;

    if (!isBeaconInitialized) {
      beaconService.initialize(helpScoutBeaconId);
      beaconService.setDefaultConfiguration();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
      beaconService.setContent('','');
      beaconService.navigate('/ask/');
      beaconService.addCallbackEvent('close', onBeaconClose);
      setBeaconInitialized(true);
    }
    beaconService.open();
    setBeaconOpen(true);
  };

  return (

    <div>
      <Modal show keyboard={false} centered className="backDrop" size="lg">

        {verificationProcessState ? (
          <React.Fragment>
            <Modal.Header className='modal-header-warning'>
              <Modal.Title>
                <div className='d-flex'>
                  <Icon symbol='backArrow' className='back-arrow' onClick={handleSignout}></Icon>
                  <h4 className='modal-title ms-2'>
                    {local.verifyEmail.confirm}
                  </h4>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='verifyCode' data-test-id="verifyCode">
                <div>
                  <Icon symbol='verificationCodeLock' />
                  <h2 className='enterCode'>{local.verifyEmail.enterCode}</h2>
                </div>
                <p>{local.formatString(local.verifyEmail.sentCode, email)}</p>
                <VerificationInput
                  validChars='0-9'
                  length={4}
                  autoFocus
                  value={verificationCode}
                  validChars='0-9'
                  onChange={e => { onChangeVerificationCode(e); }}
                  length={4}
                  autoFocus
                  ref={textInput}
                  inputProps={{ type: 'tel' }} />
                {showError && <label className='error-txt'>{error?.body}</label>}
                <div className="verifySupportLinks">
                  <div className='childSupportLinks'>
                    <a
                      className="resentCode contact-us-verify"
                      id="resend-email"
                      data-testid="resend-code"
                      color="transparent"
                      onClick={resendCode}
                      disabled={seconds > 0}> {local.verifyEmail.resend}
                    </a>
                  </div>
                  <div className='childSupportLinks'>
                    <small className='mt-2'>{local.verifyEmail.trouble}
                      <a className='contact-us-verify contactHelp' id="contact-us" color="transparent" onClick={contactUsClick}>{local.verifyEmail.contact}
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </React.Fragment>) :
          <React.Fragment>
            <Modal.Header className='modal-header-warning'>
              <Modal.Title><h4 className='modal-title'>{local.verifyEmail.verifySuccess}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='verifyCode' id="verifySignOut">
                <div>
                  <Icon symbol='greenTick' className='green-tick' fill="green"></Icon>
                  <h2 className='enterCode mt-2'>{local.verifyEmail.successMsgBanner}</h2>
                </div>
                <p className="mb-3">{local.formatString(local.verifyEmail.successMsgDescription, email)} <br />
                  {local.formatString(local.verifyEmail.redirectMsg, email)}
                </p>
                <Button
                  id="signInAuth0Button"
                  submit
                  color="blue"
                  onClick={handleSignIn}
                  className="d-flex mx-auto mt-4">
                  Sign In
                </Button>
              </div>

            </Modal.Body>
          </React.Fragment>}

      </Modal>
    </div>
  );
}

export default VerifyEmailByCode;


