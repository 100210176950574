exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".loading{top:50%;left:50%;transform:translate(-50%, -50%);position:absolute;transform-origin:center center;height:40px;width:40px;z-index:3}.loading:before{content:\"\";position:absolute;height:100%;width:100%;top:0px;left:0px;border-radius:50%;background-color:transparent;box-shadow:25px 0px 0px -15px #049fda,-25px 0px 0px -15px #049fda,0px 25px 0px -15px #54c2b6,0px -25px 0px -15px #54c2b6,18px 18px 0px -15px #ea3b7d,-18px -18px 0px -15px #ea3b7d,18px -18px 0px -15px #fed504,-18px 18px 0px -15px #fed504;transform-origin:center center;-webkit-animation-duration:2s;animation-duration:2s;-webkit-animation-name:spin;animation-name:spin;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}.loading-overlay{position:fixed;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:#e0e0e0;z-index:2}.loading-icon{width:1em;height:1em;margin-right:5px;display:inline-block;border-radius:50%;background-color:transparent;border:1.5px solid currentColor;border-bottom-color:transparent;-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-name:spin;animation-name:spin;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear;vertical-align:middle}", ""]);

// Exports
exports.locals = {
	"colorTeal": "#54c2b6",
	"colorTealRedChannel": "84",
	"colorTealGreenChannel": "194",
	"colorTealBlueChannel": "182",
	"colorTealDark": "#26a995",
	"colorTeal50": "rgba(84,194,182,.5)",
	"colorTealScreened": "#54c2b6",
	"colorWhiteOff": "#f2f2f2",
	"colorWhite": "#fff",
	"colorWhiteTrans": "rgba(255,255,255,0)",
	"colorWhite50": "rgba(255,255,255,.5)",
	"colorBlack": "#333",
	"colorGrayDark": "#4f4f4f",
	"colorGray": "#828282",
	"colorGrayLight": "#e0e0e0",
	"colorBlue": "#049fda",
	"colorBlueScreened": "#049fda",
	"colorMagenta": "#ea3b7d",
	"colorMagentaScreened": "#ea3b7d",
	"colorYellow": "#fed504",
	"colorYellowDark": "#d8b129",
	"colorYellowScreened": "#fed504",
	"colorUi1": "rgba(4,159,218,.3)",
	"colorUi2": "rgba(84,194,182,.3)",
	"colorUi3": "rgba(234,59,125,.3)",
	"colorUi4": "rgba(254,213,4,.3)",
	"colorUi5": "rgba(31,79,228,.3)",
	"colorUi6": "rgba(177,208,21,.3)",
	"colorUi7": "rgba(228,34,255,.3)",
	"colorUi8": "rgba(255,157,39,.3)",
	"colorUi9": "rgba(66,32,208,.3)",
	"colorUi10": "rgba(26,255,198,.3)",
	"colorUi11": "rgba(205,38,26,.3)",
	"colorUi12": "rgba(247,184,50,.3)",
	"colorUi13": "rgba(146,79,207,.3)",
	"colorUi14": "rgba(98,205,36,.3)",
	"colorUi15": "rgba(255,24,9,.3)",
	"colorUi16": "rgba(207,111,79,.3)",
	"colorGreen": "#0acf97"
};