import React,{ useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import local from 'services/localization/local';
import { NonAuthenticatedPageWall, LinkWrapper } from 'shared';
import { localStoragePaths, appNames } from 'services/constants';
import { signIn } from 'identity/action-creators';
import PrivacyPolicyEulaSection from 'app/privacy-policy-eula/PrivacyPolicyEulaSection';
import SignInForm from './SignInForm';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';


class SignIn extends React.Component {
  render() {
    const { signingIn, signInError, unverifiedEmail, email, actions: { handleSignIn } } = this.props;
    const errorMessage = signInError && !unverifiedEmail  ? local.signInErrorMessage : null;
    const privacyPolicySection = <PrivacyPolicyEulaSection buttonNameText={local.formSignInButton} />;
    const navAction = <LinkWrapper id='sign-in-register-nav' className='button gray' to='/register'>{local.formSignInJoin}</LinkWrapper>;
    const app = localStorage.getItem(localStoragePaths.app);
    const content = local.nonAuthWallPage;
    let registerUrl = '/register';

    let title = <p>{content.subheadMember}</p>;
    let showRegisterBtn = false;
    switch (app) {
      case appNames.covid:
        title = <p>{content.subheadCovid}</p>;
        break;
      case appNames.dash:
        title = <p>{content.subheadDash}</p>;
        break;
      case appNames.provider:
        title = <p>{content.subheadProvider}</p>;
        registerUrl = registerUrl + '?app=provider';
        break;
    }
    const getInitialValues = () => {
      return {
        email: email ?? ''
      };
    };
    return (
      <div>
        <Header isSignIn></Header>
        <div className='d-flex signin-section'>
          <div className='flex-fill'>
            <NonAuthenticatedPageWall className='signin-content' id='signIn-form' titleTabText={local.linkSignIn} navAction={navAction}>
              <h3 className='mb-0'>{local.formSignInHeader}</h3>
              {/* {title} */}
              <div className={showRegisterBtn ? 'hidden' : 'registerContent mb-3'}><small>{local.formSignInJoinText} <LinkWrapper id='sign-in-register' to={registerUrl}>{local.formSignInJoin}</LinkWrapper></small></div>

              <SignInForm
                initialValues={getInitialValues()}
                privacyPolicySection={privacyPolicySection}
                onSubmit={handleSignIn}
                errorMessage={errorMessage}
                signingIn={signingIn} />
              <div className='secondary-links forgot-pass-link'>
                {/* <div className={showRegisterBtn ? 'hidden' : 'registerContent mb-3'}><small>{local.formSignInJoinText} <LinkWrapper id=' sign-in-register' to='/register'>{local.formSignInJoin}</LinkWrapper></small></div> */}
                <small><LinkWrapper id='sign-in-forgot-password' to='/reset-password'>{local.formSignInForgotPassword}</LinkWrapper></small>
              </div>
            </NonAuthenticatedPageWall>
          </div>
          <div className='flex-fill image-section'>
            <img src='/static/images/logo-white.png' />
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

SignIn.propTypes = {
  signingIn: PropTypes.bool.isRequired,
  signInError: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    handleSignIn: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  signingIn: state.identity.signingIn,
  signInError: state.identity.signInError,
  unverifiedEmail:state.identity.unverifiedEmail,
  email:state.identity.email
});

function mapDispatchToProps(dispatch) {
  const actions = { handleSignIn: signIn };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
