import React from 'react';
import PropTypes from 'prop-types';
import { ModalWrapper, Button, Icon } from 'shared';
import HipaaAuthorization from './HipaaAuthorization';

function HipaaModal(props) {
  return (
    <ModalWrapper {...props}>
      <div id="hipaa-modal" className="modal-container">
        <div className="modal-header">
          <Button id="hipaa-authorization-close-button" onClick={props.onRequestClose}><Icon symbol="times" /></Button>
          <Icon symbol="hipaaauth" />
          <p></p>
        </div>
        <div className="modal-body">
          <HipaaAuthorization />
        </div>
      </div>
    </ModalWrapper>
  );
}

HipaaModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired
};

export default HipaaModal;
