import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeDrawerMenu } from 'app/layout/navigation/actions';
import { TextAction, Icon } from 'shared';
import local from 'services/localization/local';
import HipaaModal from 'app/privacy-policy-eula/HipaaModal';

class HipaaMoreLink extends React.Component {
  constructor(props) {
    super(props);

    this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      isPrivacyPolicyOpen: false
    };
  }

  openPrivacyPolicy() {
    const { actions } = this.props;
    this.setState({ isPrivacyPolicyOpen: true });
    actions.closeDrawerMenu();
  }

  closeModal() {
    this.setState({ isPrivacyPolicyOpen: false });
  }

  render() {
    const { isPrivacyPolicyOpen } = this.state;

    return (
      <div>
        <TextAction id="hipaa-text" className='nav-hipaa' onClick={this.openPrivacyPolicy}>
          <Icon symbol="hipaaauth" />
          <span>{local.hipaaAuthorization}</span>
        </TextAction>
        <HipaaModal isOpen={isPrivacyPolicyOpen} onRequestClose={this.closeModal} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {
    closeDrawerMenu
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

HipaaMoreLink.propTypes = {
  actions: PropTypes.shape({
    closeDrawerMenu: PropTypes.func.isRequired
  }).isRequired
};

export default connect(null, mapDispatchToProps)(HipaaMoreLink);
