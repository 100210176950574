import React from 'react';
import { Icon } from 'shared';
import local from 'services/localization/local';

class RegisterRightCardDash extends React.Component {
  render() {
    return (
      <div className="col-sm-6">
        <div className="shadow ms-md-5 mt-md-0 mt-4">
          <div className="card-body">
            <h4 className="card-title pb-3">{local.registerRightCardDash.title}</h4>
            <h6 className="card-title">{local.registerRightCardDash.subtitle}</h6>
            <div className="card-text mt-3">
              <ul>
                <li className="d-flex"> <div><Icon symbol="tick" /></div> {local.registerRightCardDash.tick1} ​</li>
                <li className="d-flex"> <div><Icon symbol="tick" /></div> {local.registerRightCardDash.tick2} </li>
                <li className="d-flex"> <div><Icon symbol="tick" /></div> {local.registerRightCardDash.tick3} </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RegisterRightCardDash;
