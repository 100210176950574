/* eslint-disable max-len */
export default {
  accept: 'Aceptar',
  acceptingInvitation: 'Aceptando invitación...',
  activity: {
    visTitle: 'De {0}',
    visDurationlabel: 'Duración: {0} min.',
    visIntensityLabel: 'Puntuación de intensidad: {0}'
  },
  activityTitleTab: 'Agregar actividad',
  activityLogHeaderDefault: 'Agregar actividad',
  activityFormNameLabel: 'Actividad',
  activityFormNotesPlaceholder: 'Agregar notas sobre esta actividad',
  activityStartDateTimeLabel: 'Hora de inicio',
  activityDurationLabel: 'Duración:',
  and: '{0} y {1}',
  observation: {
    context: {
      contextHeader: 'Estos datos están asociados con la tarea \'{0}\' de la ruta {1}.',
      dateAfter: 'Vence a partir del {0}',
      dateBefore: 'Vence antes del {0}',
      dateBetween: 'Vence entre el {0} y el {1}'
    },
    bodyArms: {
      title: 'Brazos',
      logTitle: 'Registro de brazos',
      noDataText: 'Aún no se han registrado datos sobre la brazos.',
      tileNoDataText: 'Agregue su primera brazos'
    },
    bodyChest: {
      title: 'Pecho',
      logTitle: 'Registro de pecho',
      noDataText: 'Aún no se han registrado datos sobre la pecho.',
      tileNoDataText: 'Agregue su primera pecho'
    },
    bodyFat: {
      title: 'Grasa corporal',
      noDataText: 'Conecte un dispositivo para comenzar a ver las medidas de su grasa corporal aquí.'
    },
    bodyHips: {
      title: 'Cadera',
      logTitle: 'Registro de cadera',
      noDataText: 'Aún no se han registrado datos sobre la cadera.',
      tileNoDataText: 'Agregue su primera cadera'
    },
    bodyThighs: {
      title: 'Muslos',
      logTitle: 'Registro de muslos',
      noDataText: 'Aún no se han registrado datos sobre la muslos.',
      tileNoDataText: 'Agregue su primera muslos'
    },
    bodyWaist: {
      title: 'Cintura',
      logTitle: 'Registro de cintura',
      noDataText: 'Aún no se han registrado datos sobre la cintura.',
      tileNoDataText: 'Agregue su primera cintura'
    },
    bloodOxygen: {
      title: 'Oxígeno en sangre',
      noDataText: 'Conecte un dispositivo para empezar a ver las lecturas de su porcentaje de oxígeno en sangre aquí.'
    },
    bloodGlucose: {
      title: 'Glucosa en sangre',
      noDataText: 'Comience a hacer seguimiento de su glucosa en sangre.'
    },
    bloodPressure: {
      title: 'Presión arterial',
      noDataText: 'Conecte un dispositivo para comenzar a ver las lecturas de su presión arterial aquí.',
      systolicChartLabel: 'Sistólica',
      diastolicChartLabel: 'Diastólica',
      systolicFormLabel: 'Sistólica',
      systolicFormSubLabel: 'Número superior',
      diastolicFormLabel: 'Diastólica',
      diastolicFormSubLabel: 'Número inferior',
      diastolicValueHigherError: 'Debe ser inferior a la sistólica'
    },
    heartRate: {
      title: 'Frecuencia cardíaca',
      logTitle: 'Registro de frecuencia cardíaca',
      noDataText: 'Aún no se han registrado datos sobre la frecuencia cardíaca.',
      tileNoDataText: 'Agregue su primera frecuencia cardíaca'
    },
    height: {
      title: 'Altura',
      logTitle: 'Registro de altura',
      noDataText: 'Aún no se han registrado datos sobre la altura.',
      tileNoDataText: 'Agregue su primera altura'
    },
    insulin: {
      title: 'Insulina',
      noDataText: 'Conecte un dispositivo para comenzar a ver las lecturas de su insulina.'
    },
    peakFlow: {
      title: 'Flujo máximo',
      noDataText: 'Conecte un dispositivo para comenzar a ver sus lecturas de flujo máximo aquí.',
      peakFlowFormLabel: 'Flujo máximo (L/minuto)'
    },
    steps: {
      title: 'Pasos',
      noDataText: 'Conecte un dispositivo para comenzar a ver sus pasos diarios aquí.',
      tileTitle: 'Pasos diarios',
      barChartFormat: '{0} pasos'
    },
    temperature: {
      title: 'Temperatura',
      logTitle: 'Registro de temperatura',
      noDataText: 'Aún no se han registrado datos sobre la temperatura.',
      tileNoDataText: 'Agregue su primera temperatura'
    },
    weight: {
      title: 'Peso',
      logTitle: 'Registro de peso',
      noDataText: 'Aún no se han registrado datos sobre el peso.'
    }
  },
  checkEmail: 'Por favor, compruebe su correo electrónico. Le hemos enviado un correo electrónico a {0}.',
  sendingConfirmationEmail: 'Enviando otro correo electrónico de confirmación.',
  daysShortLabel: 'd',
  hoursShortLabel: 'h',
  minutesShortLabel: 'm',
  secondsShortLabel: 's',
  daysLabel: 'días',
  hoursLabel: 'horas',
  minutesLabel: 'minutos',
  secondsLabel: 'segundos',
  activityIntensityLabel: 'Intensidad',
  low: 'Ligera',
  medium: 'Moderada',
  high: 'Enérgica',
  lowActivityHelper: 'Puede cantar una canción',
  mediumActivityHelper: 'Puede mantener una conversación',
  highActivityHelper: 'Puede decir unas pocas palabras',
  add: 'Añadir',
  agree: 'Acepto',
  app: 'Calcium',
  aspartof: 'como parte de',
  back: 'Volver',
  bmiCategoryNormal: '(Normal)',
  bmiCategoryObese: '(Obeso)',
  bmiCategoryOverweight: '(Sobrepeso)',
  bmiCategoryUnderweight: '(Bajo peso)',
  bmiDetailsBmi: 'IMC reciente',
  bmiTitle: 'IMC',
  bmiHeader: 'Índice de masa corporal',
  bmiNoDataText: 'Se requieren datos de altura y peso para calcular el IMC.',
  bodyMeasurementsHeader: 'Medidas corporales',
  browserInformationDisclaimer: 'La siguiente información se está recopilando para uso diagnóstico.',
  cancel: 'Cancelar',
  close: 'Cerrar',
  continue: 'Continuar',
  copyLink: 'Copiar enlace',
  copyright: 'Calcium LLC',
  createSelfProfileTitle: 'Cree su perfil',
  createOtherProfileTitle: 'Crear perfil',
  currentSelection: 'Selección actual',
  dashboard: {
    allResults: 'Ver todo',
    data: { header: 'Datos de salud' },
    dataConnections: {
      header: 'Orígenes de datos',
      tile: 'Dispositivos y datos'
    },
    pathways: {
      activeHeader: 'Mis rutas',
      completedHeader: 'Rutas completadas',
      header: 'Explorar rutas',
      description: 'Nuestros programas personalizados de mejora de la salud.',
      nextTask: 'Siguiente tarea',
      dueToday: 'Vence hoy'
    }
  },
  dateDisplayToday: 'Hoy',
  dateDisplayTomorrow: 'Mañana',
  dateDisplayYesterday: 'Ayer',
  dateDisplayDayFormat: 'dddd',
  decline: 'Rechazar',
  deleted: 'Borrada',
  deleting: 'Borrando...',
  devices: {
    connectButton: 'Conecte un dispositivo',
    errorMessage: 'No se puede preparar para el dispositivo y las conexiones de datos',
    explanation: 'Conecte sus dispositivos desde Fitbit, Garmin Connect, iHealth y Withings.',
    dotDescription: 'Este punto de color indicará que los datos provienen de un tercero.',
    overviewTitle: 'Dispositivos y datos',
    connectError: 'Error al abrir el administrador de dispositivos. Haga clic para volver a intentarlo.',
    syncTitle: 'Sincronizar datos automáticamente',
    needADeviceQuestion: '¿Necesita un dispositivo que funcione con Calcium?',
    marketplaceLink: 'Obtenga un dispositivo compatible aquí.',
    healthKitExplanation: 'Haga clic aquí para conectar o desconectar su cuenta de Apple Health.',
    healthKitButton: 'Apple Health'
  },
  done: 'Hecho',
  edit: 'Editar',
  editProfile: 'Editar perfil',
  error: 'Se ha producido un error',
  errorLoadProfile: 'Lo sentimos, no hemos podido cargar su perfil.  Compruebe su conexión a Internet e inténtelo de nuevo.',
  errorPasswordError: 'La contraseña no está permitida, puede que sea muy común',
  errorPasswordStrengthError: 'La contraseña debe tener al menos 8 caracteres',
  errorUserExistsError: 'Correo electrónico ya registrado',
  errorInvalidEmailFormat: 'Formato de correo electrónico inválido',
  errorCodeMessage: {
    inviteAlreadyExists: 'Ya existe una invitación de compartir para el usuario con este correo electrónico',
    inviteSelf: 'No puedo invitarme',
    inviteUsed: 'Esta invitación ya no es válida.',
    inviteExistingRelationship: 'El usuario con este correo electrónico ya tiene acceso a este perfil.'
  },
  feedbackEmailSubject: 'Comentarios de Calcium',
  formAvatarLabel: 'Avatar',
  formBirthdateLabel: 'Fecha de nacimiento',
  formBloodGlucoseLabel: 'Glucosa en sangre (mg/dL)',
  formBodyChestLabel: 'Pecho (pulgadas)',
  formBodyChestPlaceholder: 'Ejemplo: 30',
  formBodyHipsLabel: 'Cadera (pulgadas)',
  formBodyHipsPlaceholder: 'Ejemplo: 30',
  formBodyThighsLabel: 'Muslos (pulgadas)',
  formBodyThighsPlaceholder: 'Ejemplo: 30',
  formBodyWaistLabel: 'Cintura (pulgadas)',
  formBodyWaistPlaceholder: 'Ejemplo: 30',
  formBodyArmsLabel: 'Brazos (pulgadas)',
  formBodyArmsPlaceholder: 'Ejemplo: 30',
  formCancel: 'Cancelar',
  formContinue: 'Continuar',
  formDayLabel: 'Día',
  formEmailLabel: 'Correo electrónico',
  formResetEmailLabel: 'Correo electrónico',
  formGenderLabel: 'Sexo',
  formHeartRateLabel: 'Ritmo cardíaco (bpm)',
  formHeartRatePlaceholder: 'Ejemplo: 120',
  formHeightFeetLabel: 'Pies',
  formHeightInchesLabel: 'Pulgadas',
  formLast4Ssn: 'Últimos 4 dígitos del número de seguridad social',
  medicationDoses: 'Dosis',
  noMedicationDoses: 'No se han registrado dosis de medicamentos que coincidan con su búsqueda.',
  medications: {
    deleteButton: 'Borrar medicamentos',
    deleteWarning: 'Advertencia: Borrar este medicamento también eliminará todas sus dosis registradas'
  },
  medicationDoseLogHeaderDefault: 'Dosis del medicamento',
  medicationDoseLogHeader: 'Añadir {0} dosis',
  medicationDoseAddTitleTab: 'Añadir dosis de medicamento',
  medicationDosageCallToAction: 'Añadir dosis',
  medicationMostRecentDose: '{0} tomadas {1}',
  medicationNoTypicalDose: 'No se encontraron dosis habituales',
  medicationFormTypicalDose: 'Dosis habitual:',
  medicationLogHeader: 'Añadir medicamento',
  medicationEditHeader: 'Editar medicamento',
  medicationAddTitleTab: 'Añadir medicamento',
  medicationEditTitleTab: 'Editar medicamento',
  medicationFormNameLabel: 'Nombre del medicamento',
  medicationFormNotesPlaceholder: 'Añadir notas sobre este medicamento',
  medicationFormTypicalDosePlaceholder: 'Añada su dosis habitual',
  medicationFormTypicalDoseLabel: 'Dosis habitual (opcional)',
  medicationDoseFormlDoseLabel: 'Dosis (opcional)',
  medicationDoseFormlDosePlaceholder: 'Dosis tomadas',
  morning: 'Mañana',
  afternoon: 'Tarde',
  evening: 'Atardecer',
  nighttime: 'Noche',
  formCreateAccount:'Crear una cuenta',
  formMonthLabel: 'Mes',
  formNameLabel: 'Nombre de preferencia',
  formNotesLabel: 'Notas (opcional)',
  formNotesPlaceholder: '¿Qué estaba pasando en ese momento?',
  formPasswordLabel: 'Contraseña',
  formPasswordRequirementLabel: 'Contraseña (mínimo 8 caracteres)',
  formPrivacyPolicyAgreeText: 'Al hacer clic en {0}, usted acepta nuestras {1} y {2}.',
  formProfileHeader: 'Perfil',
  formProfileSave: 'Guardar perfil',
  formSave: 'Guardar',
  formSignInButton: 'Iniciar sesión',
  formContinueButton: 'Continuar',
  formSignInHeader: 'Iniciar sesión en Calcium',
  formSignInForgotPassword: '¿Ha olvidado la contraseña?',
  formSignInResetPasswordLink: 'Restablecer',
  formSignInJoin: 'Crea una cuenta',
  formSignInJoinText: '¿Es nuevo en Calcium?',
  formSubmit: 'Enviar',
  formTemperatureLabel: 'Temperatura (ºF)',
  formTemperaturePlaceholder: 'Temperatura',
  formTimestampLabel: 'Indicación de tiempo',
  formWeightLabel: 'Peso (lbs)',
  formWeightPlaceholder: 'Peso',
  formYearLabel: 'Año',
  genderFemale: 'Femenino',
  genderMale: 'Masculino',
  genderOther: 'Otros',
  genderUnknown: 'Desconocido',
  generalFeedbackMessage: 'Agradecemos sus comentarios y nos encantaría saber sobre su experiencia con Calcium. Háganos saber lo que le gusta y lo que podría ser mejorado.',
  generatingLink: 'Creando enlace para compartir...',
  goToDashboard: 'Ir al Panel de control',
  healthScoreLabel: 'Puntuación Calcium',
  healthDataHeader: 'Datos de salud',
  healthDataBodyMeasurementsSectionHeader: 'Medidas corporales',
  healthDataVitalsSectionHeader: 'Vitales',
  healthDataOtherSectionHeader: 'Otros',
  healthDashboardHeader: 'Panel de control',
  healthChampionScore: 'Puntuación Calcium',
  healthBadge: {
    unknown: {
      message: 'Estado desconocido',
      status: 'Por favor, rellene correctamente las tareas obligatorias.',
      calculatingStatus: 'Calculando el nuevo estado.', // 'Calculating new status.', // non-official
      errorStatus: 'Se produjo un error al recuperar el último estado' // 'An error occurred retrieving the latest status' // non-official
    },
    verified: {
      message: '¡Enhorabuena!',
      status: 'No presenta síntomas.'
    },
    unverified: {
      message: 'Advertencia de estado',
      status: 'Por favor, asegúrese de rellenar correctamente las tareas obligatorias. Si informó sobre síntomas, siga las pautas de los CDC en los próximos pasos.'
    },
    updated: 'Actualizado {0}', //'Updated {0}', // non-official
    onDay: 'En {0}', //'On {0}', // non-official
    dateAtTime: 'MM/DD/YYYY [at] h:mm a' // locale consideration
  },
  healthDataLink: 'Datos de salud',
  healthData: {
    connect: {
      identityForm: {
        address1Label: 'Dirección',
        address2Label: 'N.º de suite/apartamento',
        addressCityLabel: 'Ciudad',
        addressStateLabel: 'Estado/provincia',
        birthdateLabel: 'Fecha de nacimiento',
        firstNameLabel: 'Nombre legal',
        lastNameLabel: 'Apellidos legales',
        genderLabel: 'Sexo',
        profileLabel: 'Verifique la identidad para {0}',
        zipCodeLabel: 'Código postal',
        submitButton: 'Iniciar verificación',
        validationBirthdate: 'La fecha de nacimiento no es válida',
        validationDay: 'El día debe estar entre 1 y 31',
        validationMonth: 'El mes debe ser estar 1 y 12',
        validationZipCode: 'El código postal debe tener 5 dígitos',
        disclaimer: 'Al hacer clic en Iniciar verificación, certifica que tiene autorización legal para acceder a la Información de salud protegida de esta persona.'
      },
      identityVerification: {
        challengeMessage: 'Para su seguridad, necesitamos un poco más de información a fin de completar su verificación.',
        contactCta: 'Contactar con el servicio de asistencia',
        continueVerificationAction: 'Continuar verificación',
        emailSubject: 'Problemas de verificación de identidad',
        emailMessage: 'Tengo problemas para verificar mi identidad y necesito ayuda.',
        introDetails: 'Calcium se dedica a proteger su privacidad. Ayúdenos a verificar su identidad.',
        introHeader: 'Conecte su historia clínica',
        processingMessage: 'Procesando...',
        ssnHeader: 'Verificar identidad',
        ssnParagraph1: 'Para asegurarnos de que lo hemos encontrado, necesitamos que verifique los últimos cuatro dígitos de su número de seguridad social (SSN, por sus siglas en inglés).',
        ssnParagraph2: 'Utilizamos esta información solo para fines de verificación, y no la almacenaremos una vez completada.',
        startVerificationAction: 'Iniciar verificación',
        steps: '1. La verificación de identidad suele tardar entre cinco y diez minutos.\n\n3. Puede intentar este proceso **una vez**.',
        title: 'Verifique su identidad',
        toastProcessing: 'Procesando',
        toastSuccess: '¡Identidad verificada!',
        toastError: 'No se pudo verificar su identidad',
        verificationErrorHeader: 'Lo sentimos',
        verificationErrorDetails: 'No hemos podido verificar su identidad.\n\nHay varias razones por las que esto puede haber ocurrido, especialmente en el caso de los menores de 18 años.\n\nCalcium se dedica a proteger su privacidad. Hasta que no haya verificado su identidad, no podrá sincronizar su historia clínica electrónico.\n\nNos gustaría ayudarle con un método alternativo para verificar su identidad. Póngase en contacto con el servicio de asistencia.',
        verifyIdentiyHeader: 'Verificar identidad',
        warning: 'Asegúrese de estar en un entorno donde podrá completar el proceso a continuación sin distracciones.'
      },
      status: {
        actionConnect: 'Conectar',
        actionConnectLong: 'Conecte su historia clínica',
        complete: '¡Completado!',
        connected: 'Conectado',
        connecting: 'Conectando...',
        header: 'Historia clínica',
        lastSynced: 'Sincronizado {0}',
        noData: 'No encontramos su historia clínica ¡No se desanime! ¡Estamos expandiendo nuestra red continuamente!',
        processing: 'Procesando',
        returnToDashboard: 'Volver al Panel de control',
        searchComplete: 'Búsqueda completada',
        searching: 'Ahora estamos buscando su información clínica en nuestras redes.',
        searchingDisclaimer: 'Esto podría tardar un tiempo en completarse, así que siéntase libre de continuar usando la aplicación. Le informaremos cuando hayamos terminado.',
        sorry: 'Lo sentimos',
        startedSync: 'Sincronización iniciada',
        successDetails: '¡Su historia clínica ya está disponible!',
        syncing: 'Sincronizando...',
        syncDataAction: 'Sincronizar ahora',
        syncError: 'Algo salió mal. Por favor, vuelva más tarde.',
        viewHealthRecords: 'Conecte su historia clínica'
      }
    },
    conditions: {
      connectMessage: 'Conecte su historia clínica para ver sus enfermedades.',
      noConditions: 'No se encontraron enfermedades',
      title: 'Enfermedades',
      linkText: 'Enfermedades',
      diagnosed: 'Diagnosticadas',
      severity: 'Gravedad',
      reportedBy: 'Informado por',
      remission: 'Remisión',
      unspecified: 'Sin especificar'
    },
    encounters: {
      connectMessage: 'Conecte su historia clínica para ver sus interacciones con los médicos y otros proveedores de atención médica.',
      title: 'Encuentros',
      diagnoses: 'Diagnósticos',
      linkText: 'Encuentros',
      location: 'Ubicación',
      noRecords: 'No se encontraron encuentros',
      provider: 'Proveedor',
      started: 'Iniciado',
      stopped: 'Interrumpido'
    },
    immunizations: {
      connectMessage: 'Conecte su historia clínica para ver su historial de vacunas.',
      dose: 'Dosis',
      title: 'Vacunas',
      linkText: 'Vacunas',
      lotNumber: 'N.º de lote',
      manufacturer: 'Fabricante',
      noRecords: 'No se encontraron registros sobre vacunas',
      practitioner: 'Profesional médico'
    },
    labs: {
      connectMessage: 'Conecte su historia clínica para ver sus resultados de laboratorio.',
      title: 'Resultados de laboratorio',
      linkText: 'Resultados de laboratorio',
      noRecords: 'No se encontraron resultados de laboratorio',
      expandAction: 'Expandir',
      collapseAction: 'Colapsar'
    },
    procedures: {
      connectMessage: 'Conecte su historia clínica para ver su historial de procedimientos médicos.',
      title: 'Procedimientos',
      status: 'Estado',
      linkText: 'Procedimientos',
      location: 'Ubicación',
      noRecords: 'No se encontraron procedimientos',
      provider: 'Proveedor',
      started: 'Iniciado',
      stopped: 'Interrumpido'
    }
  },
  healthScoreDetails: 'La puntuación de Calcium es una cifra calculada en base a una variedad de factores relacionados con la salud física, la salud mental, el estilo de vida y factores sociales medibles y gestionables. Puede realizar el seguimiento de su puntuación de Calcium a diario, mientras recibe comentarios personalizados y sugerencias para mejorar durante su evolución en el camino de la atención médica.',
  helpscout: {
    heathSync: {
      subject: 'Conectar registros médicos',
      message: 'Cuéntenos un poco sobre lo que estaba buscando al conectar su EHR'
    }
  },
  hipaaAuthorization: 'Autorización de HIPAA',
  hipaaConsentIntroGreeting: '¡Hola!',
  hipaaConsentIntroP1: 'Necesitamos autorización para usar la información de salud personal de {0} a fin de proporcionarle los conocimientos más relevantes y que pueda experimentar la aplicación al completo.',
  hipaaReconsentIntroP1: 'Cada año, necesitamos renovar su autorización para usar la información de salud personal de {0} a fin de proporcionarle los conocimientos más relevantes y que pueda experimentar la aplicación al completo.',
  hipaaConsentNewVersionIntroP1: 'Hemos actualizado nuestro formulario de autorización de HIPAA y necesitamos su autorización para continuar utilizando la información de salud de {0} a fin de proporcionarle los conocimientos más relevantes y que pueda experimentar la aplicación al completo.',
  hipaaConsentIntroP2: 'Sin su autorización, se perderá experimentar muchas de las funciones más innovadoras y útiles de nuestra plataforma.',
  hipaaConsentP1: 'He leído este formulario y acepto los usos y divulgaciones de la información según lo descrito.',
  hipaaConsentP2: 'Cualquier copia de esta autorización es tan válida como la original. Tiene derecho a recibir una copia de esta autorización. Al hacer clic en «Acepto», usted acepta los términos mencionados anteriormente en lugar de una firma original en papel.',
  hipaaIntroNewProfileTitle: 'Antes de continuar ..',
  hipaaIntroNewProfileContent: 'Necesitamos que firme nuestro acuerdo HIPAA para cada nuevo perfil que cree. Sabemos que los datos de salud pueden ser confidenciales y por eso nos mantenemos al más alto nivel de seguridad.',
  hipaaIntroNewUserTitle: 'Casi ahí..',
  hipaaIntroNewUserContent: 'Pero primero necesitamos que firme nuestro acuerdo HIPAA, sabemos que los datos de salud son personales y pueden ser muy sensibles. Es por eso que nos regimos por el más alto estándar de seguridad y creemos que nuestros usuarios merecen saber cómo se utilizan sus datos.',
  hipaaIntroResignTitle: 'Antes de continuar ..',
  hipaaIntroResignContent: 'Necesitamos que renuncie a nuestro acuerdo de HIPAA, al igual que lo hace cada año cuando visita al médico. Como siempre, entendemos que los datos de salud pueden ser confidenciales y es por eso que nos mantenemos al más alto nivel de seguridad.',
  hipaaIntroUpdateTitle: 'Antes de continuar ..',
  hipaaIntroUpdateContent: 'Necesitamos que renuncie a nuestro acuerdo de HIPAA, hemos realizado algunos cambios. Como siempre, entendemos que los datos de salud pueden ser confidenciales y es por eso que creemos que nuestros usuarios merecen saber cómo se utilizan sus datos.',
  in: 'en {0}',
  inactiveHaveACode: '¿Tiene un código de invitación?',
  inactiveHeader: '¡Gracias por unirse!',
  inactiveParagraph: 'Manténgase atento ya que iremos agregando participantes con el tiempo. Si ya recibió un código de invitación, introdúzcalo a continuación para obtener acceso instantáneo. De lo contrario, {0} y le enviaremos un correo electrónico una vez que su acceso esté disponible.',
  inactivePreHeader: 'Introduzca un código de invitación',
  inactiveRequestCode: 'solicite un código de invitación',
  inactiveRequestCodeEmailSubject: 'Solicite un código de invitación',
  includes: 'incluye',
  interestCta: 'Envíenos un correo electrónico',
  interestEmailSubject: 'La inclusión de {0} como función haría de Calcium un mejor producto.',
  interestMessageCta: '¿Esta función sería beneficiosa para usted?',
  interestMessageSubmittedCta: '¡Gracias por sus comentarios! Nos encantaría saber más sobre cómo la utilizaría.',
  interestMessageactivity: 'Haga un seguimiento y analice el impacto de su actividad diaria. Introduzca detalles sobre el ejercicio, la atención plena, el sueño y otras actividades directamente en la aplicación y sincronice los dispositivos conectados.',
  interestMessageallergies: 'Haga un seguimiento y comprenda sus alergias conocidas. Introduzca notas directamente en la aplicación, importe su historia clínica y sus resultados de laboratorio.',
  interestMessagebloodglucose: 'Haga un seguimiento y analice sus niveles de glucosa en sangre. Introduzca las lecturas directamente en la aplicación, importe su historia clínica, sus resultados de laboratorio, y sincronice los dispositivos conectados.',
  interestMessagebp: 'Haga un seguimiento y comprenda los resultados de su presión arterial. Introduzca las lecturas directamente en la aplicación, importe su historia clínica y sincronice los dispositivos conectados.',
  interestMessagehealthprograms: 'Obtenga recomendaciones inteligentes sobre rutas relacionadas con la salud para ayudarle a alcanzar sus objetivos personalizados.',
  interestMessageimmunizations: 'Haga un seguimiento y gestione sus vacunas pasadas, actuales y futuras en función de su historia clínica o de la introducción directa de datos.',
  interestMessagemedications: 'Haga un seguimiento y gestione sus medicamentos actuales en función de su historia clínica o de la lista introducida manualmente. Vea información sobre medicamentos, interacciones y reabastecimiento desde cadenas de farmacias específicas.',
  interestMessagenutrition: 'Obtenga una imagen clara de sus hábitos alimenticios diarios, realice un seguimiento del progreso hacia sus objetivos y acceda a consejos para mejorar y mantener una dieta saludable.',
  interestMessagereproductive: 'Gestione y mantenga su salud reproductiva. Haga un seguimiento a procesos y funciones reproductivos en todas las etapas de la vida. Integre su historia clínica, aplicaciones y dispositivos de terceros.',
  interestMessagespO2: 'Haga seguimiento y analice sus niveles de oxígeno en sangre. Introduzca las lecturas directamente en la aplicación, importe su historia clínica y sincronice los dispositivos conectados.',
  interestMessageshareprofile: 'Comparta privilegios de acceso para perfiles con otros.',
  interestMessagegroup: 'Cree grupos, comparta información y apoye el progreso de otros.',
  interviews: {
    answerYes: 'Sí',
    answerNo: 'No',
    continue: 'Continuar',
    complete: 'Completar',
    unknownContentType: 'Tipo de contenido desconocido'
  },
  invitationAccepted: '¡Éxito! La invitación ha sido aceptada correctamente',
  invitationError: 'No se pudo aceptar la invitación',
  invitationErrorCta: 'Compruebe su conexión a Internet e inténtelo de nuevo.',
  invitationErrorToast: 'No se pudo aceptar la invitación',
  join: 'Registrarme',
  joinCalcium: 'Crear cuenta',
  journal: {
    linkJournals: 'Mi diario',
    noData: 'No hay entradas de diario que mostrar',
    title: 'Mi diario',
    createTitle: 'Crear entrada de diario',
    formTitle: 'Introduzca un título (opcional)',
    formContent: 'Contenido de la entrada (límite de 4000 caracteres)',
    deletePrompt: '¿Está seguro de que desea eliminar esta entrada? Esta operación no se puede deshacer.',
    deleteJournal: 'Crear entrada de diario',
    journalEntry: 'Entrada de diario'
  },
  linkActivity: 'Actividades',
  linkAddProfile: 'Añadir a alguien',
  linkAllergies: 'Alergias',
  linkLearnMore: 'Calcium',
  linkHome: 'Inicio',
  linkMedications: 'Medicamentos',
  linkNutrition: 'Nutrición',
  linkObservations: 'Vitales',
  privacyPolicy: 'Política de privacidad',
  eula: 'Términos y condiciones',
  lastName: 'Apellidos',
  linkProfileList: 'Personas',
  linkReproductiveHealth: 'Salud reproductiva',
  linkSelfProfile: 'Yo',
  linkSignIn: 'Iniciar sesión',
  linkSignout: 'Cerrar sesión',
  listActivityHeader: 'Actividades',
  listGroupsHeader: 'Grupos',
  listMedicationDosesHeader: 'Dosis del medicamento',
  listMedicationsHeader: 'Medicamentos',
  listMoreHeader: 'Más opciones',
  listPeopleHeader: 'Personas',
  listProfileHeader: 'Perfil',
  loading: 'Cargando...',
  logButton: 'Añadir',
  signInError: 'Error de inicio de sesión',
  signInErrorMessage: 'Dirección de correo electrónico o contraseña no válidas',
  marketplace: {
    label: 'Mercado',
    sponsoredAds: 'Patrocinado',
    noDataText: 'Actualmente no hay artículos en el mercado.',
    ads: {
      omronBp7900: {
        title: 'Omron - BP7900, monitor inalámbrico completo de presión arterial del brazo superior + ECG',
        header: 'Omron - BP7900',
        summary: 'Monitor inalámbrico completo de presión arterial del brazo superior + ECG'
      }
    }
  },
  menu: 'Más',
  more: 'más',
  modal: {
    yes: 'Sí',
    no: 'No'
  },
  namePlaceholder: '(Nombre y apellido)',
  nav: {
    title: 'Más',
    dashboard: 'Panel de control',
    health: 'Datos de salud',
    pathways: 'Rutas',
    more: 'Más',
    people: 'Personas',
    preferences: 'Preferencias',
    getHelp: 'Consigue ayuda',
    marketplace: 'Mercado',
    hipaaAuth: 'Autorización de HIPAA',
    privacyPolicy: 'Política de privacidad',
    termsConditions: 'Términos y condiciones',
    signOut: 'Cerrar sesión'
  },
  noActivities: 'Actividad reciente',
  noMedications: 'Agregue su primer medicamento',
  noMedicationsPlaceholder: 'Aún no se han registrado medicamentos.',
  noMedicationDosesPlaceholder: 'Hoy no se han registrado dosis de medicamentos.',
  nonAuthWallPage: {
    nav: {
      about: 'Acerca de',
      partners: 'Socios'
    },
    header: 'Empezar',
    subheadCovid: 'Detección de COVID-19 para su equipo: fácil.',
    subheadDash: 'Haga crecer su negocio de entrenamiento personal hoy.',
    subheadMember: 'Empoderamiento de la atención sanitaria impulsada por las personas.',
    subheadProvider: 'Piensa más sana',
    headerProvider:'Crear una cuenta de calcio',
    value1: 'Acceda a su historia clínica electrónica en tiempo real.',
    value2: 'Haga seguimiento a sus síntomas y sus actividades.',
    value3: 'Informe a su médico sobre los acontecimientos.',
    value4: 'Consolide sus datos de accesorios de salud.'
  },
  notEnoughInfoForHealthScore: 'Obtenga mejores conocimientos agregando más datos.',
  notVerifiedForInvites: 'Verifique su correo electrónico para ver las invitaciones.', // 'Please confirm your email to see invites. If you cannot find the email you can resend it.'
  notifications: {
    label: 'Notificaciones',
    noDataText: 'Sin notificaciones',
    markReadError: 'Se ha producido un error al marcar la notificación como leída',
    markDeletedError: 'Se ha producido un error al eliminar la notificación',
    deleteNotification: 'Eliminar la notificación'
  },
  offlineErrorMainText: 'Usted está desconectado',
  organizations: {
    admins: 'Administradores',
    adminsDisclaimer: 'Estos son los administradores de este grupo. Si esta información parece sospechosa, rechace la invitación.',
    title: 'Grupos',
    createGroupLink: 'Crear grupo',
    createDescription: 'Cree un grupo de salud confiable y comparta noticias importantes de salud con los miembros que invite.',
    joinGroupLink: 'Unirse al grupo', // 'Join group by invite code', // non-official
    groupNameLabel: 'Nombre del grupo',
    groupDescriptionLabel: 'Descripción del grupo (opcional)',
    statuses: {
      none: 'Ninguno',
      pending: 'Pendiente',
      declined: 'Rechazado',
      active: 'Activa',
      removed: 'Eliminado'
    },
    roles: {
      none: 'Ninguno',
      admin: 'Administrador',
      member: 'Miembro'
    },
    details: {
      header: 'Detalles del grupo',
      membersListHeader: 'Afiliación'
    },
    loadError: 'Se ha producido un error al cargar sus grupos.',
    leavePrompt: '¿Está seguro de que desea salir de {0}?',
    leavePromptForAdmin: '¿Está seguro de que desea salir de {0}?\n\nSi lo hace, seguirá siendo administrador de {0}, pero sus datos de salud no se compartirán con {0}.',
    leaveOrgHeader: 'Abandonar grupo',
    organizationInviteHeader: 'Invitación de grupo',
    organizationInviteCodeHeader: 'Código de invitación',
    inviteCodeLabel: 'Código de invitación',
    inviteCodeIntro: 'Ingrese el código de invitación del grupo.',
    inviteCodeSearching: 'Buscando...',
    inviteCodeFound: '¡Encontrado!',
    inviteCodeAlreadyUsed: 'Código de invitación ya utilizado.',
    inviteCodeNotFound: 'Código de invitación no encontrado.',
    removingOrg: 'Saliendo de {0}...',
    removedOrg: 'Quedan {0}',
    notVerifiedForInvites: 'Verifique su correo electrónico para ver las invitaciones a unirse a grupos.', // 'Please verify your email to see your invites.', // non-official
    viewDetails: 'Ver detalles',
    cannotRespond: 'No puede aceptar una invitación para unirse a un grupo con un perfil compartido. Elija un perfil diferente para responder a esta invitación.',
    joinHeader: 'Unirme a {0}',
    joinHeaderForProfile: 'Unirme a {0} para {1}', // rough translation 'Group {0} Invitation For {1}', //group invite for profile
    programInviteHeaderForProfile: 'Invitacion de Pathway de {0}',
    expectationMessage: '{2} se une como grupo {0}.',
    expectationMessageAdmin: 'Has sido invitado a ser {0} de {1}. Unirme como {2}.',
    accepting: 'Aceptando...',
    accepted: 'Aceptado',
    declining: 'Rechazando...',
    declined: 'Rechazado',
    membershipAlreadyExists: '{0} ya es miembro de {1}. Elija un perfil diferente para responder a esta invitación.',
    joinChildOrganization: 'Conviértase en {0}', // 'Join the group {0}, part of {1}.', // needs translation
    joinRootOrganization: 'Conviértase en {0}', // 'Join the group {0}.' // needs translation
    recommendedByOrg: 'Recomendado por {0}',
    learnMore: 'Más información',
    dataShared: 'Los siguientes datos se compartirán con {0}:',
    permissions: {
      adminHeader: 'Administración de grupo',
      adminMessageSingle: 'Este administrador de grupos es {0}.',
      adminMessageMultiple: 'Los administradores son {0} y {1}.',
      adminMessageExtended: 'Los administradores incluyen {0} y {1} más.',
      observationHeader: 'Datos compartidos',
      observationMessageDefault: 'Las respuestas a las preguntas se compartirán con los administradores del grupo.',
      observationMessageSingle: 'Las respuestas a las preguntas y {0} se compartirán con los administradores del grupo.',
      observationMessageMultiple: 'Las respuestas a las preguntas, {0} y {1} se compartirán con los administradores del grupo.',
      pathwayHeader: 'Inscripciones en rutas',
      pathwayMessageSingle: 'Será inscrito en la ruta {0}.',
      pathwayMessageMultiple: 'Será inscrito en las rutas {0} y {1}.',
      choosePathway: 'Elija las vías en las que desea inscribirse',
      selectAllPrograms: 'Seleccionar Todo'
    }
  },
  page: 'Página',
  page404: {
    titleTab: 'Página no encontrada',
    heading: '¡Vaya!',
    subhead: 'Al parecer no podemos encontrar la página que está buscando.',
    error: 'Código de error: 404',
    goBackButton: 'Inicio'
  },
  preferencesCelsiusHelper: '(Celsius)',
  preferencesCelsiusLabel: '°C',
  preferencesFahrenheitHelper: '(Fahrenheit)',
  preferencesFahrenheitLabel: '°F',
  preferencesImperialHelper: '(ft, in, lb, oz)',
  preferencesImperialLabel: 'Imperial',
  preferencesMetricHelper: '(m, cm, kg, g)',
  preferencesMetricLabel: 'Métrico',
  preferencesOn: 'Sí',
  preferencesOff: 'No',
  preferencesShowFeaturePreviews: 'Vista previa de las próximas funciones',
  preferencesShowFeaturePreviewsDescription: 'Al seleccionar «sí», las funciones están disponibles en la aplicación en modo de vista previa. Ayúdenos a decidir qué construir próximamente votando y compartiendo su opinión.',
  preferencesTemperatureUnitHeader: 'Unidades de temperatura',
  preferencesTitle: 'Preferencias',
  preferencesUnitHeader: 'Unidades',
  preview: {
    devicesLabel: 'Conectar dispositivos portátiles',
    devicesName: 'Dispositivos portátiles',
    devicesDetails: 'Conecte dispositivos portátiles a fin de obtener una visión general clara de su progreso y acceder a informes de datos clínicos.',
    healthRecordsLabel: 'Conecte su historia clínica',
    healthRecordsName: 'Historia clínica',
    healthRecordsDetails: 'Conecte su historia clínica electrónica para ver y administrar fácilmente sus datos.'
  },
  previewGroupMembers: 'Podrá incluirse a usted, sus amigos, familiares o proveedores de atención médica',
  previewGroupName: 'Calcium',
  programs: {
    detailsComplete: 'Ruta completa',
    detailsCompleteButton: 'Completar ruta',
    detailsCompletedOnDate: '{0} completada',
    detailsDayProgress: 'Día {0} de {1}',
    detailsEndedOnDate: '{0} finalizada',
    detailsEndingDate: '{0} finalizando',
    detailsResources: 'Recursos',
    detailsTasksEmpty: 'No hay tareas',
    detailsTasksAvailableEmpty: 'Aún no hay tareas para completar',
    detailsTasksComplete: '{0} de {1} tareas completadas',
    detailsAvailableTaskCompleteSingle: 'La tarea actual se ha completado',
    detailsAvailableTasksComplete: 'Todas las tareas {0} se han completado',
    detailsUnenrollButton: 'Salir de la ruta',
    detailsUnenrollHeader: '¿Desea salir de esta ruta?',
    detailsUnenrollText: 'Salirse puede hacer que pierda la conexión de datos y de la evolución asociada con esta ruta.',
    enrollButton: 'Iniciar',
    enrollDuration: 'Duración: {0} días',
    enrollDurationSingular: 'Duración: {0} día',
    enrollDurationShort: '{0} Días',
    enrollDurationShortSingular: '{0} Día',
    enrollHeader: 'Iniciar',
    enrollTitleTab: 'Inscripción en la ruta',
    willBeEnrolled: 'Será inscrito en la ruta {0}',
    sharedDataHeader: 'Datos compartidos',
    willSeeData: 'Las respuestas a esta ruta serán compartidas.',
    sharedData: 'La ruta solicita datos {0}.',
    listHeader: 'Rutas',
    completedListHeader: 'Rutas completadas',
    listItemEnrolled: 'Activa',
    listPlaceholderNoAvailablePrograms: 'No hay rutas disponibles actualmente',
    listPlaceholderNoCompletedPrograms: 'Aún no se ha completado ninguna ruta',
    listTabAvailable: 'Disponible',
    listTabEnrolled: 'Inscrito',
    listTabCompleted: 'Completado',
    programShareTitle: 'Ruta Calcium en curso',
    programShareText: 'Estoy siguiendo la ruta {0} en Calcium',
    programShareDialogTitle: 'Compartir con mis amigos',
    readyToComplete: '¡Listo para completar!',
    reEnrollText: 'Continúe su viaje; vuelva a inscribirse en esta ruta y siga los próximos pasos.',
    reEnrollLabel: 'Reinscribirme',
    reEnrollingLabel: 'Inscribiendo...',
    reEnrollSkipLabel: 'Saltar',

    reEnrollUpgradeLabel: 'Actualiza', // 'Update' // non-official
    reEnrollingUpgradeLabel: 'Actualizando ...', // 'Updating ...' // non-official
    referralListTitle: 'Rutas sugeridas',
    successPromptHeader: '¡Hola, buen trabajo!',
    successPromptCompleteHeader: '¡Buen trabajo!',
    successPromptCompleteText: 'Está listo para marcar esta ruta como completada, ¡adelante!',
    successPromptCompleteButton: 'Completar',
    successPromptShareOrReferralCTA: '¿Por qué no compartir sus logros o dar el siguiente paso e inscribirse en otra ruta?',
    successPromptShareCTA: '¿Por qué no compartir su logro?',
    successPromptShareTitle: 'Ruta de Calcium completada',
    successPromptShareText: 'He completado la ruta {0} en Calcium',
    successPromptShareDialogTitle: 'Compartir con mis amigos',
    taskListTitle: 'Tareas',
    taskListTitleCurrent: 'Tareas actuales',
    taskListTitleRemaining: 'Tareas restantes opcionales',
    taskListTitleMissed: 'Tareas perdidas',
    taskListTitleCompleted: 'Tareas completadas',
    taskListTitleFuture: 'Tareas futuras',
    taskListNextAt: 'Próxima {0}',
    taskListDueBy: 'Vencida {0}',
    taskListCompleted: '{0} completada',
    tileLink: 'Explorar rutas',
    toastMessageComplete: 'Ruta completa',
    toastMessageCompleting: 'Completando ruta',
    toastMessageEnrolling: 'Aceptando ruta',
    toastMessageEnrolled: 'Ruta aceptada',
    toastMessageLeaving: 'Abandonando ruta',
    toastMessageLeft: 'Ruta abandonada',
    upgradeAvailableBanner: 'Actualiza a la nueva versión de este camino.', // 'Update to the new version of this pathway.', // non-official
    upgradePromptHeader: 'Actualización disponible', // 'Pathway Update Available', // non-official
    upgradePromptText: 'La ruta ha cambiado. Por favor, pulse el botón para continuar.', // 'Your pathway has changed. Please tap the button below to continue.', // non-offical
    upgradePromptCTA: 'Actualización disponible', // 'Update Available', // non-official
    weightProgramAction: 'Registro de peso',
    weightProgramCallToAction: 'Registro de peso de hoy',
    weightProgramHistory: 'Historial de peso',
    weightProgramLinkWeightLoss: 'Pérdida de peso'
  },
  register: 'Registrarme',
  registerRightCardCovid: {
    title: '¡Calcium hace que sea fácil comenzar!',
    tick1: 'Prueba gratuita de 30 días para hasta 10 miembros del equipo.',
    tick2: 'No se requiere tarjeta de crédito.'
  },
  registerRightCardDash: {
    title: '¡Mantenga a las clientes comprometidas y obtenga mejores ideas!',
    subtitle: 'La prueba gratuita de un mes incluye:',
    tick1: 'Hasta 5 clientes conectadas.',
    tick2: 'Acceso al Panel de Instructor exclusivo y a una biblioteca de entrenamientos.​',
    tick3: 'Conéctese a los dispositivos desgastables de su cliente.'
  },
  registerStep: {
    step1: 'Paso 1',
    step1Title: 'Configurar la cuenta',
    step2: 'Paso 2',
    step2Title: 'Elija su plan',
    step3: 'Paso 3',
    step3Title: 'Vaya a su panel de control'
  },
  registerAlreadyHaveAccount: '¿Ya tiene cuenta?',
  registerConfirmEmail: 'Gracias por crear su cuenta. Por favor revise su correo electrónico y confirme su dirección de correo electrónico.',
  registerConfirmEmailSubtext:'¿Necesita un nuevo correo electrónico de confirmación? Haga clic aquí ',
  registerAlreadyHaveCalciumAccount: '¿Ya tienes una cuenta de Calcio?',
  
  registerLearnMore: 'Conozca más sobre {0}.',
  registerSignupCovid: 'Crea tu cuenta de organización',
  registerSignupDash: 'Crea tu cuenta de organización',
  registerSignupMember: 'Registrarme',
  registerSignupProvider: 'Crea tu cuenta de organización',
  registerThankYou: 'Gracias',
  reload: 'Inténtelo de nuevo',
  confirmYourEmailAlert:'confirme su email',
  resendVerification: 'Reenviar correo electrónico de confirmación',
  resetPasswordTitle: 'Restablecer la contraseña',
  resetPasswordSubtitle: 'Introduzca la dirección de correo electrónico asociada a su cuenta.',
  resetPasswordSuccessTitle: 'Gracias',
  resetPasswordSuccessSubtitle: 'Compruebe su correo electrónico y haga clic en el enlace para restablecer su contraseña.',
  resetPasswordSuccessSignInText: 'Navegar a',
  saved: '¡Guardado!',
  saving: 'Guardando...',
  closeConfirmationContent: 'Si sale ahora, perderá todo el progreso con esta tarea.¿Seguro que quieres salir? Haga clic en el botón completar al final de esta tarea para enviarlo.',
  backToTaskLabel: 'Volver a la tarea',
  closeTaskLabel: 'Cerrar',
  sending: 'Enviando...',
  sent: '¡Enviado!',
  selectPlaceholder: '- Seleccionar',
  sharing: {
    title: 'Compartir perfil',
    description: 'Invite a otra persona a compartir la administración de su perfil seleccionado.',
    inviteEmail: 'Enviar invitación por correo electrónico',
    legalText: 'Al hacer clic en el botón anterior, acepta compartir la administración del perfil con otro usuario que pueda ver y modificar la información de salud de este perfil.',
    noPendingInvites: 'No hay invitaciones de perfil pendientes',
    pendingInvites: 'Invitaciones pendientes',
    invitedBy: 'A {0} le gustaría que manejaras el perfil de {1}.', //'{0} would like you to manage {1}\'s profile.', // non-official, needs update
    incomingTitle: 'Invitaciones compartidas entrantes',
    sharingTitle: 'Compartiendo',
    accepted: '¡Aceptado!',
    declined: '¡Rechazado!',
    pending: 'Pendiente',
    inviteSentOn: 'Enviado el: {0}',
    accessSince: 'Desde: {0}',
    whoHasAccess: 'Quién tiene acceso',
    shareToSeeData: 'Administre quién en su círculo puede acceder y actualizar la información sobre este perfil.',
    callToAction: 'Comience a compartir ahora',
    revoke: 'Dejar de compartir',
    delete: 'Borrar',
    revokeModalTitle: 'Revocar acceso al perfil',
    revokeModalText: '¿Está seguro de que desea revocar permanentemente el acceso a {0}?',
    inviteCancelModalTitle: 'Eliminar invitación de perfil',
    inviteCancelModalText: '¿Está seguro de que desea eliminar la invitación a {0}?',
    sharedWithYou: 'Compartido usted',
    accepting: 'Aceptando...',
    declining: 'Rechazando...',
    revokingAccess: 'Revocando el acceso...',
    accessRevoked: '¡Acceso revocado!',
    removeProfileModalTitle: 'Quitar perfil',
    removeProfileModalText: '¿Estás seguro de que quieres eliminar el perfil {0}?'
  },
  signingIn: 'Iniciando sesión...',
  signingOut: 'Cerrando sesión...',
  simpleDateFormat: 'MM/DD/YY', // locale
  simpleDateTimeFormat: 'MM/DD/YY hh:mm A', // locale
  simpleTimeFormat: 'h:mm A',
  socialShare: {
    fallbackCopied: 'Copiado al portapapeles',
    desktopTitle: 'Copiar enlace',
    menuShareDialogTitle: 'Invitar amigos',
    copyAppLink: 'Copiar el enlace de la aplicación',
    menuShareText: 'Únase a mí en Calcium'
  },
  sponsored: 'Patrocinado',
  states: {
    stateAL: 'Alabama',
    stateAK: 'Alaska',
    stateAS: 'Samoa Americana',
    stateAZ: 'Arizona',
    stateAR: 'Arkansas',
    stateCA: 'California',
    stateCO: 'Colorado',
    stateCT: 'Connecticut',
    stateDE: 'Delaware',
    stateDC: 'Distrito de Columbia',
    stateFM: 'Estados Federados de Micronesia',
    stateFL: 'Florida',
    stateGA: 'Georgia',
    stateGU: 'Guam',
    stateHI: 'Hawái',
    stateID: 'Idaho',
    stateIL: 'Illinois',
    stateIN: 'Indiana',
    stateIA: 'Iowa',
    stateKS: 'Kansas',
    stateKY: 'Kentucky',
    stateLA: 'Luisiana',
    stateME: 'Maine',
    stateMH: 'Islas Marshall',
    stateMD: 'Maryland',
    stateMA: 'Massachusetts',
    stateMI: 'Michigan',
    stateMN: 'Minnesota',
    stateMS: 'Mississippi',
    stateMO: 'Missouri',
    stateMT: 'Montana',
    stateNE: 'Nebraska',
    stateNV: 'Nevada',
    stateNH: 'Nuevo Hampshire',
    stateNJ: 'Nueva Jersey',
    stateNM: 'Nuevo México',
    stateNY: 'Nueva York',
    stateNC: 'Carolina del Norte',
    stateND: 'Dakota del Norte',
    stateMP: 'Islas Marianas del Norte',
    stateOH: 'Ohio',
    stateOK: 'Oklahoma',
    stateOR: 'Oregón',
    statePW: 'Palau',
    statePA: 'Pennsylvania',
    statePR: 'Puerto Rico',
    stateRI: 'Rhode Island',
    stateSC: 'Carolina del Sur',
    stateSD: 'Dakota del Sur',
    stateTN: 'Tennessee',
    stateTX: 'Texas',
    stateUT: 'Utah',
    stateVT: 'Vermont',
    stateVI: 'Islas Vírgenes',
    stateVA: 'Virginia',
    stateWA: 'Washington',
    stateWV: 'West Virginia',
    stateWI: 'Wisconsin',
    stateWY: 'Wyoming'
  },
  tileActivityTitle: 'Actividad: 7 días',
  tileActivityUnits: 'minutos',
  tileBodyMeasurementsTitle: 'IMC',
  noBodyMeasurementsData: 'Añada su altura y peso',
  tileBodyMeasurementsUnits: '(Sobrepeso)',
  tileMedicationsLabel: 'Levotiroxina',
  tileMedicationsTitle: 'Medicamentos',
  tileMedicationsUnits: 'horas desde la última toma',
  timeline: {
    title: 'Calendario de 7 días',
    noData: {
      header: 'Vea su calendario',
      message: 'Vea todos sus datos de salud de los últimos 7 días en un solo lugar.',
      callToAction: 'Intente añadir su temperatura para comenzar.',
      buttonText: 'Añadir temperatura',
      linkText1: 'Sincronice su historia clínica',
      linkText2: 'Conecte un dispositivo'
    },
    filtersApplied: 'Filtros aplicados.',
    applyFilters: 'Aplicar',
    clearFilters: 'Borrar'
  },
  tooltipAddMedication: 'Añadir medicamento',
  tryAgain: 'Haga clic para volver a intentarlo',
  units: {
    unknown: 'N/A',
    beatsPerMinute: 'bpm',
    degreesCelsius: 'ºC',
    degreesFahrenheit: 'ºF',
    centimeters: 'cm',
    inches: 'in',
    kilograms: 'kg',
    pounds: 'lb',
    meters: 'm',
    steps: 'pasos',
    percent: '%',
    internationalUnit: 'UI',
    litersPerMinute: 'l/min',
    gramsPerDeciliter: 'g/dl',
    mgdL: 'mg/dL',
    millimolesPerLiter: 'mmol/L',
    millimetersOfMercury: 'mmHg',
    cubicMetersPerSecond: 'm³/s',
    pascal: 'Pa'
  },
  userAgent: 'Agente de usuario',
  validationBirthdate: 'La fecha de nacimiento no es válida',
  validationDate: 'La fecha no es válida',
  validationDateInPast: 'La fecha debe estar en el pasado',
  validationDateInRange: 'La fecha no está en el rango válido',
  validationInvalidEmail: 'Debe ser un correo electrónico válido',
  validationInvalidHeight: 'La altura es demasiado grande',
  validationMaxLength: 'longitud máxima de caracteres',
  validationMaxCharLength: '{0} longitud máxima de caracteres',
  validationMaxValue: 'Debe ser a lo sumo',
  validationMinLength: 'longitud máxima de caracteres',
  validationMinValue: 'Debe ser al menos de',
  validationNumber: 'Debe ser un número',
  validationRequired: 'Requerido',
  validationSsn4: 'Se requieren exactamente cuatro dígitos',
  validationTimeLessThan1Day: 'El tiempo debe ser inferior a 24 horas.',
  verifyEmailBody: 'No puede acceder a ciertas páginas hasta que se confirme su dirección de correo electrónico. Verifique su correo electrónico y haga clic en el enlace para confirmar su dirección de correo electrónico.',
  verifyEmailHeader: 'Por favor, confirme su dirección de correo electrónico',
  verifyEmailResentHeader: 'Nueva confirmación de correo electrónico enviada',
  verifyEmailBodyMobile: 'Si ha verificado su correo electrónico y aún ve este mensaje, reinicie la aplicación.', //'If you have confirmed your email and still see this message please restart the app.',
  verifyEmailBodyBrowser: 'Si ha verificado su correo electrónico y aún ve este mensaje, actualice el navegador.', //'If you have confirmed your email and still see this message please refresh the browser.',
  verifyEmailAlertMessage:'Antes de iniciar sesión, debe confirmar su correo electrónico. Se ha enviado un correo electrónico al correo electrónico que utilizó para crear su cuenta.',
  vitalsHeader: 'Vitales',
  welcome: 'Bienvenido',
  signUpFlow: {
    subHead: 'Un pase completo de dos semanas de acceso a nuestra plataforma.',
    steps: {
      step1: {
        step1Title: 'Paso 1',
        step1Tag: 'Crear una cuenta',
        formLabels: {
          firstName: 'Primer nombre',
          lastName: 'Apellido',
          phoneNumber: 'Número de teléfono',
          solution: 'Solución',
          email: 'Correo electrónico (nombre de usuario)',
          password: 'Contraseña ({0} caracteres como mínimo)'
        },
        solutionOptions: {
          infectiousDiseaseCOVID19: 'Enfermedad Infecciosa (COVID-19)'
        },
      },
      step2: {
        step2Title: 'Paso 2',
        formLabels:{
          companyName: 'nombre de empresa',
          numberOfEmployees: 'Número de empleados',
          numberOfPatients: 'Nombre de patients',
          companyLocation: 'Ubicación de la compañía',
          howDidyouHearAboutCalcium: '¿Cómo se enteró del calcio?',
        },
        step2Tag: 'Comienza tu prueba gratuita',
        btnText: 'Comienza tu prueba gratuita'
      }
    },
    validationMessages:{
      required: 'Este campo es requerido.',
      invalidPhoneNumber: 'Por favor, introduzca un número de teléfono válido.',
      invalidPassword: 'longitud máxima de caracteres.',
      invalidEmail: 'Por favor introduzca un correo electrónico válido.',
      emailExists:'Ya existe Este email.',
      error: 'Ocurrió un error. Inténtalo de nuevo.',
      invalidEmployeeNumbers: 'Ingrese números de empleados válidos.',
      invalidPatientNumbers: 'Ingrese números de pacientes válidos'
    },
    alreadyHaveAccount: '¿Ya tienes una cuenta?',
    linkSignIn: 'Registrarse'
  },
  verifyEmail:{
    confirm: 'Verifique su correo electrónico',
    sentCode: 'Envió un código de 4 dígitos a {0}',
    contact: 'Contacta con nosotros',
    enterCode: 'Inserte el código',
    trouble: '¿Teniendo problemas?',
    verifying: 'Verificando...',
    verified: 'verificada',
    resend: 'Reenviar codigo',
    error: 'Código de verificación no válido. Verifique el código en su correo electrónico e introdúzcalo nuevamente',
    failed: 'Fallo en la verificación',
    verifySuccess: 'Verificación exitosa',
    successMsgBanner:'Su correo electrónico se verificó con éxito',
    successMsgDescription: 'Ahora puede iniciar sesión con {0}.',
    redirectMsg:'Serás redirigido en 10 segundos'
  }
};
