exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "@-webkit-keyframes spin{from{transform:rotate(0deg)}to{transform:rotate(359deg)}}@keyframes spin{from{transform:rotate(0deg)}to{transform:rotate(359deg)}}@-webkit-keyframes fadein{from{opacity:0}to{opacity:1}}@keyframes fadein{from{opacity:0}to{opacity:1}}@-webkit-keyframes fadeScale{from{opacity:0;transform:scale(0)}to{opacity:1;transform:scale(1)}}@keyframes fadeScale{from{opacity:0;transform:scale(0)}to{opacity:1;transform:scale(1)}}@-webkit-keyframes fadeRise{from{opacity:0;transform:translateY(100%)}to{opacity:1;transform:translateY(0%)}}@keyframes fadeRise{from{opacity:0;transform:translateY(100%)}to{opacity:1;transform:translateY(0%)}}@-webkit-keyframes moveGradient{0%{transform:translateX(0%)}100%{transform:translateX(75%)}}@keyframes moveGradient{0%{transform:translateX(0%)}100%{transform:translateX(75%)}}@-webkit-keyframes fadeGrow{from{transform-origin:top center;opacity:0;transform:scaleY(0)}to{transform-origin:top center;opacity:1;transform:scaleY(1)}}@keyframes fadeGrow{from{transform-origin:top center;opacity:0;transform:scaleY(0)}to{transform-origin:top center;opacity:1;transform:scaleY(1)}}@-webkit-keyframes growRight{from{transform-origin:top left;transform:scaleX(0)}to{transform-origin:top left;transform:scaleX(1)}}@keyframes growRight{from{transform-origin:top left;transform:scaleX(0)}to{transform-origin:top left;transform:scaleX(1)}}@-webkit-keyframes fadeFall{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0%)}}@keyframes fadeFall{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0%)}}.share-icon-container{color:#54c2b6;grid-area:right;display:flex;align-items:center;padding:5px}.share-icon-container .action-text{font-weight:normal;font-size:.9em;vertical-align:text-top;padding-left:.5em;display:none}@media(min-width: 768px){.share-icon-container .action-text{display:inline}}", ""]);

// Exports
exports.locals = {
	"colorTeal": "#54c2b6",
	"colorTealRedChannel": "84",
	"colorTealGreenChannel": "194",
	"colorTealBlueChannel": "182",
	"colorTealDark": "#26a995",
	"colorTeal50": "rgba(84,194,182,.5)",
	"colorTealScreened": "#54c2b6",
	"colorWhiteOff": "#f2f2f2",
	"colorWhite": "#fff",
	"colorWhiteTrans": "rgba(255,255,255,0)",
	"colorWhite50": "rgba(255,255,255,.5)",
	"colorBlack": "#333",
	"colorGrayDark": "#4f4f4f",
	"colorGray": "#828282",
	"colorGrayLight": "#e0e0e0",
	"colorBlue": "#049fda",
	"colorBlueScreened": "#049fda",
	"colorMagenta": "#ea3b7d",
	"colorMagentaScreened": "#ea3b7d",
	"colorYellow": "#fed504",
	"colorYellowDark": "#d8b129",
	"colorYellowScreened": "#fed504",
	"colorUi1": "rgba(4,159,218,.3)",
	"colorUi2": "rgba(84,194,182,.3)",
	"colorUi3": "rgba(234,59,125,.3)",
	"colorUi4": "rgba(254,213,4,.3)",
	"colorUi5": "rgba(31,79,228,.3)",
	"colorUi6": "rgba(177,208,21,.3)",
	"colorUi7": "rgba(228,34,255,.3)",
	"colorUi8": "rgba(255,157,39,.3)",
	"colorUi9": "rgba(66,32,208,.3)",
	"colorUi10": "rgba(26,255,198,.3)",
	"colorUi11": "rgba(205,38,26,.3)",
	"colorUi12": "rgba(247,184,50,.3)",
	"colorUi13": "rgba(146,79,207,.3)",
	"colorUi14": "rgba(98,205,36,.3)",
	"colorUi15": "rgba(255,24,9,.3)",
	"colorUi16": "rgba(207,111,79,.3)",
	"colorGreen": "#0acf97"
};