import React, { Component } from 'react';
import { LinkWrapper, NonAuthenticatedPage } from 'shared';
import local from 'services/localization/local';
import { appNames, localStoragePaths } from 'services/constants';
import VerifyEmailLink from 'identity/verify-email/VerifyEmailLink';
import { connect } from 'react-redux';

class RegistrationThankYou extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isRegisteredForPortal } = this.props;
    let app = localStorage.getItem(localStoragePaths.app);
    if(isRegisteredForPortal == true){
      app = appNames.provider; //appName should be Provider as of now after completing steps1,2 signup, need to update based on the product in future.
    }
    var signinlink = '/signin';
    if (app) {
      signinlink = signinlink + '?app=' + app;
    }

    return (
      <NonAuthenticatedPage id='register-thank-you' titleTabText={local.registerThankYou}>
        <p>
          {local.registerConfirmEmail}
        </p>
        <p >
          {local.registerConfirmEmailSubtext}
          <VerifyEmailLink />
        </p>
        <br />
        <p><LinkWrapper id='link-to-sign-in' className='button blue w-100' to={signinlink}>{local.linkSignIn}</LinkWrapper></p>
      </NonAuthenticatedPage>
    );
  }
}

const mapStateToProps = state => ({
  isRegisteredForPortal: state.identity.isRegisteredForPortal
});

export default connect(mapStateToProps)(RegistrationThankYou);
