import React from 'react';
import PropTypes from 'prop-types';
import './card-component.scss';

export default function CardList(props) {
  return (
    <ul id={props.id} className="card-list-component" data-testid={props.testId}>
      {React.Children.map(props.children, (child, i) => <li className="card-list-item" key={`cardlist-${i + 1}`}>{child}</li>)}
    </ul>
  );
}

CardList.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  testId: PropTypes.string
};
