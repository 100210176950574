// Client
export const ToastStyle = {
  Processing: 1,
  Info: 2,
  Success: 3,
  Error: 4
};

export const AlertSeverityStatus = {
  Unknown: 0,
  Verified: 1,
  NotVerified: 2
};

// Server
export const ErrorCode = {
  General: 1000,
  NoHipaaConsent: 1005,
  HipaaConsentExpired: 1006,
  HipaaConsentVersionOutdated: 1007,
  InviteAlreadyExists: 1101,
  InviteSelf: 1102,
  InviteUsed: 1103,
  InviteExistingRelationship: 1104,
  InviteNotFound: 1105,
  RegisterPasswordError: 1106,
  RegisterPasswordStrengthError: 1107,
  RegisterUserExistsError: 1108,
  RegisterUserInvalidEmailFormat: 1109
};

export const HealthDataSource = {
  Unknown: 0,
  SelfReported: 1,
  Validic: 2,
  HealthGorilla: 3
};

export const Unit = {
  Unknown: 0,
  BeatsPerMinute: 1,
  Celsius: 2,
  Fahrenheit: 3,
  Centimeters: 4,
  Inches: 5,
  Kilograms: 6,
  Pounds: 7,
  Meters: 8,
  Count: 9,
  Percent: 10,
  InternationalUnit: 11,
  LiterPerMinute: 12,
  GramsPerDeciliter: 13,
  MilligramsPerDeciliter: 14,
  MillimolesPerLiterGlucose: 15,
  MillimetersOfMercury: 16,
  CubicMetersPerSecond: 17,
  Pascal: 18
};

export const ObservationType = {
  None: 0,
  InstantaneousHeartRate: 1,
  BodyTemperature: 2,
  Height: 3,
  Weight: 4,
  Steps: 5,
  BodyArms: 6,
  BodyChest: 7,
  BodyFat: 8,
  BodyHips: 9,
  BodyThighs: 10,
  BodyWaist: 11,
  BloodOxygen: 12,
  Insulin: 13,
  PeakFlow: 14,
  BloodGlucose: 15,
  Systolic: 16,
  Diastolic: 17
};

export const IntensityType = {
  None: 0,
  Low: 1,
  Medium: 2,
  High: 3
};

export const ActivityType = {
  None: 0,
  Workout: 1
};

export const NotificationType = {
  ReadOnly: 0,
  InternalUrl: 1,
  ExternalUrl: 2
};

export const TaskType = {
  Checklist: 1,
  Observation: 2,
  Resource: 3,
  Interview: 4
};

export const InterviewContentType = {
  Static: 1,
  Boolean: 2,
  Select: 3
};

export const healthDataType = {
  ObservationTypeInstantaneousHeartRate: 1001,
  ObservationTypeBodyTemperature: 1002,
  ObservationTypeHeight: 1003,
  ObservationTypeWeight: 1004,
  ObservationTypeSteps: 1005,
  ObservationTypeBodyArms: 1006,
  ObservationTypeBodyChest: 1007,
  ObservationTypeBodyFat: 1008,
  ObservationTypeBodyHips: 1009,
  ObservationTypeBodyThighs: 1010,
  ObservationTypeBodyWaist: 1011,
  ObservationTypeBloodOxygen: 1012,
  ObservationTypeInsulin: 1013,
  ObservationTypePeakFlow: 1014,
  ObservationTypeBloodGlucose: 1015,
  ObservationTypeSystolic: 1016,
  ObservationTypeDiastolic: 1017,
  Medication: 2001,
  MedicationDose: 2002,
  Activity: 3001,
  Condition: 4001,
  Encounter: 4002,
  Procedure: 4003,
  Immunization: 4004,
  DiagnosticReport: 4005,
  JournalEntry: 5001
};

export const OrganizationMemberStatus = {
  None: 0,
  Pending: 1,
  Declined: 2,
  Active: 3,
  Removed: 4
};

export const OrganizationMemberRole = {
  None: 0,
  Admin: 1,
  Member: 2
};

export const MetricAlertSeverity = {
  Unknown: 0,
  None: 1,
  Low: 2,
  Medium: 3,
  High: 4,
  Critical: 5,
  Urgent: 6
};

export const ProgramCompletionCode = {
  Complete: 1,
  Unenrolled: 2,
  Upgraded: 3
};

export const ProfileRelationshipType = {
  Unknown: 0,
  Self: 1,
  Owner: 2,
  Shared: 3
};

export const OrganizationStatus = {
  Pending: 0,
  Active: 1,
  Deleted: 2,
  Declined: 3
};

export const SubscriptionProducts = {
  CovidPortal: '641be7a8-8c53-4147-b4c1-33e7f17b442f',
  FitwellPortal: '9fc79b04-2d25-4898-859c-3dc3d90277b5',
  ProviderPortal:'40edfae4-d2b0-45d4-b57b-5e605ab3f014',
  Potal30Covid: 'a807815f-523a-448a-94ca-516076ba5e23'
};
export const App = {
  covid:'covid',
  dash:'dash',
  provider:'provider',
  member:'member'
};
