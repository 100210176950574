import React from 'react';
import pathToRegexp from 'path-to-regexp';

export const routePaths = {
  root: '/',
  navigate: '/navigate/:routeId',
  feedbackHealthSync: '/feedback/health-sync',

  // Health Data
  health: '/health',
  healthActivityAdd: '/health/activity/add',
  healthActivity: '/health/activity',
  healthScoreDetails: '/health/health-score',
  healthBodyMeasurements: '/health/body-measurements',
  healthConditions: '/health/conditions',
  healthConnect: '/health/connect',
  healthConnectIntro: '/health/connect/intro',
  healthEncounters: '/health/encounters',
  healthIdentity: '/health/connect/identity',
  healthImmunizations: '/health/immunizations',
  healthJournalAdd: '/health/journal/add',
  healthLabResults: '/health/labs',
  healthLogObservation: '/health/observation/:configId/log',
  healthObservation: '/health/observation/:configId',
  healthDevices: '/health/devices',
  healthPreview: '/health/placeholder/:metricName/:name',
  healthVitalsPreview: '/health/vitals/placeholder/:metricName/:name',
  healthProcedures: '/health/procedures',
  healthPrograms: '/health/program',
  healthTimeline: '/health/timeline',
  healthJournal: '/health/journal',
  healthBadge: '/health/public-health-badge',

  // Programs
  healthProgramDetails: '/pathways/details/:programId/:programEnrollmentId', // To be deleted - Would cause a breaking change with notifications
  programs: '/pathways',
  programsEnroll: '/pathways/enroll/:programId',
  programsList: '/pathways/list',
  programsCompleted: '/pathways/completed',
  programsPreview: '/pathways/placeholder/:metricName/:programId',
  programsDetails: '/pathways/details/:programId/:programEnrollmentId',
  programsViewData: '/pathways/view/:programId/:programEnrollmentId/:observationType',
  programsRecordData: '/pathways/record/:programId/:programEnrollmentId/:observationType/:taskId?',
  healthProgramRecordData: '/pathways/record/:programId/:programEnrollmentId/:observationType/:taskId?', // To be deleted - Would cause a breaking change with notifications
  dashboardPreview: '/placeholder/:metricName/:name',
  home: '/home',
  notifications: '/notifications',
  people: '/people',
  peopleAdd: '/people/add',
  peopleEdit: '/people/edit',
  preferences: '/preferences',
  organizations: '/organizations',
  organizationsCreate: '/organizations/create',
  organizationsJoinCode: '/organizations/join/:inviteCode?',
  organizationsInviteDetails: '/organizations/:organizationId/invites/:membershipId/:hasProperties?',
  organizationsDetails: '/organizations/:organizationId/details',
  organizationsJoinProgram: '/organizations/:organizationId/pathways/:programId',
  sharing: '/sharing',
  sharingIncoming: '/sharing/incoming',
  sharingAdmin: '/sharing/admin',
  signout: '/signout',
  redirectToExternalURL:'/redirect-to-external-url',
  signinCallback: '/signin-callback',
  signinCapacitor: '/signin-capacitor',
  registerThankyou: '/register/thankyou',
  register: '/register',
  resetPassword: '/reset-password',
  verifyEmail: '/verify-email',
  verifyEmailCode : '/verify-email-by-code',
  verifiedEmail:'/verified-email',
  page404: '/404',
  registerOrg: '/register-org'
};

export const ProgramsContext = React.createContext({ base: routePaths.root });

export const programsContexts = {
  default: {
    base: routePaths.root
  },
  list: {
    base: routePaths.programsList
  },
  completed: {
    base: routePaths.programsCompleted
  }
};

export const buildPath = (path, params) => {
  let url = pathToRegexp.compile(path)(params);

  if (params && params.profileId) {
    url = `${url}?profileId=${params.profileId}`;
  }

  return url;
};
