import React from 'react';
import PropTypes from 'prop-types';
import { routePaths } from 'services/routing';
import { ProfileRedirect } from 'shared';

export default function ObservationRedirect(props) {
  return (
    <ProfileRedirect to={routePaths.healthObservation} params={{ configId: props.configId }} />
  );
}

ObservationRedirect.propTypes = {
  configId: PropTypes.string.isRequired
};
