import React from 'react';
import PropTypes from 'prop-types';
import './card-component.scss';

export default function Card(props) {
  const { children, className, title } = props;

  return (
    <div className="card-component">
      {title && <span className="card-title"><strong>{title}</strong></span>}
      <div className={`card-container ${className}`}>
        {children}
      </div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string
};

Card.defaultProps = {
  className: ''
};
