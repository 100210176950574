import { WebAuth } from 'auth0-js';
import IdTokenVerifier from 'idtoken-verifier';
import qs from 'qs';
import {
  authAudience,
  authClientId,
  authDomain,
  authRealm,
  authResponseType,
  authScope
} from 'app/config';
import { getSigninUrl, baseUrl } from 'services/url-service';
import { parseSuccess } from 'services/api/api';

const ERROR_VALIDATE_EMAIL = 'ERROR_VALIDATE_EMAIL';

function getAuth0WebAuth() {
  return new WebAuth({
    audience: authAudience,
    clientID: authClientId,
    domain: authDomain,
    redirectUri: getSigninUrl(),
    responseType: authResponseType,
    scope: authScope
  });
}
const buildOnResponse = (onSuccess, onError, onEmailUnverified) => {
  return (error, token) => {
    if (error && error.error) {
      if (error.errorDescription == ERROR_VALIDATE_EMAIL) {
        onEmailUnverified();
      }
      else {
        onError();
      }
    }
    else if (onSuccess) {
      onSuccess(token);
    }
    else {
      onError(); // Unexpected path
    }
  };
};

export const authorize = (username, password, errorCallback, onEmailUnverified, authConnection) => {
  const Auth0 = getAuth0WebAuth();
  if (authConnection && authConnection.length > 0) {
    Auth0.authorize({
      connection: authConnection,
      login_hint: username,
      prompt: 'login'
    });
  }
  else {
    Auth0.login({
      realm: authRealm,
      username: username,
      password: password
    }, buildOnResponse(null, errorCallback, onEmailUnverified));
  }
};

export const authorizeCallback = (onSuccess, onError, onEmailUnverified) => {
  const Auth0 = getAuth0WebAuth();

  Auth0.parseHash(buildOnResponse(onSuccess, onError, onEmailUnverified));
};

export const parseHash = (onSuccess, onError) => {
  const hashStr = window.location.hash.replace(/^#?\/?/, '');
  const parsedQs = qs.parse(hashStr);

  if (parsedQs.hasOwnProperty('error')) {
    onError(parsedQs.error);
    return;
  }

  const token = buildTokenData(parsedQs);

  onSuccess(token);
};

const buildTokenData = (auth0Response) => {
  const decodedToken = new IdTokenVerifier().decode(auth0Response.id_token);

  const tokenData = {
    tokenType: auth0Response.token_type,
    accessToken: auth0Response.access_token,
    idTokenPayload: decodedToken.payload,
    redirect: auth0Response.redirect
  };

  return tokenData;
};

export const checkSession = (onSuccess, onError, onEmailUnverified) => {
  const Auth0 = getAuth0WebAuth();

  Auth0.checkSession({}, buildOnResponse(onSuccess, onError, onEmailUnverified));
};

export const signOut = () => {
  const Auth0 = getAuth0WebAuth();

  Auth0.logout({
    returnTo: baseUrl
  });
};

export const changePassword = (email, onSuccess, onError) => {
  const Auth0 = getAuth0WebAuth();

  const onResponse = (error) => {
    if (error) {
      onError();
    } else {
      onSuccess();
    }
  };

  Auth0.changePassword({ email, connection: authRealm }, onResponse);
};

export const refreshUserData = (accessToken) => {
  return fetch(`https://${authDomain}/userinfo`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  }).then(parseSuccess);
};
