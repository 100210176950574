import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import moment from 'moment';
import Logo from 'shared/logos/inline/HcLogoInline';
import { Icon, ExternalLink } from 'shared';
import './non-authenticated-page-wall.scss';

class NonAuthenticatedPageWall extends React.Component {
  componentDidMount() {
    document.title = `${this.props.titleTabText} - ${local.app}`;
  }

  componentWillUnmount() {
    document.title = local.app;
  }

  render() {
    const { id, children, navAction } = this.props;
    const navActionListItem = <li>{navAction}</li>;
    const date = moment().format('YYYY');
    const content = local.nonAuthWallPage;

    return (
      <div id={id}>
        <div id="non-authenticated-page-wall-component">
          {/* <nav>
            <section className="grid">
              <figure><Logo /></figure>
              <ul>
                <li>
                  <ExternalLink
                    className="nav-link"
                    id="about-hc"
                    logName="about-hc"
                    title="About | Health Champion"
                    openNewTab
                    href="https://myhealthchampion.com/about/"
                    rel="nofollow noopener">{content.nav.about}
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink
                    className="nav-link"
                    id="partners-hc"
                    logName="providers-hc"
                    title="Partners | Health Champion"
                    openNewTab
                    href="https://myhealthchampion.com/partners/"
                    rel="nofollow noopener">{content.nav.partners}
                  </ExternalLink>
                </li>
                {navActionListItem}
              </ul>
            </section>
          </nav> */}
          <main>
            {/* <header className="container">
              <h2>{content.header}</h2>
              <p>{content.subhead}</p>
              <div className="btn-group btn-group-lg w-100 my-3" role="group" aria-label="Basic example">
                <button type="button" className="btn btn-light">
                  <div className="d-flex align-items-center">
                    <Icon symbol="createUser_user" />
                    <div className="text-left ml-3">
                      <h6 className="mb-0">Step 1:</h6>
                      <small>Set Up Account</small>
                    </div>
                  </div>
                </button>
                <button type="button" className="btn btn-light">
                  <div className="d-flex align-items-center">
                    <Icon symbol="createUser_plan" />
                    <div className="text-left ml-3">
                      <h6 className="mb-0">Step 2:</h6>
                      <small>Choose Your Plan</small>
                    </div>
                  </div>
                </button>
                <button type="button" className="btn btn-light">
                  <div className="d-flex align-items-center">
                    <Icon symbol="create_User_dashboard" />
                    <div className="text-left ml-3">
                      <h6 className="mb-0">Step 3:</h6>
                      <small>Go to Your Dashboard</small>
                    </div>
                  </div>
                </button>
              </div>
            </header> */}
            <section className="container m-auto">
              <div className="form">{children}</div>
              {/* <div className="branding">
                <div className="content">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path d="M71.08,54h-.15a7.54,7.54,0,0,1-6.84-4.77L48.85,10.24a7.5,7.5,0,0,1,14-5.47l8.66,22.11L81.3,4.49a7.5,7.5,0,1,1,13.74,6L78,49.47A7.51,7.51,0,0,1,71.08,54"/>
                      <path d="M88.17,144a7.51,7.51,0,0,1-7-4.77l-8.66-22.11L62.7,139.51a7.5,7.5,0,1,1-13.74-6L66.05,94.53a7.5,7.5,0,0,1,13.86.28l15.24,38.95a7.51,7.51,0,0,1-7,10.24"/>
                      <path d="M53.28,62.09a7.55,7.55,0,0,1-3-.63L12,44.7A7.5,7.5,0,0,1,18,31l21.75,9.52L30.84,17.69a7.5,7.5,0,1,1,14-5.45L60.28,51.86a7.51,7.51,0,0,1-7,10.23"/>
                      <path d="M106.17,136.54a7.5,7.5,0,0,1-7-4.78L83.72,92.14a7.51,7.51,0,0,1,10-9.6L132,99.3a7.5,7.5,0,0,1-6,13.75l-21.75-9.52,8.88,22.78a7.49,7.49,0,0,1-7,10.23" />
                      <path d="M7.51,95.67A7.5,7.5,0,0,1,4.77,81.18l22.11-8.65L4.49,62.7a7.5,7.5,0,1,1,6-13.74l39,17.09a7.5,7.5,0,0,1-.28,13.86L10.24,95.16a7.5,7.5,0,0,1-2.73.51" />
                      <path d="M136.49,95.67a7.46,7.46,0,0,1-3-.63L94.53,78a7.51,7.51,0,0,1,.28-13.86l38.95-15.25a7.51,7.51,0,0,1,5.47,14l-22.11,8.65,22.39,9.83a7.5,7.5,0,0,1-3,14.37" />
                      <path d="M37.82,136.54A7.51,7.51,0,0,1,31,126l9.52-21.75-22.78,8.88a7.5,7.5,0,1,1-5.45-14L51.86,83.72a7.51,7.51,0,0,1,9.6,10L44.7,132a7.5,7.5,0,0,1-6.88,4.5" />
                      <path d="M89.42,60.79a7.5,7.5,0,0,1-6.88-10.51L99.3,12a7.5,7.5,0,1,1,13.75,6l-9.52,21.75,22.78-8.88a7.5,7.5,0,1,1,5.45,14L92.14,60.28a7.58,7.58,0,0,1-2.72.51" />
                      <path d="M60.7,55.54a8.17,8.17,0,0,0,.09-.85c0-.26,0-.52-.06-.79s0-.45-.06-.67a8.18,8.18,0,0,0-.41-1.42,7.47,7.47,0,0,0-4-4.11L39.56,40.46l6.76,16.91a7.48,7.48,0,0,0,4.2,4.17,7.84,7.84,0,0,0,1,.33L52,62a7.39,7.39,0,0,0,1.31.14h.05A7.44,7.44,0,0,0,54.55,62l.37-.08a8.14,8.14,0,0,0,.93-.27l.35-.13a7.1,7.1,0,0,0,1.19-.63l.15-.1a8.85,8.85,0,0,0,.89-.73l.28-.28a7.7,7.7,0,0,0,.63-.76l.21-.28a7.27,7.27,0,0,0,1.08-2.56c0-.2,0-.41.07-.61" />
                      <path d="M83.31,88.42a8.53,8.53,0,0,0-.1.85c0,.26,0,.53.06.79s0,.45.06.67a6.54,6.54,0,0,0,.4,1.42,7.45,7.45,0,0,0,4,4.14l16.67,7.31L97.69,86.66a7.42,7.42,0,0,0-4.17-4.18,8.54,8.54,0,0,0-1-.34l-.38-.09a7.4,7.4,0,0,0-1.32-.14h0a7.3,7.3,0,0,0-1.23.11l-.38.08a7.45,7.45,0,0,0-.92.27l-.36.13a7.06,7.06,0,0,0-1.19.62l-.15.1a7.78,7.78,0,0,0-.89.72l-.28.28a7.79,7.79,0,0,0-.64.76l-.21.28a7.57,7.57,0,0,0-.63,1.15,8,8,0,0,0-.46,1.41c0,.2,0,.4-.07.6" />
                      <path d="M52.33,68.34a5.78,5.78,0,0,0-.53-.67c-.18-.18-.4-.34-.6-.51s-.33-.31-.52-.43a7.44,7.44,0,0,0-7-.8L26.73,72.62,43.46,79.8a7.51,7.51,0,0,0,5.92,0,8.12,8.12,0,0,0,1-.51l.33-.21a7.63,7.63,0,0,0,1-.83l0,0s0,0,0,0a6.73,6.73,0,0,0,.79-1,3.53,3.53,0,0,0,.21-.32,6.85,6.85,0,0,0,.46-.84c.06-.12.11-.23.16-.35a7.57,7.57,0,0,0,.4-1.28,1.13,1.13,0,0,0,0-.18,7.35,7.35,0,0,0,.12-1.14v-.39a6.39,6.39,0,0,0-.1-1,2.11,2.11,0,0,0-.05-.35,7.91,7.91,0,0,0-.37-1.26,6.77,6.77,0,0,0-.68-1.31,5.71,5.71,0,0,0-.38-.48" />
                      <path d="M91.58,75.6a5.78,5.78,0,0,0,.53.67,6.05,6.05,0,0,0,.6.52c.17.14.33.3.51.43a7.36,7.36,0,0,0,1.29.72,7.47,7.47,0,0,0,5.73.12l17-6.62-16.7-7.25a7.41,7.41,0,0,0-5.91,0,6.44,6.44,0,0,0-1,.5l-.33.21a6.93,6.93,0,0,0-1,.83l0,0s0,0,0,0a7.24,7.24,0,0,0-.79.95,3.53,3.53,0,0,0-.21.32,8.6,8.6,0,0,0-.47.84c0,.12-.11.23-.15.34a7,7,0,0,0-.41,1.28,1.13,1.13,0,0,0,0,.18A7.35,7.35,0,0,0,90,70.82c0,.13,0,.26,0,.39a8.28,8.28,0,0,0,.09,1c0,.11,0,.23,0,.34a8,8,0,0,0,.37,1.27,7.7,7.7,0,0,0,.67,1.31,5.71,5.71,0,0,0,.38.48" />
                      <path d="M68.3,91.65a6.76,6.76,0,0,0-.66.54,6,6,0,0,0-.51.6,5.49,5.49,0,0,0-.44.52,7.49,7.49,0,0,0-.8,7l6.7,16.94,7.17-16.74a7.4,7.4,0,0,0,0-5.91,7,7,0,0,0-.5-1L79,93.3a7.6,7.6,0,0,0-.84-1s0,0,0,0,0,0,0,0a7.24,7.24,0,0,0-1-.79l-.32-.21a7.66,7.66,0,0,0-.85-.46l-.34-.16a7.57,7.57,0,0,0-1.28-.4l-.18,0a7.48,7.48,0,0,0-1.14-.12c-.14,0-.27,0-.4,0a7.93,7.93,0,0,0-1,.09l-.35,0a7.62,7.62,0,0,0-1.26.37,8.21,8.21,0,0,0-1.32.68c-.17.11-.32.26-.48.38" />
                      <path d="M75.57,52.41c.23-.17.46-.34.67-.53s.34-.4.51-.6a5.49,5.49,0,0,0,.44-.52,6.93,6.93,0,0,0,.72-1.28A7.47,7.47,0,0,0,78,43.75l-6.62-17L64.15,43.48a7.47,7.47,0,0,0,0,5.92,6.83,6.83,0,0,0,.5,1l.2.34a9.21,9.21,0,0,0,.84,1h0s0,0,0,0a8.2,8.2,0,0,0,1,.8l.33.2a5.68,5.68,0,0,0,.84.47l.34.16a8.65,8.65,0,0,0,1.28.41l.18,0a6.32,6.32,0,0,0,1.14.13h.39a8.28,8.28,0,0,0,1-.09l.35,0a7,7,0,0,0,1.26-.36,8.17,8.17,0,0,0,1.32-.67c.17-.11.32-.26.48-.38" />
                      <path d="M55.49,83.32a8.17,8.17,0,0,0-.85-.09c-.26,0-.52,0-.78.06s-.46,0-.68.06a7.6,7.6,0,0,0-1.42.41,7.47,7.47,0,0,0-4.11,4l-7.23,16.71,16.9-6.76a7.48,7.48,0,0,0,4.17-4.2,6.6,6.6,0,0,0,.33-1l.09-.39A7.38,7.38,0,0,0,62,90.75v0a7.32,7.32,0,0,0-.12-1.23c0-.13-.05-.25-.08-.37a8.14,8.14,0,0,0-.27-.93,3.62,3.62,0,0,0-.13-.35,7.88,7.88,0,0,0-.62-1.19l-.11-.15a6.91,6.91,0,0,0-.73-.89l-.27-.28a9.2,9.2,0,0,0-.77-.63l-.28-.21a8.56,8.56,0,0,0-1.15-.63,7.68,7.68,0,0,0-1.41-.45c-.2,0-.41,0-.61-.07" />
                      <path d="M88.37,60.71a8.53,8.53,0,0,0,.85.1c.26,0,.53,0,.79-.06s.45,0,.68-.06a7.26,7.26,0,0,0,1.41-.4,7.45,7.45,0,0,0,4.14-4l7.31-16.67L86.61,46.33a7.42,7.42,0,0,0-4.18,4.17,8.54,8.54,0,0,0-.34,1l-.09.38a8.45,8.45,0,0,0-.14,1.32v0A7.3,7.3,0,0,0,82,54.53c0,.13.06.25.08.38a7.45,7.45,0,0,0,.27.92,3.62,3.62,0,0,0,.13.35,7.54,7.54,0,0,0,.62,1.2l.1.14a8.11,8.11,0,0,0,.72.9l.28.28a7.79,7.79,0,0,0,.76.64l.28.21a7.57,7.57,0,0,0,1.15.63,8.29,8.29,0,0,0,1.41.46c.2,0,.4,0,.6.07" />
                    </g>
                  </svg>
                </div>
              </div> */}
            </section>
          </main>
          {/* <aside>
            <header className="grid"><h3>{content.subhead}</h3></header>
            <section className="grid">
              <p>
                <Icon size="3em" symbol="health" /><br />
                <small>{content.value1}</small>
              </p>
              <p> <Icon size="3em" symbol="temperature" /><br />
                <small>{content.value2}</small>
              </p>
              <p> <Icon size="3em" symbol="observations" /><br />
                <small>{content.value3}</small>
              </p>
              <p> <Icon size="3em" symbol="devices" /><br />
                <small>{content.value4}</small>
              </p>
            </section>
          </aside> */}
          {/* <footer>
            <section className="grid">
              <div className="c1">
                <figure><Logo /></figure>
              </div>
              <div className="c5">
                © {local.copyright}, {date}
              </div>
            </section>
          </footer> */}
        </div>
      </div >
    );
  }
}

NonAuthenticatedPageWall.propTypes = {
  id: PropTypes.string.isRequired,
  titleTabText: PropTypes.string.isRequired,
  children: PropTypes.node,
  navAction: PropTypes.node
};
NonAuthenticatedPageWall.defaultProps = {
  children: null,
  navAction: null
};

export default NonAuthenticatedPageWall;
