import React from 'react';
import { Icon } from 'shared';
import local from 'services/localization/local';

class RegisterStepHeader extends React.Component {
  render() {
    return (
      <div className="btn-group btn-group-lg w-100 my-3" role="group">
        <button type="button" className="btn btn-light">
          <div className="d-block d-md-flex align-items-center">
            <Icon symbol="createUser_user" />
            <div className="ms-2">
              <h6 className="mb-0 text-start">{local.registerStep.step1}:</h6>
              <small className="d-none d-md-block">{local.registerStep.step1Title}</small>
            </div>
          </div>
        </button>
        <button type="button" className="btn btn-light" disabled>
          <div className="d-block d-md-flex align-items-center">
            <Icon symbol="createUser_plan" />
            <div className="ms-2">
              <h6 className="mb-0 text-start">{local.registerStep.step2}:</h6>
              <small className="d-none d-md-block">{local.registerStep.step2Title}</small>
            </div>
          </div>
        </button>
        <button type="button" className="btn btn-light" disabled>
          <div className="d-block d-md-flex align-items-center">
            <Icon symbol="create_User_dashboard" />
            <div className="ms-2">
              <h6 className="mb-0 text-start">{local.registerStep.step3}:</h6>
              <small className="d-none d-md-block">{local.registerStep.step3Title}</small>
            </div>
          </div>
        </button>
      </div>
    );
  }
}
export default RegisterStepHeader;