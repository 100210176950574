import compileReducers from 'services/compile-reducer';
import { actionNames } from './request-actions';

export const initialState = {};
export const defaultStatus = {
  success: false,
  processing: false,
  hasError: false,
  error: null,
  params: null
};
export const reducers = {};

reducers[actionNames.REQUESTED] = (state, payload) => {
  const { requestId, params } = payload;

  return {
    ...state,
    [requestId]: {
      success: false,
      processing: true,
      hasError: false,
      error: null,
      params
    }
  };
};

reducers[actionNames.REQUEST_SUCCESS] = (state, payload) => {
  const { requestId, result, params } = payload;

  return {
    ...state,
    [requestId]: {
      success: true,
      processing: false,
      hasError: false,
      error: null,
      result,
      params
    }
  };
};

reducers[actionNames.REQUEST_ERROR] = (state, payload) => {
  const { requestId, error, params } = payload;

  return {
    ...state,
    [requestId]: {
      success: false,
      processing: false,
      hasError: true,
      error,
      params
    }
  };
};

export default compileReducers(reducers, initialState);
