import React from 'react';
import PropTypes from 'prop-types';
import withNavigation from 'app/withNavigation';
import { Icon } from 'shared';
import MobileBackButtonController from 'mobile/MobileBackButtonController';
import './header.scss';

export class BackAction extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { to, params, handleBack, navigate } = this.props;
    navigate({ to, params, navAction: handleBack });
  }

  render() {
    const { text } = this.props;

    return (
      <button className="nav-action back-action" type="button" onClick={this.handleClick}>
        <Icon symbol="arrowleft" size="24px" />
        {text}
        <MobileBackButtonController onPress={this.handleClick} />
      </button>
    );
  }
}

BackAction.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  handleBack: PropTypes.func,
  params: PropTypes.shape(),
  // navigation
  navigate: PropTypes.func.isRequired
};

export default withNavigation(BackAction);
