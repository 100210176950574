import React from 'react';
import PropTypes from 'prop-types';
import { ModalWrapper, Button, Icon, FormattedText } from 'shared';
import local from 'services/localization/local';
import './confirm-modal.scss';

export default function ConfirmModal({ modalTitle, modalPromptText, handleYes, handleNo, isOpen }) {
  return (
    <ModalWrapper isOpen={isOpen}>
      <div id="confirm-modal" className="modal-container" data-testid='modal'>
        <div className="modal-header">
          <Button id="delete-journal-close-button" onClick={handleNo}><Icon symbol="times" /></Button>
          <div className='modal-title'>{modalTitle}</div>
        </div>
        <div className="modal-body">
          <div className='scrollable confirm-section'>
            <FormattedText text={modalPromptText} />
          </div>
        </div>
        <div className='sticky-button-section'>
          <Button id="accept-button" className='modal-button' color="transparent" onClick={handleYes}>{local.modal.yes}</Button>
          <Button id="reject-button" className='modal-button' color="transparent" onClick={handleNo}>{local.modal.no}</Button>
        </div>
      </div>
    </ModalWrapper>
  );
}

ConfirmModal.propTypes = {
  modalTitle: PropTypes.string,
  modalPromptText: PropTypes.string,
  handleYes: PropTypes.func.isRequired,
  handleNo: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
