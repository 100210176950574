import React from 'react';
import PropTypes from 'prop-types';
import './circle-dot-figure.scss';

export default function CircleDotFigure(props) {
  return <figure className={`circle-dot-figure ${props.color || 'teal'}`} />;
}

CircleDotFigure.propTypes = {
  color: PropTypes.string
};
