exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "#toaster .toaster-enter{transform:translateX(100%);transition:transform 300ms ease-in-out}#toaster .toaster-enter.toaster-enter-active{transform:translateX(0%);transition:transform 300ms ease-in-out}#toaster .toaster-leave{transform:translateX(0%);transition:transform 300ms ease-in-out}#toaster .toaster-leave.toaster-leave-active{transform:translateX(100%);transition:transform 300ms ease-in-out}#toaster .notificationWrapper{position:fixed;right:0px;min-width:200px;z-index:3000}#toaster .notification{position:fixed;right:0px;top:100px;padding:20px;min-width:200px;background-color:#333;color:#fff;font-weight:500;font-size:14px;display:flex;align-items:center;z-index:3000}#toaster .notification:before{content:\"\";transform-origin:center center;flex-grow:0;flex-shrink:0;display:block;height:1.5em;width:1.5em;margin:.5em;border-radius:50%;border:1px solid #54c2b6}#toaster .notification.saving:before{border-left-color:#fff;border-top-color:#fff;-webkit-animation-name:spin;animation-name:spin;-webkit-animation-duration:500ms;animation-duration:500ms;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}#toaster .notification.saved:before{border-color:#54c2b6}#toaster .notification.saved:after{content:\"\";position:absolute;top:49%;left:2.5em;height:.25em;width:.6em;border:1px solid #54c2b6;transform-origin:center left;transform:rotate(-40deg);border-top:none;border-right:none}#toaster .notification.error:before{border-color:#fff}#toaster .notification.error:after{content:\"!\";position:absolute;top:50%;left:2.75em;color:#fff;transform-origin:center center;transform:translate(-50%, -50%)}", ""]);

// Exports
exports.locals = {
	"colorTeal": "#54c2b6",
	"colorTealRedChannel": "84",
	"colorTealGreenChannel": "194",
	"colorTealBlueChannel": "182",
	"colorTealDark": "#26a995",
	"colorTeal50": "rgba(84,194,182,.5)",
	"colorTealScreened": "#54c2b6",
	"colorWhiteOff": "#f2f2f2",
	"colorWhite": "#fff",
	"colorWhiteTrans": "rgba(255,255,255,0)",
	"colorWhite50": "rgba(255,255,255,.5)",
	"colorBlack": "#333",
	"colorGrayDark": "#4f4f4f",
	"colorGray": "#828282",
	"colorGrayLight": "#e0e0e0",
	"colorBlue": "#049fda",
	"colorBlueScreened": "#049fda",
	"colorMagenta": "#ea3b7d",
	"colorMagentaScreened": "#ea3b7d",
	"colorYellow": "#fed504",
	"colorYellowDark": "#d8b129",
	"colorYellowScreened": "#fed504",
	"colorUi1": "rgba(4,159,218,.3)",
	"colorUi2": "rgba(84,194,182,.3)",
	"colorUi3": "rgba(234,59,125,.3)",
	"colorUi4": "rgba(254,213,4,.3)",
	"colorUi5": "rgba(31,79,228,.3)",
	"colorUi6": "rgba(177,208,21,.3)",
	"colorUi7": "rgba(228,34,255,.3)",
	"colorUi8": "rgba(255,157,39,.3)",
	"colorUi9": "rgba(66,32,208,.3)",
	"colorUi10": "rgba(26,255,198,.3)",
	"colorUi11": "rgba(205,38,26,.3)",
	"colorUi12": "rgba(247,184,50,.3)",
	"colorUi13": "rgba(146,79,207,.3)",
	"colorUi14": "rgba(98,205,36,.3)",
	"colorUi15": "rgba(255,24,9,.3)",
	"colorUi16": "rgba(207,111,79,.3)",
	"colorGreen": "#0acf97"
};