import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import identityReducer from 'identity/identity-reducer';
import toasterReducer from 'app/toaster/toaster-reducer';
import networkReducer from 'app/network/network-reducer';
import navigationReducer from './layout/navigation/navigation-reducer';
import requestReducer from 'app/request/request-reducer';
import journalReducer from 'journal/journal-reducer';
import authConnectionReducer from 'auth-connections/auth-connection-reducer';
import organizationReducer from 'organizations/organizations-reducer';

export default combineReducers({
  form: formReducer,
  identity: identityReducer,
  journal: journalReducer,
  navigation: navigationReducer,
  network: networkReducer,
  request: requestReducer,
  toaster: toasterReducer,
  authConnection: authConnectionReducer,
  organizationConnection: organizationReducer
});
